/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsdoc/no-types */
/* eslint-disable jsdoc/check-alignment */
import { JsonObject, JsonProperty } from 'json2typescript';

import { Event } from './event.model';
import { Time } from './time.model';
import { Point } from'./point.model';
import { Angle } from './angle.model';
import { MarkerData } from './markerData.model';
import { forEach } from 'lodash';

/**
 * Media options
 */
@JsonObject('SceneOptions')
export class SceneOptions {

    // @JsonProperty('Angle', Number, true)
    // private Angle: number = 180;
    @JsonProperty('StartPosition', Point, true)
    private StartPosition: Point = new Point(-1,-1);
    @JsonProperty('StartPositionAngle', Angle, true)
    private StartPositionAngle: Angle = new Angle(-1,-1);
    @JsonProperty('Limit', Angle, true)
    private Limit: Angle = new Angle(180,180);
    @JsonProperty('TimeOnScene', Time, true)
    private TimeOnScene: Time = new Time();
    @JsonProperty('DestinationKey', String, true)
    private DestinationKey: string = '';
    @JsonProperty('BtnPause', Boolean, true)
    private BtnPause: boolean = false;
    @JsonProperty('Timeline', Boolean, true)
    private Timeline: boolean = false;
    @JsonProperty('Obj3D', String, true)
    private Obj3D: string = '';
    @JsonProperty('Loop', Boolean, true)
    private Loop: boolean = true;
    @JsonProperty('AudioKey', String, true)
    private AudioKey: string = '';
    @JsonProperty('Volume', Number, true)
    private Volume: number = 100;
    @JsonProperty('VidVolume', Number, true)
    private VidVolume: number = 100;


    constructor() {}

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
    get angle(): number { return -1; }
    set angle(value: number) { console.log('angle obsolete'); }

    get startPosition(): Point { return this.StartPosition; }
    set startPosition(value: Point) { this.StartPosition = value; }

    get limit(): Angle { return this.Limit; }
    set limit(value: Angle) { this.Limit = value; }

    get startPositionAngle(): Angle { return this.StartPositionAngle; }
    set startPositionAngle(value: Angle) { this.StartPositionAngle = value; }

    get timeOnScene(): Time { return this.TimeOnScene; }
    set timeOnScene(value: Time) { this.TimeOnScene = value; }

    get destinationKey(): string { return this.DestinationKey; }
    set destinationKey(value: string) { this.DestinationKey = value; }

    get obj3DKey(): string { return this.Obj3D; }
    set obj3DKey(value: string) { this.Obj3D = value; }

    get audioKey(): string { return this.AudioKey; }
    set audioKey(value: string) { this.AudioKey = value; }

    get volume(): number { return this.Volume; }
    set volume(value: number) { this.Volume = value; }

    get vidVolume(): number { return this.VidVolume; }
    set vidVolume(value: number) { this.VidVolume = value; }

    get btnPause(): boolean { return this.BtnPause; }
    set btnPause(value: boolean) { this.BtnPause = value; }

    get timeline(): boolean { return this.Timeline; }
    set timeline(value: boolean) { this.Timeline = value; }

    get loop(): boolean { return this.Loop; }
    set loop(value: boolean) { this.Loop = value; }

}

/**
 * Scene
 */
@JsonObject('Scene')
export class Scene {

    @JsonProperty('Key', String, true)
    private Key: string;
    @JsonProperty('Title', String, true)
    private Title: string = '';
    @JsonProperty('IsMain', Boolean, true)
    private IsMain: boolean = false;
    @JsonProperty('MediaKey', String, true)
    private MediaKey: string = '';
    @JsonProperty('Events', [Event], true)
    private Events: Event[] = [];
    @JsonProperty('SceneOptions', SceneOptions, true)
    private SceneOptions: SceneOptions = new SceneOptions();
    @JsonProperty('MarkerData', MarkerData, true)
    private MarkerData: MarkerData = new MarkerData();

    constructor(key?: string, title?: string) {
        this.Key = key;
        this.Title = title;
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
    get key(): string { return this.Key; }
    set key(value: string) { this.Key = value; }

    get title(): string { return this.Title; }
    set title(value: string) { this.Title = value; }

    get isMain(): boolean { return this.IsMain; }
    set isMain(value: boolean) { this.IsMain = value; }

    get mediaKey(): string { return this.MediaKey; }
    set mediaKey(value: string) { this.MediaKey = value; }

    get events(): Event[] { return this.Events; }
    set events(value: Event[]) { this.Events = value; }

    get sceneOptions(): SceneOptions { return this.SceneOptions; }
    set sceneOptions(value: SceneOptions) { this.SceneOptions = value; }

    get markerData(): MarkerData { return this.MarkerData; }
    set markerData(value: MarkerData) { this.MarkerData = value; }

    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
	 * Add event to events list
	 *
	 * @param {Event} newEvent
	 */
	addEvent(newEvent: Event): void {
		this.Events.push(newEvent);
	}

	/**
	 * Modify event at index in events list
	 *
	 * @param {number} index
	 * @param {Event} modifiedEvent
	 */
	modifyEvent(modifiedEvent: Event): number {

        console.log('modifyEvent', modifiedEvent);
        console.log('this.Events', this.Events);
        const index = this.Events.findIndex((event) => {
            return event.key === modifiedEvent.key;});

        console.log('index', index);
        if(index === -1) 
        {
            console.error('Event not found');
            return -1;
        }
        this.Events[index] = modifiedEvent;
        return index;
    }

    /**
	 * Delete event in events list
	 *
	 * @param {Event} newEvent
	 */
    deleteEvent(event: Event): void {
		const index = this.events.indexOf(event);

		if (index !== -1) {
			this.events.splice(index, 1);
		}
	}

    /**
     * 
     * @param variableKey
     * @returns true si variable utilise
     */
    isUsedVariable(variableKey: string): boolean {

        for(let event of this.events) {
            if(event.isUsedVariable(variableKey)) {
                return true;
            }
        }
        

        return false;
    }

}
