import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Notification } from 'app/components/notifications/notifications.types';
import { map, switchMap, take, tap } from 'rxjs/operators';
//import { playAlert } from 'alert-sound-notify';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService
{
    private _notifications: Notification[] = [];
    private _notificationsSubject: Subject<Notification[]> = new Subject<Notification[]>();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]>
    {
        return this._notificationsSubject;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]>
    {
        /*return this._httpClient.get<Notification[]>('api/common/notifications').pipe(
            tap((notifications) => {
                this._notificationsSubject.next(notifications);
            })
            );*/
        this._notificationsSubject.next(this._notifications);
        return this._notificationsSubject;
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification)//: Observable<Notification>
    {
             

        this._notifications.push(notification);
        console.log(this._notifications);
        this._notificationsSubject.next(this._notifications);
        //return this._notificationsSubject;
        let playAlert = require('alert-sound-notify')
        playAlert.volume(0.5)
        playAlert('purr');
        if(notification.icon=="bug_report"){
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('bottle')
            },1000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('glass')
            },2000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('funk')
            },3000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('morse')
            },4000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('purr')
            },5000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('tink')
            },6000);
            setTimeout(()=>{
                let playAlert = require('alert-sound-notify')
                playAlert.volume(0.5)
                playAlert('submarine')
            },7000);
        }
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification)//: Observable<Notification>
    {
        /*return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<Notification>('api/common/notifications', {
                id,
                notification
            }).pipe(
                map((updatedNotification: Notification) => {

                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                })
            ))
        );*/
        const index = this._notifications.findIndex(item => item.id === id);

        // Update the notification
        this._notifications[index] = notification;

        // Update the notifications
        this._notificationsSubject.next(this._notifications);

    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string)//: Observable<boolean>
    {
        /*return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );*/
        // Find the index of the deleted notification
        const index = this._notifications.findIndex(item => item.id === id);

        // Delete the notification
        this._notifications.splice(index, 1);

        // Update the notifications
        this._notificationsSubject.next(this._notifications);

    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead()//: Observable<boolean>
    {
        /*return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {

                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                })
            ))
        );*/
        this._notifications.forEach((notification, index) => {
            this._notifications[index].read = true;
        });

        // Update the notifications
        this._notificationsSubject.next(this._notifications);

    }
}
