import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { ShareScenario } from 'app/models/scenario/sharescenario.model';
import { User } from 'app/models/user.model';
import { httpsCallable } from 'firebase/functions';
import { Scenario } from 'app/models/scenario/scenario.model';
import { Diffusion } from 'app/models/scenario/diffusion.model';


@Injectable({
    providedIn: 'root'
})
export class FunctionsService {

    functions;

	constructor() {

    }

    init(functions){
        this.functions = functions;
    }

	// -----------------------------------------------------------------------------------------------------
    // @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Send diffusion email
	 *
	 * @param data
	 */
	sendDiffusionEmail(data: any) {

		const sendEmailWithDiffusionPropertiesFunction = httpsCallable(this.functions,"onlySendDiffusionPropertiesEmail");
		sendEmailWithDiffusionPropertiesFunction(data);
	}

    sendEmail(data)
    {

        data.lang = localStorage.getItem('user.language');
        const sendWelcomeEmailFunction = httpsCallable(this.functions,"sendEmail");
        return sendWelcomeEmailFunction(data);
    }

	/**
	 * Create short link
	 *
	 * @param data
	 */
	createShortLink(data: {
        action: string,
        toEmail: string,
        isPrivate: boolean,
        storymode :boolean,
        username: string,
        scenarioTitle: string,
        diffusionKey: string,
        diffusionPassword: string,
        url: string,
        databaseVersion: string,
        sendEmail: boolean,
        iframe: string,
        description: string,
        image: string,
        lang: string
    }) {

		return httpsCallable<{
            action: string,
            toEmail: string,
            isPrivate: boolean,
            storymode :boolean,
            username: string,
            scenarioTitle: string,
            diffusionKey: string,
            diffusionPassword: string,
            url: string,
            databaseVersion: string,
            sendEmail: boolean,
            iframe: string,
            description: string,
            image: string
        },{res:string}>(this.functions,"createShortLink")(data);

	}

	bigQSqlQuery(query){
		const bigQSqlQueryFunction = httpsCallable<{uri: string},{res:any}>(this.functions,"bigQSqlQuery");
		return bigQSqlQueryFunction(query);
	}

	getUserByEmail(email){
		return httpsCallable<{uri: string},any>(this.functions,"findUserByEmail")(email);
	}

	copyFile(from: string, to: string,filename: string){
		const data = {from: from, to: to, filename: filename};
		return httpsCallable<{from: string, to: string, filename: string},{error:string}>(this.functions,"copyFile")(data);
	}

	deleteSharedStory(sharedStory){
		return httpsCallable<{uri: string},{res:string}>(this.functions,"deleteSharedStory")(sharedStory);
	}

    deleteSpecificSharedStory(sharedStory)
    {
        const data = {sharedStory: sharedStory};

        return httpsCallable<{sharedStory: string}>(this.functions,"clearSpecificShare")(data);
    }

    checkAccount()
    {
        const data = {};
        return httpsCallable<{}>(this.functions,"checkAccount")();
    }

	clearSharedStory(){
		httpsCallable(this.functions,"testClearSharedStory")();
	}

	deleteUser(key,email=undefined){


        const data = {key: key, email: email,lang: localStorage.getItem('user.language')};

		return httpsCallable<{key: string,email:string,lang:string},{res:string}>(this.functions,"deleteUserV2")(data);
	}

    generateContent(libraryKey : string, contentKey : string, contentName : string, category : string, type:string,lang: string = undefined){
        const data = {contentName: contentName, libraryKey: libraryKey, contentKey: contentKey, type : category, format: type,lang: lang};


        return httpsCallable<{contentName: string, libraryKey: string, contentKey: string, type : string, lang: string},{res:string}>(this.functions,"generateContent")(data);
    }
    uploadToVimeo(libraryKey : string, contentKey : string, ref : string, contentName : string, contentTitle : string,lang: string = undefined){
        contentTitle = contentTitle.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const data = {contentName: contentName, libraryKey: libraryKey, contentKey: contentKey, 'ref': ref, contentTitle : contentTitle,lang: lang};


        return httpsCallable< {contentName: string, libraryKey: string, contentKey: string, ref: string, contentTitle : string,lang: string},{res:string}>(this.functions,"uploadToVimeo")(data);
    }
	deleteVimeo(uri : string){
        const data = {'uri': uri};


        return httpsCallable<{uri: string},{res:string}>(this.functions,"deleteVimeo")(data);
    }
    async getVimeoSource(uri: string){
        const data = {'uri': uri};


        return await httpsCallable<{uri: string},{res:string}>(this.functions,"vimeoSource")(data);
        //return httpsCallable(this.functions,"deleteVimeo")(undefined);
    }
    addUser(user : User,sendEmail : boolean,url : string)
    {
        const data = {user: user, sendEmail: sendEmail, url: url};
        return httpsCallable<{user: User, sendEmail: boolean, url: string},{res:User}|{res:any}>(this.functions,"addUser")(data);
    }

    recaptchaToken(token : string)
    {
        const data = {token: token,secret: environment.recaptcha.secret};
        return httpsCallable<unknown,unknown>(this.functions,"recaptchaToken")(data);
    }

    shareFile(data) {

        return httpsCallable<{scenario: string, diffusion: string,scenarioKey: string, diffusionKey: string,user: string, version:string},{res:any,error:any}>(this.functions,"shareFile")(data);
    }

    directUploadToVimeo(libraryKey : string, contentKey : string, ref : string, contentName : string, contentTitle : string,file:string, lang: string = undefined){
        contentTitle = contentTitle.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const data = {contentName: contentName, libraryKey: libraryKey, contentKey: contentKey, 'ref': ref, contentTitle : contentTitle,file:file, lang: lang};

        return httpsCallable< {contentName: string, libraryKey: string, contentKey: string, ref: string, contentTitle : string,lang: string},{res:string}>(this.functions,"directUploadToVimeo")(data);
    }

    forceCheckAccount(){
		httpsCallable(this.functions,"forceCheckAccount")();
	}

    resetPassword(uid: string)
    {
        const data = {uid: uid };
        return httpsCallable<{uid: string }>(this.functions,"resetPassword")(data);
    }
}
