import { SceneService } from "app/services/scenario/scene.service";
import { FuseConfirmationService } from "./../../@fuse/services/confirmation/confirmation.service";
import {
    Avatar,
    DestinationLink,
    EndLink,
    GroupRecognition,
    SetVariableEvent,
} from "./../models/scenario/event.model";
/* eslint-disable no-case-declarations */
import { Notification as CustomNotif } from "app/components/notifications/notifications.types";
import { AuthService } from "app/core/auth/auth.service";
import { AuthService as AuthService2 } from "./auth.service";
import { Connection, Stripe, UserSpecific } from "./../models/user.model";
import { Injectable } from "@angular/core";

import { Subject, Observable, of, firstValueFrom, filter } from "rxjs";

import { JsonConvert, PropertyConvertingMode } from "json2typescript";

import { ProjectService } from "./project.service";
import { ProjectV2Service } from "./projectV2.service";
import { LibraryService } from "./library.service";
import { UserService } from "./user.service";
import { InterfaceService } from "./interface.service";
import { DevicesService } from "./devices.service";
import { ScenariosService } from "./scenarios.service";

import { Project } from "app/models/project.model";
import { Content, ContentCategory } from "app/models/content.model";
import { ProjectV2 } from "app/models/projectV2.model";
import { Interface } from "app/models/interface.model";
import { User, UserProject, UserType } from "app/models/user.model";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { Library } from "app/models/library.model";
import { Device, DeviceUserTable } from "app/models/device.model";
import { StorageService } from "./storage.service";

import { Scenario } from "app/models/scenario/scenario.model";
import { Subscription as UserSubscription } from "app/models/scenario/subscription.model";
import {
    Subscription,
    Subscriptions,
    SubscriptionType,
} from "app/models/subscription.model";
import {
    SceneLink,
    Text,
    Image,
    Mask360,
    Video,
    Audio,
    WebLink,
    Zone,
    EventType,
    Quiz,
    QuizAnswer,
    QuizHotspotAnswer,
    QuizHotspot,
    Feedback,
    Debriefing,
    Consigne,
    Diaporama,
    StoryLink,
    ContentLink,
    QCO,
    Objet3D,
    SelectLanguage,
    Event,
    VoiceRecognition,
} from "app/models/scenario/event.model";
import { NotificationsService } from "app/components/notifications/notifications.service";
import { DlType } from "app/models/download.model";
import {
    Diffusion,
    DiffusionsExamples,
    ModeType,
} from "app/models/scenario/diffusion.model";
import { Point } from "app/models/scenario/point.model";
import { Angle } from "app/models/scenario/angle.model";
import { ScenarioService } from "./scenario/scenario.service";
import { ShareScenario } from "app/models/scenario/sharescenario.model";
import { environment } from "environments/environment";
import { FunctionsService } from "./functions.service";
import { Tag } from "app/models/tag.model";
import { forEach, random } from "lodash-es";
import { PagePref, UserPref } from "app/models/userPref.model";
import { TranslocoService } from "@ngneat/transloco";
import exifr from "exifr";
import { MarkerData } from "app/models/scenario/markerData.model";
import {
    HeadsetSetting,
    HeadSetting,
} from "app/models/scenario/headsetsettings.model";
import { FormEvent } from "app/models/scenario/formevent.model";
import { MyForm } from "app/models/scenario/myform.model";
import { VimeoData, VimeoFile } from "app/models/vimeodata.model";

import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

import {
    getDatabase,
    ref,
    push,
    onValue,
    set,
    remove,
    off,
    get,
    onDisconnect,
    serverTimestamp,
    Database,
    DataSnapshot,
} from "firebase/database";
import {
    TranslateEvent,
    TranslateScenario,
} from "app/models/scenario/translate.model";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import {
    getStorage,
    ref as Sref,
    FirebaseStorage,
    getDownloadURL,
} from "firebase/storage";
import { Scene } from "app/models/scenario/scene.model";
import { DataService } from "./data.service";
import { VimeoService } from "./vimeo.service";
import {
    DevicePairing,
    Pairing,
    PairingType,
    RequestPairing,
    UserPairing,
} from "app/models/pairing.model";
import { Statistics } from "app/models/statistics.model";
import { StatisticsService } from "./statistics.service";
import { MatDialogRef } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Variable } from "app/models/scenario/variables.model";
import { T } from "@angular/cdk/keycodes";

@Injectable({
    providedIn: "root",
})
export class DatabaseService {
    constructor(
        private _projectService: ProjectService,
        private _devicesService: DevicesService,
        private _projectV2Service: ProjectV2Service,
        private _scenariosService: ScenariosService,
        private _scenarioService: ScenarioService,
        private _sceneService: SceneService,
        private _libraryService: LibraryService,
        private _interfaceService: InterfaceService,
        private _userService: UserService,
        private _fuseNavigationService: FuseNavigationService,
        private _storageService: StorageService,
        private _notificationsService: NotificationsService,
        private _functionsService: FunctionsService,
        private _translocoService: TranslocoService,
        public _authService: AuthService,
        public _authService2: AuthService2,
        private _fuseConfirmationService: FuseConfirmationService,
        private _dataService: DataService,
        private _vimeoService: VimeoService,
        private _statisticsService: StatisticsService,
        private _router: Router,
    ) {
        //	this._storageService.setDatabaseService(this);
        this._router.events
            .pipe(
                filter(
                    (rs): rs is NavigationEnd => rs instanceof NavigationEnd,
                ),
            )
            .subscribe((event) => {
                if (event.id === 1 && event.url === event.urlAfterRedirects) {
                    this.pageRefresh();
                }
            });
    }

    jsonConvert = new JsonConvert();
    inProgressProjectsV2 = false;
    inProgressLibrary = false;
    inProgressUser = false;

    appFirebase: FirebaseApp = undefined;
    database: Database = undefined;
    auth: Auth = undefined;
    storage: FirebaseStorage = undefined;

    languages: any;
    countries: any;

    subscriptionsSubject: Subject<Subscription[]> = new Subject<
        Subscription[]
    >();
    subscriptions: Subscriptions;

    loginInterval: NodeJS.Timeout = undefined;

    confirmDialogRef: MatDialogRef<any> = undefined;

    stripeInProgress = false;

    diffusionsExamples: Diffusion[] = [];
    onDiffusionsExamples: Subject<Diffusion[]> = new Subject<Diffusion[]>();

    async initDatabase() {
        console.log("Init database");
        console.log(this.appFirebase);
        if (this.appFirebase === undefined) {
            // Your web app's Firebase configuration
            const firebaseConfig = {
                apiKey: environment.firebase.apiKey,
                authDomain: environment.firebase.authDomain,
                databaseURL: environment.firebase.databaseURL,
                projectId: environment.firebase.projectId,
                storageBucket: environment.firebase.storageBucket,
                messagingSenderId: environment.firebase.messagingSenderId,
                appId: environment.firebase.appId,
            };
            // Initialize Firebase
            this.appFirebase = initializeApp(firebaseConfig, "easybox");

            this.database = getDatabase(this.appFirebase);
            this.auth = getAuth(this.appFirebase);
            this.storage = getStorage(
                this.appFirebase,
                environment.firebase.storageBucket,
            );
            this._storageService.init(this.storage);
            this._interfaceService.init(this.storage, this);
            this._projectService.init(this);
            this._authService2.init(this);
            this._functionsService.init(
                getFunctions(this.appFirebase, "europe-west1"),
            );
            this._authService.init(this.auth);
            this._statisticsService.init(
                getDatabase(
                    this.appFirebase,
                    environment.firebase.statisticsURL,
                ),
            );
        }
        if (
            this.auth.currentUser === undefined ||
            this.auth.currentUser === null
        ) {
            return;
        }

        //Check if user exist
        const exist = await this.checkUserExist();

        //console.log("User Exist : " + exist);

        //console.log("Init database");

        if (
            this.user == undefined ||
            this.user == null ||
            this.user.getEmail() == ""
        ) {
            this.getUser();
        } else {
            this.emitUser();
        }
        if (this.user == undefined) {
            return;
        }
        if (
            this.project == undefined ||
            this.project == null ||
            this.project.getKey() == ""
        ) {
            this.getProject();
        } else {
            this.emitProject();
        }

        if (
            this.subscriptions == undefined ||
            this.subscriptions == null ||
            this.subscriptions.subscriptions?.length == 0
        ) {
            this.getSubscriptions();
        } else {
            this.emitSubscriptions();
        }

        if (this.devices == undefined || this.devices == null) {
            this.getDevices();
        } else {
            this.emitDevices();
        }
        if (this.checkUserList()) {
            this.getDeviceUserList();
        } else {
            this.emitDeviceUserList();
        }
        if (this.userList == undefined || this.userList == null) {
            this.getUserList();
        } else {
            this.emitUserList();
        }

        if (
            this.library == undefined ||
            this.library == null ||
            this.library.getKey() == ""
        ) {
            this.getLibrary();
        } else {
            this.emitLibrary();
        }

        if (
            this.template == undefined ||
            this.template == null ||
            this.template.getKey() == ""
        ) {
            this.getTemplate();
        } else {
            this.emitTemplate();
        }

        if (
            this.interface == undefined ||
            this.interface == null ||
            this.interface.getKey() == ""
        ) {
            this.getInterface();
        } else {
            this.emitInterface();
        }

        if (
            this.scenarios == undefined ||
            this.scenarios == null ||
            this.scenarios.length == 0
        ) {
            this.getScenarios();
        } else {
            this.emitScenarios();
        }

        if (
            this.forms == undefined ||
            this.forms == null ||
            this.forms.length == 0
        ) {
            this.getForms();
        } else {
            this.emitForms();
        }

        //console.log("Get diffusions");
        //console.log(this.diffusions);
        if (
            this.diffusions == undefined ||
            this.diffusions == null ||
            this.diffusions.length == 0
        ) {
            this.getDiffusions();
        } else {
            this.emitDiffusions();
        }

        if (this.projects == undefined || this.projects == null) {
            this.getProjectsV2();
        } else {
            this.emitProjectV2();
        }

        if (this.sharedStories == undefined || this.sharedStories == null) {
            this.getSharedStories();
        } else {
            this.emitSharedStories();
        }

        if (
            this.languages == undefined ||
            this.languages == null ||
            this.languages.length == 0
        ) {
            this.getLanguages();
        }

        if (
            this.diffusionsExamples == undefined ||
            this.diffusionsExamples == null ||
            this.diffusionsExamples.length == 0
        ) {
            this.getDiffusionsExamples();
        }

        if (this.loginInterval == undefined) {
            this.loginInterval = setInterval(
                () => {
                    this.loginLog();
                },
                1000 * 60 * environment.settings.lastlogin,
            );
        }
    }

    pageRefresh() {
        if (
            this.user == undefined ||
            this.user == null ||
            this.user.Key == ""
        ) {
            return;
        }
        this.disconnectUser();
    }

    loginLog() {
        if (
            this.user == undefined ||
            this.user == null ||
            this.user.Key == ""
        ) {
            return;
        }
        const dt = new Date();
        this.user.LastConnexion = dt;
        this.user.Connection.LastOnline = dt.getTime();
        set(
            ref(this.database, "Users/" + this.user.Key + "/LastConnexion"),
            dt.getTime(),
        )
            .then(() => {
                console.log("LastConnexion updated");
            })
            .catch((error) => {
                console.error("LastConnexion update error : " + error);
            });
        set(
            ref(
                this.database,
                "Users/" + this.user.Key + "/Connection/LastConnexion",
            ),
            dt.getTime(),
        )
            .then(() => {
                console.log("LastConnexion updated");
            })
            .catch((error) => {
                console.error("LastConnexion update error : " + error);
            });
    }

    getRefresh() {
        this.getProject();
        this.getLibrary();
        this.getInterface();
        this.getProjectsV2();
        this.getDevices();
        this.getDeviceUserList();
        this.getUserList();
    }

    getDatabasePrefix(): string {
        //console.log("getDatabasePrefi");
        //console.log(this.user);
        if (this.user == undefined) return undefined;
        return this.user.getVersion() == 0 ? "" : this.user.getVersion() + "/";
    }

    checkKeyNotEmpty(key: string): boolean {
        return key != undefined && key != null && key != "";
    }

    addDiffusionsExamples(diffusion: Diffusion) {
        if (
            this.diffusionsExamples == undefined ||
            this.diffusionsExamples == null
        ) {
            this.diffusionsExamples = [];
        }
        this.diffusionsExamples.push(diffusion);

        const de: DiffusionsExamples = new DiffusionsExamples();
        de.list = [];

        for (const d of this.diffusionsExamples) {
            de.list.push(d.key);
        }

        set(
            ref(this.database, this.user.Version + "/DiffusionsExamples"),
            this.jsonConvert.serializeObject(de),
        )
            .then(() => {
                this.diffusionsSubject.next(this.diffusionsExamples);
            })
            .catch((error) => console.error(error));
    }

    removeDiffusionsExamples(key: string) {
        if (
            this.diffusionsExamples == undefined ||
            this.diffusionsExamples == null
        ) {
            return;
        }
        const i = this.diffusionsExamples.findIndex((d) => d.key === key);
        if (i >= 0) {
            this.diffusionsExamples.splice(i, 1);
        }
        const de: DiffusionsExamples = new DiffusionsExamples();
        de.list = [];

        for (const d of this.diffusionsExamples) {
            de.list.push(d.key);
        }

        set(
            ref(this.database, this.user.Version + "/DiffusionsExamples"),
            this.jsonConvert.serializeObject(de),
        )
            .then(() => {
                this.diffusionsSubject.next(this.diffusionsExamples);
            })
            .catch((error) => console.error(error));
    }

    getDiffusionsExamples() {
        if (
            this.user == undefined ||
            this.user.Version == undefined ||
            this.user.Version == null
        ) {
            return;
        }
        get(ref(this.database, this.user.Version + "/DiffusionsExamples")).then(
            (snapshot) => {
                if (
                    snapshot == undefined ||
                    snapshot == null ||
                    snapshot.toJSON() == undefined ||
                    snapshot.toJSON() == null
                ) {
                    return;
                }

                const de: DiffusionsExamples =
                    this.jsonConvert.deserializeObject(
                        snapshot.toJSON(),
                        DiffusionsExamples,
                    );
                if (de == undefined || de == null) {
                    return;
                }
                const allPromises = [];
                for (const key of de.list) {
                    allPromises.push(
                        get(
                            ref(
                                this.database,
                                this.user.Version + "/Diffusions/" + key,
                            ),
                        ).then((snapshot) => {
                            const diffusion =
                                this.jsonConvert.deserializeObject(
                                    snapshot.toJSON(),
                                    Diffusion,
                                );
                            if (diffusion.scenario == undefined) {
                                get(
                                    ref(
                                        this.database,
                                        this.user.Version +
                                            "/Diffusions/" +
                                            key +
                                            "/Scenario",
                                    ),
                                ).then((snapshot) => {
                                    diffusion.scenario =
                                        this.jsonConvert.deserializeObject(
                                            snapshot.toJSON(),
                                            Scenario,
                                        );
                                    get(
                                        ref(
                                            this.database,
                                            this.user.Version +
                                                "/Library/" +
                                                diffusion.libraryKey,
                                        ),
                                    ).then((snapshot) => {
                                        const library: Library =
                                            this.jsonConvert.deserializeObject(
                                                snapshot.toJSON(),
                                                Library,
                                            );
                                        const content = library.contents.find(
                                            (c) =>
                                                c.key ===
                                                diffusion.scenario.Preview,
                                        );
                                        const contentName =
                                            "data" +
                                            content.Data.substring(
                                                content.Data.lastIndexOf("."),
                                            );

                                        let previewKek = "";
                                        if (
                                            diffusion.scenario.Preview !==
                                                undefined &&
                                            diffusion.scenario.Preview !==
                                                null &&
                                            diffusion.scenario.Preview !== ""
                                        ) {
                                            const content =
                                                this._libraryService.library.getContent(
                                                    diffusion.scenario.Preview,
                                                );
                                            if (
                                                content !== undefined &&
                                                content !== null
                                            ) {
                                                previewKek = content.key;
                                                diffusion.scenario.setPreviewUrl(
                                                    content.PreviewUrl,
                                                );
                                            } else {
                                                previewKek =
                                                    diffusion.scenario.getMainScene()
                                                        .mediaKey;
                                            }
                                        } else
                                            previewKek =
                                                diffusion.scenario.getMainScene()
                                                    .mediaKey;

                                        getDownloadURL(
                                            Sref(
                                                this.storage,
                                                "Library/" +
                                                    diffusion.libraryKey +
                                                    "/Contents/" +
                                                    previewKek +
                                                    "/Data/" +
                                                    contentName,
                                            ),
                                        ).then((url) => {
                                            diffusion.scenario.PreviewUrl = url;
                                            const index =
                                                this.diffusionsExamples.findIndex(
                                                    (d) =>
                                                        d.key === diffusion.key,
                                                );
                                            if (index !== -1) {
                                                this.diffusionsExamples[index] =
                                                    diffusion;
                                            } else {
                                                this.diffusionsExamples.push(
                                                    diffusion,
                                                );
                                            }
                                        });
                                    });
                                });
                            } else {
                                const index = this.diffusionsExamples.findIndex(
                                    (d) => d.key === diffusion.key,
                                );
                                if (index !== -1) {
                                    this.diffusionsExamples[index] = diffusion;
                                } else {
                                    this.diffusionsExamples.push(diffusion);
                                }
                            }
                        }),
                    );
                }
                Promise.all(allPromises).then(() => {
                    this.diffusionsSubject.next(this.diffusionsExamples);
                });
            },
        );
    }

    addExample(diffusion: Diffusion) {
        diffusion.scenario.key = this.generateKey();
        diffusion.scenario.diffusionKey = "";
        this.uploadScenario(diffusion.scenario);
        /*if (this._userService.getUser() != undefined) {
			this._userService.getUser().addScenario(diffusion.scenario.key);
			this.setUser(this._userService.getUser());
		}*/
        if (this.library == undefined) {
            this.library = new Library();
            this.library.Key = this.generateKey();
            this.user.setLibrary(this.library.Key);
        }
        if (this.library.Contents == undefined) {
            this.library.Contents = [];
        }
        get(
            ref(
                this.database,
                this.user.Version + "/Library/" + diffusion.libraryKey,
            ),
        ).then((snapshot) => {
            if (
                snapshot == undefined ||
                snapshot == null ||
                snapshot.toJSON() == undefined ||
                snapshot.toJSON() == null
            ) {
                return;
            }
            const library: Library = this.jsonConvert.deserializeObject(
                snapshot.toJSON(),
                Library,
            );
            if (library == undefined || library == null) {
                return;
            }

            for (const key of diffusion.contentKeys) {
                if (key == undefined || key == null || key == "") {
                    continue;
                }
                const content = library.contents.find((c) => c.key === key);
                const contentIndex = this.library.Contents.findIndex(
                    (c) => c.key == key,
                );
                if (contentIndex != -1)
                    this.library.Contents[contentIndex] = content;
                else this.library.Contents.push(content);

                for (let j = 0; j < content.tags.length; j++) {
                    if (
                        !this.library.Tags.find(
                            (val) => val.Name == content.tags[j],
                        )
                    ) {
                        this.library.Tags.push(
                            new Tag(
                                content.tags[j],
                                this.defaultTagColor[
                                    random(0, this.defaultTagColor.length)
                                ],
                            ),
                        );
                    }
                }
                /*await set(
					ref(this.database,this.user.Version + "/Library/" + this.library.Key + "/Contents/" + ((contentIndex==-1)? this.library.Contents.length-1 : contentIndex)),
					this.jsonConvert.serializeObject(content)
				).catch((error)=>console.error(error));*/
            }

            this.setLibrary(this.library, false);
        });

        this._userService.getUser().addScenario(diffusion.scenario.key);
        this.setUser(this._userService.getUser());
    }

    getLanguages() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        get(ref(this.database, "Languages")).then((snapshot) => {
            this.languages = snapshot.toJSON();
        });
        get(ref(this.database, "Countries")).then((snapshot) => {
            this.countries = snapshot.toJSON();
        });
    }

    async deleteLanguage(code: string) {
        //parcourir tout les content et chercher les lang
        for (const c of this.library.getContents()) {
            let index = 0;
            while (c.Languages[index] !== undefined) {
                if (c.Languages[index] === code) {
                    //delete
                    const cl = await this.getContentLanguage(
                        code,
                        this.library.Key,
                        c.key,
                    );
                    if (
                        cl !== undefined &&
                        cl.VimeoData !== undefined &&
                        cl.VimeoData.uri !== undefined
                    ) {
                        await this._functionsService.deleteVimeo(
                            cl.VimeoData.uri,
                        );
                    }
                    await this._storageService.deleteFolderContents(
                        code +
                            "/Library/" +
                            this.library.Key +
                            "/Contents/" +
                            c.key,
                    );
                    await remove(
                        ref(
                            this.database,
                            this.user.Version +
                                "/Languages/" +
                                code +
                                "/Contents/" +
                                c.key,
                        ),
                    ).catch((error) => console.error(error));
                    break;
                }
                index++;
            }
        }

        for (const diffusion of this.diffusions) {
            this.deleteDiffusionLangs(diffusion);
        }
        //parcourir les scenario et chercher les trad
        for (const sc of this.scenarios) {
            await remove(
                ref(
                    this.database,
                    this.user.Version +
                        "/Languages/" +
                        code +
                        "/Scenarios/" +
                        sc.key,
                ),
            ).catch((error) => {
                if (error !== null) {
                    console.error(error);
                }
            });
        }
    }

    async deleteDiffusionLangs(diffusion: Diffusion) {
        if (diffusion.scenario === undefined) {
            //console.warn('Attention Diffusion.Scenario undefined !');
            const scenarioSnapshot = await get(
                ref(
                    this.database,
                    this.user.getVersion() +
                        "/Diffusions/" +
                        diffusion.key +
                        "/Scenario",
                ),
            ).then();
            diffusion.scenario = this.jsonConvert.deserializeObject(
                scenarioSnapshot.toJSON(),
                Scenario,
            );
            //console.warn(diffusion.scenario);
        }

        for (const l of diffusion.scenario.languages) {
            await remove(
                ref(
                    this.database,
                    this.user.Version +
                        "/Languages/" +
                        l +
                        "/TDiffusions/" +
                        diffusion.key,
                ),
            ).catch((error) => console.error(error));
        }
        for (const l of this.user.Languages) {
            await remove(
                ref(
                    this.database,
                    this.user.Version +
                        "/Languages/" +
                        l +
                        "/TDiffusions/" +
                        diffusion.key,
                ),
            ).catch((error) => console.error(error));
        }
    }

    translateScenario: TranslateScenario[];
    tranlateScenarioKey = undefined;

    async getScenarioLang(scenario: Scenario): Promise<TranslateScenario[]> {
        if (
            this.tranlateScenarioKey === undefined ||
            this.tranlateScenarioKey !== scenario.key
        ) {
            //console.log("try Get Scenario ");
            this.tranlateScenarioKey = scenario.key;
            this.translateScenario = [];
            for (let index = 0; index < this.user.Languages.length; index++) {
                //console.log("try Get Scenario Lang : " + this.user.Languages[index]);
                const old = (
                    await get(
                        ref(
                            this.database,
                            this.user.Version +
                                "/Languages/" +
                                this.user.Languages[index] +
                                "/Scenarios/" +
                                scenario.key,
                        ),
                    )
                ).toJSON();
                this.langScenesOldToNew(old, scenario);
                this.translateScenario[index] =
                    this.jsonConvert.deserializeObject(old, TranslateScenario);
                //console.log(old);
                //console.log(this.translateScenario[index]);
                if (
                    this.translateScenario[index] === undefined ||
                    this.translateScenario[index] === null
                ) {
                    this.translateScenario[index] = new TranslateScenario();
                    this.translateScenario[index].Title = scenario.Title;
                    this.translateScenario[index].Scenes = [];
                }
                //console.log(this.translateScenario[index]);
            }
        }
        return this.translateScenario;
    }
    async setScenarioLang(
        translate: TranslateScenario,
        scenario: Scenario,
        code: string,
    ): Promise<any> {
        const index = this.user.Languages.indexOf(code);
        this.translateScenario[index] = translate;
        return set(
            ref(
                this.database,
                this.user.Version +
                    "/Languages/" +
                    code +
                    "/Scenarios/" +
                    scenario.key,
            ),
            this.jsonConvert.serializeObject(translate),
        );

        //return Promise.resolve(undefined);
    }
    async deleteScenarioLang(scenario: Scenario, code: string): Promise<any> {
        if (this.translateScenario === undefined)
            return Promise.resolve(undefined);

        const index = this.user.Languages.indexOf(code);
        if (index < this.translateScenario.length) {
            this.translateScenario[index] = undefined;
            return remove(
                ref(
                    this.database,
                    this.user.Version +
                        "/Languages/" +
                        code +
                        "/Scenarios/" +
                        scenario.key,
                ),
            ).catch((error) => console.error(error));
        }
        return Promise.resolve(undefined);
    }

    deleteSceneLang(scenario: Scenario, sceneKey: string) {
        for (let index = 0; index < this.user.Languages.length; index++) {
            const i = this.translateScenario[index].Scenes.findIndex(
                (sc) => sc.Key === sceneKey,
            );
            if (i !== -1) {
                this.translateScenario[index].Scenes.splice(i, 1);
            }
            this.setScenarioLang(
                this.translateScenario[index],
                scenario,
                this.user.Languages[index],
            );
        }
    }

    eventToTranslateEvent(ev: Event): TranslateEvent {
        const evt = new TranslateEvent();
        evt.Title = ev.title;
        if (ev.properties.Title !== undefined) {
            evt.TitleP = ev.properties.Title;
        }
        if (ev.properties.Feedback !== undefined) {
            evt.Feedback = ev.properties.Feedback;
        }
        if (ev.properties.FeedbackBad !== undefined) {
            evt.FeedbackBad = ev.properties.FeedbackBad;
        }
        if (ev.properties.FeedbackGood !== undefined) {
            evt.FeedbackGood = ev.properties.FeedbackGood;
        }
        if (ev.properties.Question !== undefined) {
            evt.Question = ev.properties.Question;
        }
        if (ev.properties.Text !== undefined) {
            evt.Text = ev.properties.Text;
        }
        if (ev.properties.TextHeader !== undefined) {
            evt.TextHeader = ev.properties.TextHeader;
        }

        if (ev.properties.Answers !== undefined) {
            let index = 0;
            for (const answer of ev.properties.Answers) {
                evt.Answers[index] = answer.Text;
                index++;
            }
        }

        return evt;
    }

    async addEventToLangs(scenario: Scenario, sceneKey: string, event: Event) {
        this.translateScenario = await this.getScenarioLang(scenario);
        let index = 0;
        for (const lang of this.translateScenario) {
            const sceneIndex = scenario.scenes.findIndex(
                (sc) => sc.key === sceneKey,
            );
            if (lang.Scenes[sceneIndex] !== undefined) {
                if (lang.Scenes[sceneIndex].Events === undefined) {
                    lang.Scenes[sceneIndex].Events = [];
                }
                lang.Scenes[sceneIndex].Events.push(
                    this.eventToTranslateEvent(event),
                );
                //console.log(lang.Scenes[scenekey].Events[event.key]);
                await this.setScenarioLang(
                    lang,
                    scenario,
                    this.user.Languages[index],
                );
            }
            index++;
        }
    }

    async deleteEventToLangs(
        scenario: Scenario,
        sceneKey: string,
        eventkey: string,
    ) {
        this.translateScenario = await this.getScenarioLang(scenario);
        let index = 0;
        for (const lang of this.translateScenario) {
            const sceneIndex = scenario.scenes.findIndex(
                (sc) => sc.key === sceneKey,
            );
            if (lang.Scenes[sceneIndex] === undefined) {
                continue;
            }
            if (lang.Scenes[sceneIndex].Events === undefined) {
                lang.Scenes[sceneIndex].Events = [];
            }
            const i = lang.Scenes[sceneIndex].Events.findIndex(
                (ev) => ev.Key === eventkey,
            );
            if (i >= 0) {
                lang.Scenes[sceneIndex].Events.splice(i, 1);
            }
            await this.setScenarioLang(
                lang,
                scenario,
                this.user.Languages[index],
            );
            index++;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ MyUsers
    // -----------------------------------------------------------------------------------------------------
    myUsers: User[] = [];
    myUsersSubject = new Subject<User[]>();

    emitMyUsersList() {
        this.myUsersSubject.next(this.myUsers);
    }

    async getMyUsersList() {
        this.myUsers = [];
        const usersKey = this.user.Versions[this.user.Version]?.MyUsers;
        for (let index = 0; index < usersKey.length; index++) {
            if (!this.checkKeyNotEmpty(usersKey[index])) continue;

            await get(ref(this.database, "Users/" + usersKey[index]))
                .then((snapshot) => {
                    const user = this.jsonConvert.deserializeObject(
                        snapshot.toJSON(),
                        User,
                    );
                    this.myUsers.push(user);
                })
                .catch((error) => console.error(error));

            /*await onValue(ref(this.database,'Users/'+ usersKey[index]), (snapshot) => {

                const user = this.jsonConvert.deserializeObject(snapshot.toJSON(), User);

                this.myUsers.push(user);
            });*/
        }
        this.emitMyUsersList();
    }

    async addMyUser(userKey: string) {
        if (this.user.Versions[this.user.Version].MyUsers == undefined) {
            this.user.Versions[this.user.Version].MyUsers = [];
        }

        this.user.Versions[this.user.Version].MyUsers.push(userKey);
        await this.updateUser(this.user);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ My User Subscription
    // -----------------------------------------------------------------------------------------------------
    userSubscriptionsSubject = new Subject<UserSubscription[]>();
    userSubscriptions: UserSubscription[] = [];
    emitUserSubscriptions() {
        this.userSubscriptionsSubject.next(this.userSubscriptions);
    }

    async getUserSubscriptions(user: User): Promise<any> {
        //console.log("getUserSubscriptions");
        //console.log(user);
        this.userSubscriptions = [];
        const subscriptionsKeys = user.Versions[user.Version].Subscriptions;
        if (!this.checkKeyNotEmpty(user.Key)) return;

        for (let index = 0; index < subscriptionsKeys.length; index++) {
            await get(
                ref(
                    this.database,
                    user.Version +
                        "/Subscriptions/" +
                        user.Key +
                        "/" +
                        subscriptionsKeys[index],
                ),
            ).then(async (snapshot): Promise<any> => {
                if (snapshot.toJSON() != null) {
                    const subscription = this.jsonConvert.deserializeObject(
                        snapshot.toJSON(),
                        UserSubscription,
                    );
                    if (subscription.diffusion == undefined) {
                        const snapshotDiffusion = await get(
                            ref(
                                this.database,
                                user.Version +
                                    "/Diffusions/" +
                                    subscription.diffusionKey,
                            ),
                        );
                        subscription.diffusion =
                            this.jsonConvert.deserializeObject(
                                snapshotDiffusion.toJSON(),
                                Diffusion,
                            );
                        if (subscription.diffusion != undefined) {
                            if (subscription.diffusion.scenario == undefined) {
                                const scenarioSnapshot = await get(
                                    ref(
                                        this.database,
                                        this.user.getVersion() +
                                            "/Diffusions/" +
                                            subscription.diffusionKey +
                                            "/Scenario",
                                    ),
                                ).then();
                                subscription.diffusion.scenario =
                                    this.jsonConvert.deserializeObject(
                                        scenarioSnapshot.toJSON(),
                                        Scenario,
                                    );
                            }
                        } else {
                            this.deleteSubscriptionKey(
                                subscriptionsKeys[index],
                                "" + user.Version,
                                user.Key,
                            );
                            const i = user.Versions[
                                user.Version
                            ].Subscriptions.findIndex((key) => {
                                return key == subscriptionsKeys[index];
                            });
                            user.Versions[user.Version].Subscriptions.splice(i);
                            this.updateUser(user);
                        }
                    }
                    this.userSubscriptions.push(subscription);
                    //console.log("Push !");
                    //console.log(subscription);
                    this.emitUserSubscriptions();
                }
            });
        }
        // this.emitUserSubscriptions();
    }

    async updateSubscriptions(
        subscriptions: UserSubscription[],
        user: User,
    ): Promise<any> {
        if (!this.checkKeyNotEmpty(user.Key)) {
            return;
        }
        for (let index = 0; index < subscriptions.length; index++) {
            const serialized =
                this.jsonConvert.serializeObject<UserSubscription>(
                    subscriptions[index],
                );

            if (!this.checkKeyNotEmpty(subscriptions[index].key)) {
                continue;
            }
            await set(
                ref(
                    this.database,
                    user.Version +
                        "/Subscriptions/" +
                        user.Key +
                        "/" +
                        subscriptions[index].key,
                ),
                serialized,
            );
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Admin
    // -----------------------------------------------------------------------------------------------------
    adminUserList: User[] = [];
    adminUserListSubject = new Subject<User[]>();

    emitAdminUserList() {
        this.adminUserListSubject.next(this.adminUserList);
    }

    getAdminUserList() {
        onValue(ref(this.database, "Users"), (snapshot) => {
            this.adminUserList = [];

            for (const key in snapshot.toJSON()) {
                const user = this.jsonConvert.deserializeObject(
                    snapshot.child(key).toJSON(),
                    User,
                );
                if (user.Type != UserType.MyUser) {
                    this.adminUserList[key] = user;
                }
            }
            this.emitAdminUserList();
        });
    }

    async updateUser(user: User): Promise<any> {
        //console.log("updateUser");
        //console.log(user);
        if (user == undefined || user.Key == "") return;
        if (user.Key == this.user.Key) {
            this.setUser(user);
            return;
        }
        for (let i = 0; i < user.Versions.length; i++) {
            if (user.Versions[i] == undefined) {
                user.Versions[i] = new UserProject();
                user.Versions[i].Projects[0] = "";
                user.Versions[i].Library = "";
            }
        }
        const userSerialized = this.jsonConvert.serializeObject<User>(user);
        if (!this.checkKeyNotEmpty(user.Key)) return;
        await set(ref(this.database, "Users/" + user.Key), userSerialized);
    }

    async deleteUser(key: string, deleteLibrary: boolean = true) {
        // var adminKey = this.auth.currentUser.uid;
        get(ref(this.database, "Users/" + key)).then(
            async (userSnapshot): Promise<any> => {
                const user = this.jsonConvert.deserializeObject(
                    userSnapshot.toJSON(),
                    User,
                );

                this._dataService.deleteCustomer({
                    customerId: user.Stripe.CustomerId,
                });

                if (user.Versions != null)
                    for (let i = 0; i < user.Versions.length; i++) {
                        const version: UserProject = user.Versions[i];
                        if (version != undefined) {
                            version.Devices.forEach((d) => {
                                get(
                                    ref(this.database, i + "/Devices/" + d),
                                ).then((deviceSnapshot) => {
                                    const userDevice =
                                        this.jsonConvert.deserializeObject(
                                            deviceSnapshot.toJSON(),
                                            Device,
                                        );

                                    this.deleteDevice(
                                        userDevice.getKey(),
                                        "" + i,
                                    );
                                });
                            });
                            version.ProjectsV2.forEach((p) => {
                                this.deleteProjectV2(p, i + "");
                            });
                            version.Scenarios.forEach((p) => {
                                this._statisticsService.deleteStatistics(p);
                                this.deleteScenarioKey(p, i + "");
                            });
                            version.Subscriptions.forEach((p) => {
                                this.deleteSubscriptionKey(p, i + "", user.Key);
                            });
                            const versionDB = i;
                            const libKey = user.getLibrary(true);
                            if (libKey != undefined && libKey.length > 0) {
                                await get(
                                    ref(
                                        this.database,
                                        i + "/Library/" + libKey,
                                    ),
                                )
                                    .then(
                                        async (
                                            librarySnapshot,
                                        ): Promise<any> => {
                                            const userLibrary =
                                                this.jsonConvert.deserializeObject(
                                                    librarySnapshot.toJSON(),
                                                    Library,
                                                );
                                            if (userLibrary != null) {
                                                await userLibrary
                                                    .getContents()
                                                    .forEach(
                                                        async (
                                                            c,
                                                        ): Promise<any> => {
                                                            if (
                                                                c !==
                                                                    undefined &&
                                                                c.VimeoData !==
                                                                    undefined &&
                                                                c.VimeoData
                                                                    .uri !==
                                                                    null &&
                                                                c.VimeoData
                                                                    .uri !== ""
                                                            ) {
                                                                this._functionsService.deleteVimeo(
                                                                    c.VimeoData
                                                                        .uri,
                                                                );
                                                            }
                                                            if (
                                                                c.Languages !==
                                                                undefined
                                                            ) {
                                                                for (const code of c.Languages) {
                                                                    const cl =
                                                                        await this.getContentLanguage(
                                                                            code,
                                                                            this
                                                                                .library
                                                                                .Key,
                                                                            c.key,
                                                                        );
                                                                    if (
                                                                        cl !==
                                                                            undefined &&
                                                                        cl.VimeoData !==
                                                                            undefined
                                                                    ) {
                                                                        this._functionsService.deleteVimeo(
                                                                            cl
                                                                                .VimeoData
                                                                                .uri,
                                                                        );
                                                                    }
                                                                    await this._storageService.deleteFolderContents(
                                                                        code +
                                                                            "/Library/" +
                                                                            libKey +
                                                                            "/Contents/" +
                                                                            c.key,
                                                                        c.key,
                                                                    );
                                                                    await this.removeContentLanguage(
                                                                        code,
                                                                        libKey,
                                                                        c.key,
                                                                    );
                                                                }
                                                            }
                                                            await this._storageService.deleteFolderContents(
                                                                "Library/" +
                                                                    libKey +
                                                                    "/Contents/" +
                                                                    c.getKey(),
                                                                c.getKey(),
                                                            );
                                                        },
                                                    );
                                            }
                                        },
                                    )
                                    .then((value) => {
                                        if (
                                            !this.checkKeyNotEmpty(
                                                versionDB + "",
                                            )
                                        )
                                            return;
                                        if (!this.checkKeyNotEmpty(libKey))
                                            return;
                                        remove(
                                            ref(
                                                this.database,
                                                versionDB +
                                                    "/Library/" +
                                                    libKey,
                                            ),
                                        ).catch();
                                    });
                            }

                            if (!this.checkKeyNotEmpty(versionDB + ""))
                                continue;
                            if (!this.checkKeyNotEmpty(version.Interface))
                                continue;
                            this._storageService.RemoveInterface(
                                version.Interface,
                            );
                            remove(
                                ref(
                                    this.database,
                                    versionDB +
                                        "/Interface/" +
                                        version.Interface,
                                ),
                            ).catch();
                        }
                    }

                if (!this.checkKeyNotEmpty(key)) return;
                await this._functionsService.deleteUser(key, user.Email);

                remove(ref(this.database, "Upload/" + key)).catch();
                //break;
            },
        );
    }

    async deleteMyUser(key: string) {
        // var adminKey = this.auth.currentUser.uid;
        get(ref(this.database, "Users/" + key)).then(
            async (userSnapshot): Promise<any> => {
                const user = this.jsonConvert.deserializeObject(
                    userSnapshot.toJSON(),
                    User,
                );

                if (user.Versions != null)
                    for (let i = 0; i < user.Versions.length; i++) {
                        const version: UserProject = user.Versions[i];
                        if (version != undefined) {
                            version.Scenarios.forEach((p) => {
                                this._statisticsService.deleteStatistics(p);
                                this.deleteScenarioKey(p, i + "");
                            });

                            version.Subscriptions.forEach((p) => {
                                this.deleteSubscriptionKey(p, i + "", user.Key);
                            });
                        }
                    }

                if (!this.checkKeyNotEmpty(key)) return;
                await this._functionsService.deleteUser(key, user.Email);

                remove(ref(this.database, "Upload/" + key)).catch();
                //break;
            },
        );
    }

    async deleteCurrentUser(): Promise<any> {
        //console.log("deleteCurrentUser");

        for (let i = 0; i < this.user.Versions.length; i++) {
            const version: UserProject = this.user.Versions[i];
            if (version != undefined) {
                //console.log("deleteDEV");
                await version.Devices.forEach(async (d): Promise<any> => {
                    await get(ref(this.database, i + "/Devices/" + d)).then(
                        async (deviceSnapshot): Promise<any> => {
                            const userDevice =
                                this.jsonConvert.deserializeObject(
                                    deviceSnapshot.toJSON(),
                                    Device,
                                );
                            await this.deleteDevice(
                                userDevice.getKey(),
                                i + "",
                            );
                        },
                    );
                });
                //console.log("deletePROJS");
                await version.ProjectsV2.forEach(async (p): Promise<any> => {
                    await this.deleteProjectV2(p, i + "");
                });

                await version.Scenarios.forEach(async (p): Promise<any> => {
                    this._statisticsService.deleteStatistics(p);
                    await this.deleteScenarioKey(p, i + "");
                });
                await version.Subscriptions.forEach(async (p): Promise<any> => {
                    await this.deleteSubscriptionKey(p, i + "", this.user.Key);
                });

                const versionDB = i;
                //console.log("deleteLIBSS");
                const libKey = this.user.getLibrary(true);
                if (libKey != undefined && libKey.length > 0)
                    await get(ref(this.database, i + "/Library/" + libKey))
                        .then(async (librarySnapshot): Promise<any> => {
                            const userLibrary =
                                this.jsonConvert.deserializeObject(
                                    librarySnapshot.toJSON(),
                                    Library,
                                );
                            if (userLibrary != null) {
                                await userLibrary
                                    .getContents()
                                    .forEach(async (c): Promise<any> => {
                                        if (!this.checkKeyNotEmpty(libKey))
                                            return;
                                        if (!this.checkKeyNotEmpty(c.getKey()))
                                            return;
                                        if (
                                            c !== undefined &&
                                            c.VimeoData !== undefined &&
                                            c.VimeoData.uri !== null &&
                                            c.VimeoData.uri !== ""
                                        ) {
                                            this._functionsService.deleteVimeo(
                                                c.VimeoData.uri,
                                            );
                                        }
                                        if (c.Languages !== undefined) {
                                            for (const code of c.Languages) {
                                                const cl =
                                                    await this.getContentLanguage(
                                                        code,
                                                        this.library.Key,
                                                        c.key,
                                                    );
                                                if (
                                                    cl !== undefined &&
                                                    cl.VimeoData !== undefined
                                                ) {
                                                    this._functionsService.deleteVimeo(
                                                        cl.VimeoData.uri,
                                                    );
                                                }
                                                await this._storageService.deleteFolderContents(
                                                    code +
                                                        "/Library/" +
                                                        this.library.Key +
                                                        "/Contents/" +
                                                        c.key,
                                                    c.key,
                                                );
                                                await this.removeContentLanguage(
                                                    code,
                                                    this.library.Key,
                                                    c.key,
                                                );
                                            }
                                        }
                                        await this._storageService.deleteFolderContents(
                                            "Library/" +
                                                libKey +
                                                "/Contents/" +
                                                c.getKey(),
                                            c.getKey(),
                                        );
                                    });
                            }
                        })
                        .then(async (value): Promise<any> => {
                            if (!this.checkKeyNotEmpty(libKey)) return;
                            await remove(
                                ref(
                                    this.database,
                                    versionDB + "/Library/" + libKey,
                                ),
                            ).catch();
                        });
                if (
                    version.Interface != undefined &&
                    version.Interface.length > 0
                ) {
                    if (!this.checkKeyNotEmpty(version.Interface)) return;
                    await this._storageService.RemoveInterface(
                        version.Interface,
                    );
                    await remove(
                        ref(
                            this.database,
                            versionDB + "/Interface/" + version.Interface,
                        ),
                    ).catch();
                }
            }
        }
        //console.log("checkKeyNotEmpty");
        if (!this.checkKeyNotEmpty(this.user.Key)) return;
        //console.log("deleteUser");
        await this._functionsService.deleteUser(this.user.Key, this.user.Email);

        //stop interval
        clearInterval(this.loginInterval);

        //await this._functionsService.deleteUser(key,user.Email,user.DefaultLang);
        //await set(ref(this.database,"Deleting/" +  this.user.Key),this.user.Key);

        await remove(ref(this.database, "Upload/" + this.user.Key)).catch();

        this.user = undefined;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Admin Users List data
    // -----------------------------------------------------------------------------------------------------
    adminUsersList: User[];
    adminUsersListSubject = new Subject<User[]>();
    adminUsersListObservable: Observable<User[]>;
    emitAdminUsersList() {
        this.adminUsersListSubject.next(this.adminUsersList);
    }

    getAdminUsersList() {
        onValue(ref(this.database, "Users"), (snapshot) => {
            this.adminUsersList = [];
            let i = 0;
            for (const key in snapshot.toJSON()) {
                const tmpUser = this.jsonConvert.deserializeObject(
                    snapshot.child(key).toJSON(),
                    User,
                );
                if (
                    tmpUser.Type == UserType.MyUser ||
                    tmpUser.Type == UserType.Device
                )
                    continue;
                this.adminUsersList[i] = tmpUser;

                i++;
            }
            if (this.adminUserList == undefined) return;
            for (let index = 0; index < this.adminUserList.length; index++) {
                if (
                    this.adminUserList[index].Type == UserType.Device &&
                    this.adminUserList[index].CreatedBy == ""
                ) {
                    let find = false;
                    for (let j = 0; j < this.adminUserList.length; j++) {
                        for (
                            let k = 0;
                            k <
                            this.adminUserList[j].Versions[
                                this.adminUserList[j].Version
                            ].Devices.length;
                            k++
                        ) {
                            if (
                                this.adminUserList[j].Versions[
                                    this.adminUserList[j].Version
                                ].Devices[k] == this.adminUserList[index].Key
                            ) {
                                this.adminUserList[index].CreatedBy =
                                    this.adminUserList[j].Key;
                                this.updateUser(this.adminUserList[index]);
                                find = true;
                                break;
                            }
                        }
                        if (find) break;
                    }
                }
            }

            this.emitAdminUsersList();
        });
    }

    findAdminUsersList(
        sortOrder = "asc",
        pageNumber = 0,
        pageSize = 10,
    ): Observable<User[]> {
        this.adminUsersListObservable = of(this.adminUsersList);

        return this.adminUsersListObservable;
    }
    checkAdminUsersList(): boolean {
        if (this.adminUsersList == undefined) {
            return true;
        }

        return false;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Errors
    // -----------------------------------------------------------------------------------------------------
    errors: any;
    errorsSubject = new Subject<any>();

    emitErrors() {
        this.errorsSubject.next(this.errors);
    }

    getErrors() {
        onValue(ref(this.database, "Errors/"), (errorsSnapshot) => {
            this.errors = errorsSnapshot.toJSON();

            this.emitErrors();
        });
    }

    removeError(key: string) {
        if (!this.checkKeyNotEmpty(key)) return;
        remove(ref(this.database, "Errors/" + key)).catch();
    }

    removeAllErrors() {
        remove(ref(this.database, "Errors")).catch();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Upload
    // -----------------------------------------------------------------------------------------------------
    uploadStateSubject = new Subject<any>();
    uploadState: any;

    emitUploadState() {
        this.uploadStateSubject.next(this.uploadState);
    }

    getUploadState() {
        const currentUser = this.auth.currentUser;

        onValue(ref(this.database, "Upload/" + currentUser.uid), (snapshot) => {
            this.uploadState = snapshot.toJSON();

            this.emitUploadState();
        });
    }

    setUploadState(state: string) {
        const currentUser = this.auth.currentUser;
        if (!this.checkKeyNotEmpty(currentUser.uid)) return;
        set(ref(this.database, "Upload/" + currentUser.uid + "/State"), state);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SCENARIO
    // -----------------------------------------------------------------------------------------------------

    scenarios: Scenario[] = [];
    scenariosSubject = new Subject<Scenario[]>();

    /**
     * Generate key
     *
     * @returns Key generated by firebase database
     */
    generateKey(): string {
        return push(ref(this.database)).key;
    }

    /**
     * Emit scenarios
     */
    emitScenarios() {
        //console.log("emitScenarios");
        this.scenariosSubject.next(this.scenarios);
    }

    getScenariosInProgress = false;

    /**
     * Get scenarios
     */
    async getScenarios(): Promise<any> {
        console.log(
            "--------------------------------------getScenarios---------------------------",
        );
        if (this.getScenariosInProgress) return;
        this.getScenariosInProgress = true;
        this.scenarios = [];
        this._scenariosService.reset();

        //console.log("getScenarios" + this.user.Key);
        if (this.user != undefined && this.user.Key != "") {
            const keys = this.user.Versions[this.user.Version].Scenarios;
            //console.log("getScenarios" + keys);
            //ref(this.database,'Users/' + user.uid + '/Versions/' + this.user.getVersion() + '/Scenarios/')).then( (userScenariosSnapshot) => {
            for (let index = 0; index < keys.length; index++) {
                //for (let key in userScenariosSnapshot.toJSON()) {
                const scenarioKey = keys[index]; //userScenariosSnapshot.toJSON()[key];
                //("scenarioKey",scenarioKey);

                //console.log("this.database",this.database);
                await get(
                    ref(
                        this.database,
                        this.getDatabasePrefix() + "Scenarios/" + scenarioKey,
                    ),
                ).then(async (scenarioSnapshot): Promise<any> => {
                    //console.log("scenarioKey 2",scenarioSnapshot);

                    const scenario = this.jsonConvert.deserializeObject(
                        scenarioSnapshot.toJSON(),
                        Scenario,
                    );
                    if (scenario == null) {
                        return;
                    }
                    if (scenario.token == "") {
                        scenario.token = this.generateKey();
                    }
                    //scenario.setPreviewUrl(this.getMainSceneContentUrl(scenario));
                    for (
                        let sceneIndex = 0;
                        sceneIndex < scenario.scenes.length;
                        sceneIndex++
                    ) {
                        const scene = scenario.scenes[sceneIndex];

                        if (
                            (scenario.Preview == undefined ||
                                scenario.Preview == "") &&
                            scene.isMain
                        ) {
                            scenario.Preview = scene.mediaKey;
                        }
                        // =======================================
                        // Trick that refresh time properties
                        scene.sceneOptions.timeOnScene.second;
                        //console.log(scene.title + " loop : "+ scene.sceneOptions.loop);
                        // =======================================
                        let index = 0;
                        for (
                            let eventIndex = 0;
                            eventIndex < scene.events.length;
                            eventIndex++
                        ) {
                            const event = scene.events[eventIndex];
                            if (event === undefined) {
                                continue;
                            }

                            const icon = event.icon;
                            const iconColor =
                                event.iconColor.length == 0
                                    ? "#ffffff"
                                    : event.iconColor;
                            const iconColor2 =
                                event.iconColor2.length == 0
                                    ? "#ffffff"
                                    : event.iconColor2;
                            const iconBackground = event.iconBackground;
                            const position = event.position;
                            const rotation = event.rotation;
                            const angle = event.angle;
                            const isLock = event.isLock;
                            const isSeen = event.isSee;
                            const advancedOptions = event.advancedOptions;

                            switch (event.type) {
                                // SceneLink
                                case "SceneLink":
                                    scene.events[eventIndex] = new SceneLink(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.DestinationKey,
                                        event.titlePosition,
                                        event.properties.TransitionType,
                                        event.properties.FadeColor,
                                        event.properties.KeepOrientation,
                                        event.properties.timeInScene,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "HeadsetSetting":
                                    // eslint-disable-next-line no-case-declarations
                                    const settings: HeadSetting[] = [];
                                    for (const key in event.properties
                                        .Parameters) {
                                        settings.push({
                                            Type: event.properties.Parameters[
                                                key
                                            ].Type,
                                            ArgBool:
                                                event.properties.Parameters[key]
                                                    .ArgBool,
                                            ArgNumber1:
                                                event.properties.Parameters[key]
                                                    .ArgNumber1,
                                            ArgNumber2:
                                                event.properties.Parameters[key]
                                                    .ArgNumber2,
                                            ArgString:
                                                event.properties.Parameters[key]
                                                    .ArgString,
                                        });
                                    }
                                    scene.events[eventIndex] =
                                        new HeadsetSetting(
                                            event.key,
                                            event.title,
                                            event.start,
                                            event.end,
                                            event.scale,
                                            event.display,
                                            event.titleType,
                                            event.iconCustom,
                                            event.iconImageKey,
                                            event.animate,
                                            event.animatetype,
                                            event.nbMaxOpenInScene,
                                            event.nbMaxOpen,
                                            event.openBy,
                                            event.titlePosition,
                                            settings,
                                        );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "SetVariable":
                                    const variable: Variable[] = [];
                                    for (const key in event.properties
                                        .Variables) {
                                        const vari: Variable = new Variable();
                                        vari.name =
                                            event.properties.Variables[
                                                key
                                            ].name;
                                        vari.type =
                                            event.properties.Variables[
                                                key
                                            ].type;
                                        for (const key2 in event.properties
                                            .Variables[key].stringvalues) {
                                            vari.stringvalues.push(
                                                event.properties.Variables[key]
                                                    .stringvalues[key2],
                                            );
                                        }
                                        //vari.stringvalues=event.properties.Variables[key].stringvalues;
                                        vari.initialvalue =
                                            event.properties.Variables[
                                                key
                                            ].initialvalue;
                                        vari.actionvalue =
                                            event.properties.Variables[
                                                key
                                            ].actionvalue;
                                        variable.push(vari);
                                    }
                                    scene.events[eventIndex] =
                                        new SetVariableEvent(
                                            event.key,
                                            event.title,
                                            event.start,
                                            event.end,
                                            event.scale,
                                            event.display,
                                            event.titleType,
                                            event.iconCustom,
                                            event.iconImageKey,
                                            event.animate,
                                            event.animatetype,
                                            event.nbMaxOpenInScene,
                                            event.nbMaxOpen,
                                            event.openBy,
                                            event.titlePosition,
                                            event.hotspotvisible,
                                            event.hotspotvisibleOnOver,
                                            event.conditions,
                                            event.openVariable,
                                            event.closeVariable,
                                            variable,
                                        );

                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "StoryLink":
                                    scene.events[eventIndex] = new StoryLink(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.DestinationKey,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "DestinationLink":
                                    scene.events[eventIndex] =
                                        new DestinationLink(
                                            event.key,
                                            event.title,
                                            event.start,
                                            event.end,
                                            event.scale,
                                            event.display,
                                            event.titleType,
                                            event.iconCustom,
                                            event.iconImageKey,
                                            event.animate,
                                            event.animatetype,
                                            event.nbMaxOpenInScene,
                                            event.nbMaxOpen,
                                            event.openBy,
                                            event.titlePosition,
                                            event.conditions,
                                            event.audioProperties,
                                            event.hotspotvisible,
                                            event.hotspotvisibleOnOver,
                                            event.OpenVariable,
                                            event.closeVariable,
                                        );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "EndLink":
                                    scene.events[eventIndex] = new EndLink(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "ContentLink":
                                    scene.events[eventIndex] = new ContentLink(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.ContentKey,
                                        event.properties.Open,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;

                                    break;
                                // Text
                                case "Text":
                                    scene.events[eventIndex] = new Text(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.TextHeader,
                                        event.properties.Text,
                                        event.properties.Alignement,
                                        event.properties.IsFullScreen,
                                        event.properties.TextColor,
                                        event.properties.BackgroundColor,
                                        event.properties.Scale,
                                        event.properties.FontStyle,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.properties.AutoQuit,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                // Image
                                case "Image":
                                    scene.events[eventIndex] = new Image(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Image,
                                        event.properties.IsFullScreen,
                                        event.properties.Scale,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "Mask360":
                                    scene.events[eventIndex] = new Mask360(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Image,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "Objet3D":
                                    scene.events[eventIndex] = new Objet3D(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.ContentKey,
                                        event.properties.Scale,
                                        event.titlePosition,
                                        event.properties.PositionX,
                                        event.properties.PositionY,
                                        event.properties.PositionZ,
                                        event.properties.RotateSpeed,
                                        event.properties.RotateX,
                                        event.properties.RotateY,
                                        event.properties.RotateZ,
                                        event.properties.RotationX,
                                        event.properties.RotationY,
                                        event.properties.RotationZ,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "Avatar":
                                    scene.events[eventIndex] = new Avatar(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.ContentKey,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.properties.Scale,
                                        event.titlePosition,
                                        event.properties.PositionX,
                                        event.properties.PositionY,
                                        event.properties.PositionZ,
                                        event.properties.RotationX,
                                        event.properties.RotationY,
                                        event.properties.RotationZ,
                                        event.properties.EventLinked,
                                        event.properties.Delay,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "SelectLanguage":
                                    scene.events[eventIndex] =
                                        new SelectLanguage(
                                            event.key,
                                            event.title,
                                            event.start,
                                            event.end,
                                            event.scale,
                                            event.display,
                                            event.titleType,
                                            event.iconCustom,
                                            event.iconImageKey,
                                            event.animate,
                                            event.animatetype,
                                            event.nbMaxOpenInScene,
                                            event.nbMaxOpen,
                                            event.openBy,
                                            event.properties.Title,
                                            event.properties.Unique,
                                            event.properties.Langs,
                                            event.properties.Lang,
                                            event.properties.Image,
                                            event.properties.IsFullScreen,
                                            event.properties.Scale,
                                            event.properties.Audio,
                                            event.properties.Volume,
                                            event.titlePosition,
                                            event.conditions,
                                            event.audioProperties,
                                            event.hotspotvisible,
                                            event.hotspotvisibleOnOver,
                                            event.OpenVariable,
                                            event.closeVariable,
                                        );
                                    break;
                                case "Diaporama":
                                    const images = event.properties.Images;
                                    const resultat = [];
                                    if (images != undefined) {
                                        for (
                                            let index = 0;
                                            index < Object.keys(images).length;
                                            index++
                                        ) {
                                            resultat.push(images[index]);
                                        }
                                    }

                                    scenario.scenes[sceneIndex].events[
                                        eventIndex
                                    ] = new Diaporama(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        resultat /*event.properties.Images*/,
                                        event.properties.IsFullScreen,
                                        event.properties.Scale,
                                        event.properties.StartFullScreen,
                                        event.properties.Auto,
                                        event.properties.ShowBtn,
                                        event.properties.ShowNum,
                                        event.properties.Time,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scenario.scenes[sceneIndex].events[
                                        eventIndex
                                    ].hotspot = event.hotspot;

                                    break;
                                // Video
                                case "Video":
                                    scene.events[eventIndex] = new Video(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Video,
                                        event.properties.Loop,
                                        event.properties.Volume,
                                        event.properties.IsFullScreen,
                                        event.properties.Scale,
                                        event.titlePosition,
                                        event.properties.WebFull,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                // Audio
                                case "Audio":
                                    scene.events[eventIndex] = new Audio(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.properties.Loop,
                                        event.properties.AutoPause,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    //scene.events[eventIndex].properties.Loop = event.properties.Loop;

                                    break;
                                // WebLink
                                case "WebLink":
                                    scene.events[eventIndex] = new WebLink(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Url,
                                        event.properties.Iframe,
                                        event.properties.SafeUrl,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                // Zone
                                case "Zone":
                                    scene.events[eventIndex] = new Zone(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Radius,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot = false;
                                    break;
                                // Quiz
                                case "QCM":
                                    const answersQCM: QuizAnswer[] = [];
                                    for (const key in event.properties
                                        .Answers) {
                                        answersQCM.push({
                                            Text: event.properties.Answers[key]
                                                .Text,
                                            Points: event.properties.Answers[
                                                key
                                            ].Points,
                                            Checked:
                                                event.properties.Answers[key]
                                                    .Checked,
                                            Key: event.properties.Answers[key]
                                                .Key,
                                            Variable:
                                                event.properties.Answers[key]
                                                    .Variable === undefined
                                                    ? new Variable()
                                                    : event.properties.Answers[
                                                          key
                                                      ].Variable,
                                        });
                                    }

                                    const groupstoaddQCM: GroupRecognition[] =
                                        [];
                                    index = 0;
                                    if (event.properties.groups !== undefined) {
                                        while (
                                            event.properties.groups[index] !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];
                                            const gre = new GroupRecognition();
                                            let i = 0;
                                            if (gp !== undefined) {
                                                while (
                                                    gp.events !== undefined &&
                                                    gp.events[i] !== undefined
                                                ) {
                                                    gre.events.push(
                                                        gp.events[i],
                                                    );
                                                    i++;
                                                }
                                            }
                                            groupstoaddQCM.push(gre);
                                            index++;
                                        }
                                    } else {
                                        groupstoaddQCM.push(
                                            new GroupRecognition(),
                                        ); //on en ajoute 2 pour les feedbacks
                                        groupstoaddQCM.push(
                                            new GroupRecognition(),
                                        );
                                    }

                                    scene.events[eventIndex] = new Quiz(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        EventType.QCM,
                                        event.properties.Question,
                                        answersQCM,
                                        event.properties.FeedbackGood,
                                        event.properties.AudioGood,
                                        event.properties.FeedbackBad,
                                        event.properties.AudioBad,
                                        event.properties.Time,
                                        event.properties.IsFullScreen,
                                        event.properties.Image,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.properties.CluesHelpKey,
                                        event.properties.CluesWinKey,
                                        event.properties.CluesWinBool,
                                        event.properties.AnswerText,
                                        event.properties.Correct,
                                        event.properties.AutoQuit,
                                        event.properties.Zoom,
                                        groupstoaddQCM,
                                        event.conditions,
                                        event.properties.idScorm,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;

                                    break;
                                case "QCU":
                                    const answersQCU: QuizAnswer[] = [];

                                    for (const key in event.properties
                                        .Answers) {
                                        answersQCU.push({
                                            Text: event.properties.Answers[key]
                                                .Text,
                                            Points: event.properties.Answers[
                                                key
                                            ].Points,
                                            Checked:
                                                event.properties.Answers[key]
                                                    .Checked,
                                            Key: event.properties.Answers[key]
                                                .Key,
                                            Variable:
                                                event.properties.Answers[key]
                                                    .Variable === undefined
                                                    ? new Variable()
                                                    : event.properties.Answers[
                                                          key
                                                      ].Variable,
                                        });
                                    }
                                    const groupstoaddQCU: GroupRecognition[] =
                                        [];
                                    index = 0;
                                    if (event.properties.groups !== undefined) {
                                        while (
                                            event.properties.groups[index] !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];
                                            const gr2 = new GroupRecognition();
                                            let i = 0;
                                            if (gp !== undefined) {
                                                while (
                                                    gp.events !== undefined &&
                                                    gp.events[i] !== undefined
                                                ) {
                                                    gr2.events.push(
                                                        gp.events[i],
                                                    );
                                                    i++;
                                                }
                                            }
                                            groupstoaddQCU.push(gr2);
                                            index++;
                                        }
                                    } else {
                                        groupstoaddQCU.push(
                                            new GroupRecognition(),
                                        ); //on en ajoute 2 pour les feedbacks
                                        groupstoaddQCU.push(
                                            new GroupRecognition(),
                                        );
                                    }

                                    scene.events[eventIndex] = new Quiz(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        EventType.QCU,
                                        event.properties.Question,
                                        answersQCU,
                                        event.properties.FeedbackGood,
                                        event.properties.AudioGood,
                                        event.properties.FeedbackBad,
                                        event.properties.AudioBad,
                                        event.properties.Time,
                                        event.properties.IsFullScreen,
                                        event.properties.Image,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.properties.CluesHelpKey,
                                        event.properties.CluesWinKey,
                                        event.properties.CluesWinBool,
                                        event.properties.AnswerText,
                                        event.properties.Correct,
                                        event.properties.AutoQuit,
                                        event.properties.Zoom,
                                        groupstoaddQCU,
                                        event.conditions,
                                        event.properties.idScorm,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;

                                    break;
                                case "QCO":
                                    const groupstoaddGCO: GroupRecognition[] =
                                        [];

                                    index = 0;
                                    if (event.properties.groups !== undefined) {
                                        while (
                                            event.properties.groups[index] !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];
                                            const gc = new GroupRecognition();
                                            let i = 0;
                                            if (gp !== undefined) {
                                                while (
                                                    gp.events !== undefined &&
                                                    gp.events[i] !== undefined
                                                ) {
                                                    gc.events.push(
                                                        gp.events[i],
                                                    );
                                                    i++;
                                                }
                                            }
                                            groupstoaddGCO.push(gc);
                                            index++;
                                        }
                                    } else {
                                        groupstoaddGCO.push(
                                            new GroupRecognition(),
                                        ); //on en ajoute 2 pour les feedbacks
                                        groupstoaddGCO.push(
                                            new GroupRecognition(),
                                        );
                                    }

                                    scene.events[eventIndex] = new QCO(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        EventType.QCO,
                                        event.properties.Question,
                                        event.properties.AnswerText,
                                        event.properties.FeedbackGood,
                                        event.properties.AudioGood,
                                        event.properties.FeedbackBad,
                                        event.properties.AudioBad,
                                        event.properties.Time,
                                        event.properties.IsFullScreen,
                                        event.properties.Image,
                                        event.properties.GoodPoints,
                                        event.properties.BadPoints,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.properties.CluesHelpKey,
                                        event.properties.CluesWinKey,
                                        event.properties.CluesWinBool,
                                        event.properties.Correct,
                                        event.properties.AutoQuit,
                                        event.properties.Zoom,
                                        groupstoaddGCO,
                                        event.conditions,
                                        event.properties.idScorm,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;

                                    break;
                                case "QuizHotspot":
                                    const quizHotspotAnswer: QuizHotspotAnswer[] =
                                        [];
                                    for (const key in event.properties
                                        .HotspotAnswers) {
                                        quizHotspotAnswer.push({
                                            Position: new Point(
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Position.X,
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Position.Y,
                                            ),
                                            Angle: new Angle(
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Angle.Alpha,
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Angle.Beta,
                                            ),
                                            Points: event.properties
                                                .HotspotAnswers[key].Points,
                                            Checked:
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Checked,
                                            ImageKey:
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].ImageKey === undefined
                                                    ? ""
                                                    : event.properties
                                                          .HotspotAnswers[key]
                                                          .ImageKey,
                                            ImageSize:
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].ImageSize === undefined
                                                    ? 1
                                                    : event.properties
                                                          .HotspotAnswers[key]
                                                          .ImageSize,
                                            Variable:
                                                event.properties.HotspotAnswers[
                                                    key
                                                ].Variable === undefined
                                                    ? new Variable()
                                                    : event.properties
                                                          .HotspotAnswers[key]
                                                          .Variable,
                                        });
                                    }

                                    const groupstoadd: GroupRecognition[] = [];
                                    index = 0;
                                    if (event.properties.groups !== undefined) {
                                        while (
                                            event.properties.groups[index] !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];
                                            const g = new GroupRecognition();
                                            let i = 0;
                                            if (gp !== undefined) {
                                                while (
                                                    gp.events !== undefined &&
                                                    gp.events[i] !== undefined
                                                ) {
                                                    g.events.push(gp.events[i]);
                                                    i++;
                                                }
                                            }
                                            groupstoadd.push(g);
                                            index++;
                                        }
                                    } else {
                                        groupstoadd.push(
                                            new GroupRecognition(),
                                        ); //on en ajoute 2 pour les feedbacks
                                        groupstoadd.push(
                                            new GroupRecognition(),
                                        );
                                    }

                                    scene.events[eventIndex] = new QuizHotspot(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Question,
                                        quizHotspotAnswer,
                                        event.properties.FeedbackGood,
                                        event.properties.AudioGood,
                                        event.properties.FeedbackBad,
                                        event.properties.AudioBad,
                                        event.properties.Time,
                                        event.properties.Answer,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.properties.CluesHelpKey,
                                        event.properties.CluesWinKey,
                                        event.properties.CluesWinBool,
                                        event.properties.Correct,
                                        event.properties.AutoQuit,
                                        groupstoadd,
                                        event.conditions,
                                        event.properties.idScorm,
                                        event.audioProperties,
                                        event.properties.respectOrder,
                                        event.properties.multiChoice,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;

                                    break;
                                case "Debriefing":
                                    const feedback: Feedback[] = [];
                                    index = 0;
                                    const groupsdeb: GroupRecognition[] = [];
                                    for (const key in event.properties
                                        .Feedback) {
                                        feedback.push({
                                            PourcentMin:
                                                event.properties.Feedback[key]
                                                    .PourcentMin,
                                            PourcentMax:
                                                event.properties.Feedback[key]
                                                    .PourcentMax,
                                            Description:
                                                event.properties.Feedback[key]
                                                    .Description,
                                            AudioKey:
                                                event.properties.Feedback[key]
                                                    .AudioKey,
                                            AudioVolume:
                                                event.properties.Feedback[key]
                                                    .AudioVolume,
                                        });

                                        const g = new GroupRecognition();
                                        if (
                                            event.properties.groups !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];

                                            g.events = [];
                                            let i = 0;
                                            if (gp !== undefined) {
                                                while (
                                                    gp.events !== undefined &&
                                                    gp.events[i] !== undefined
                                                ) {
                                                    g.events.push(gp.events[i]);
                                                    i++;
                                                }
                                            }
                                        }
                                        index++;
                                        groupsdeb.push(g);
                                    }

                                    scene.events[eventIndex] = new Debriefing(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        feedback,
                                        event.properties.IsFullScreen,
                                        event.properties.Text,
                                        event.properties.Title,
                                        event.properties.StoryEnd,
                                        event.titlePosition,
                                        event.properties.QuestionsSetKey,
                                        event.properties.DisplayType ===
                                        undefined
                                            ? 0
                                            : event.properties.DisplayType,
                                        groupsdeb,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "Consigne":
                                    scene.events[eventIndex] = new Consigne(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.properties.Title,
                                        event.properties.Text,
                                        event.properties.Image,
                                        event.properties.Button,
                                        event.properties.IsFullScreen,
                                        event.properties.Scale,
                                        event.properties.Audio,
                                        event.properties.Volume,
                                        event.titlePosition,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "Form":
                                    scene.events[eventIndex] = new FormEvent(
                                        event.key,
                                        event.title,
                                        event.start,
                                        event.end,
                                        event.scale,
                                        event.display,
                                        event.titleType,
                                        event.iconCustom,
                                        event.iconImageKey,
                                        event.animate,
                                        event.animatetype,
                                        event.nbMaxOpenInScene,
                                        event.nbMaxOpen,
                                        event.openBy,
                                        event.titlePosition,
                                        event.properties.Form,
                                        event.properties.Title,
                                        event.properties.Description,
                                        event.conditions,
                                        event.audioProperties,
                                        event.hotspotvisible,
                                        event.hotspotvisibleOnOver,
                                        event.OpenVariable,
                                        event.closeVariable,
                                    );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                case "VoiceRecognition":
                                    index = 0;
                                    const groups: GroupRecognition[] = [];
                                    if (event.properties.groups !== undefined) {
                                        while (
                                            event.properties.groups[index] !==
                                            undefined
                                        ) {
                                            const gp =
                                                event.properties.groups[index];
                                            const g = new GroupRecognition();
                                            g.words = [];
                                            g.events = [];
                                            let i = 0;
                                            while (
                                                gp.words !== undefined &&
                                                gp.words[i] !== undefined
                                            ) {
                                                g.words.push(gp.words[i]);
                                                i++;
                                            }
                                            i = 0;
                                            while (
                                                gp.events !== undefined &&
                                                gp.events[i] !== undefined
                                            ) {
                                                g.events.push(gp.events[i]);
                                                i++;
                                            }

                                            g.points =
                                                gp.points === undefined
                                                    ? 0
                                                    : gp.points;
                                            groups.push(g);
                                            index++;
                                        }
                                    }
                                    scene.events[eventIndex] =
                                        new VoiceRecognition(
                                            event.key,
                                            event.title,
                                            event.start,
                                            event.end,
                                            event.scale,
                                            event.display,
                                            event.titleType,
                                            event.iconCustom,
                                            event.iconImageKey,
                                            event.animate,
                                            event.animatetype,
                                            event.nbMaxOpenInScene,
                                            event.nbMaxOpen,
                                            event.openBy,
                                            event.titlePosition,
                                            groups,
                                            event.properties.pause,
                                            event.properties.reduceVolume,
                                            event.properties.allList,
                                            event.properties.showName,
                                            event.properties.isquizz,
                                            event.properties.questionsSetKey,
                                            event.conditions,
                                            event.audioProperties,
                                            event.hotspotvisible,
                                            event.hotspotvisibleOnOver,
                                            event.OpenVariable,
                                            event.closeVariable,
                                        );
                                    scene.events[eventIndex].hotspot =
                                        event.hotspot;
                                    break;
                                default:
                                    break;
                            }

                            // =======================================
                            // Trick that refresh time properties
                            scene.events[eventIndex].start.second;
                            scene.events[eventIndex].end.second;
                            // =======================================

                            scene.events[eventIndex].icon = icon;
                            scene.events[eventIndex].iconColor = iconColor;
                            scene.events[eventIndex].iconColor2 = iconColor2;
                            scene.events[eventIndex].iconBackground =
                                iconBackground;
                            scene.events[eventIndex].position = position;
                            scene.events[eventIndex].rotation = rotation;
                            scene.events[eventIndex].angle = angle;
                            scene.events[eventIndex].isLock = isLock;
                            scene.events[eventIndex].isSee = isSeen;
                            scene.events[eventIndex].advancedOptions =
                                advancedOptions;
                            if (isLock) {
                                scene.events[eventIndex].lockIcon = "lock";
                            } else {
                                scene.events[eventIndex].lockIcon = "lock_open";
                            }

                            if (isSeen)
                                scene.events[eventIndex].seeIcon = "visibility";
                            else
                                scene.events[eventIndex].seeIcon =
                                    "visibility_off";
                        }
                    }
                    if (this.library !== undefined && this.library !== null) {
                        const content = this.library.getContent(
                            scenario.Preview,
                        );
                        scenario.PreviewUrl =
                            content != undefined ? content.PreviewUrl : "";
                        if (content != undefined && content.PreviewUrl == "") {
                            this.getPreviewURL(this.library.Key, content);
                        }
                    }
                    if (
                        scenario.version == scenario.versionDiffusion ||
                        scenario.diffusionKey.length != 0
                    ) {
                        scenario.diffusionState = "published";
                    } else {
                        scenario.diffusionState = "not_published";
                    }
                    this._scenarioService.setDatabaseService(this);
                    const isReadyForDiffusion =
                        this._scenarioService.readyForDiffusion(scenario, this);

                    if (
                        isReadyForDiffusion.isReady &&
                        scenario.diffusionState == "published" &&
                        scenario.version != scenario.versionDiffusion
                    ) {
                        scenario.state = "update";
                    } else if (isReadyForDiffusion.isReady) {
                        scenario.state = "ready";
                    } else {
                        scenario.state = "error";
                    }

                    const i = this.scenarios.findIndex((item) => {
                        item.key == scenario.key;
                    });
                    if (i != -1) {
                        this.scenarios[i] = scenario;
                    } else {
                        this.scenarios.push(scenario);
                        this._scenariosService.push(scenario);
                    }
                });
            }

            //console.log("emitScenarios2");
            this.emitScenarios();
        }

        this.getScenariosInProgress = false;
        //console.log("-----------getScenarios end------------ ");
    }

    SetScenariosPreview() {
        if (this.library === undefined || this.scenarios === undefined) return;
        let emit = false;
        for (let index = 0; index < this.scenarios.length; index++) {
            const scenario = this.scenarios[index];
            if (
                scenario.Preview !== undefined &&
                scenario.Preview !== null &&
                scenario.Preview !== ""
            ) {
                const content = this.library.getContent(scenario.Preview);
                if (content !== undefined && content !== null) {
                    scenario.PreviewUrl = content.PreviewUrl;
                    emit = true;
                } else {
                    scenario.PreviewUrl = this.getMainSceneContentUrl(scenario);
                }
            } else {
                scenario.PreviewUrl = this.getMainSceneContentUrl(scenario);
            }
        }
        if (emit) this.emitScenarios();
    }
    /**
     * Get main scene content url
     * @param scenario
     *
     * @returns String url if the content is found
     */
    getMainSceneContentUrl(sc: Scenario): string {
        if (sc.getMainScene() === undefined || sc.getMainScene() === null)
            return "";
        const content = this.library.getContent(sc.getMainScene().mediaKey);

        if (content == null || content == undefined) {
            return "";
        } else {
            return content.getPreviewUrl();
        }
    }

    /**
     * Upload scenario to database
     *
     * @param {Scenario} scenario
     */
    uploadScenario(scenario: Scenario) {
        if (scenario == undefined) {
            return;
        }
        if (!this.checkKeyNotEmpty(scenario.key)) return;
        if (scenario.key == "Error Key") {
            return;
        }

        if (this._userService.getUser() == undefined) {
            return;
        }

        const databaseVersion = this._userService.getUser().getVersion();

        //console.log("uploadScenario");
        //console.log(scenario);

        const scenarioSerialized =
            this.jsonConvert.serializeObject<Scenario>(scenario);
        set(
            ref(this.database, databaseVersion + "/Scenarios/" + scenario.key),
            scenarioSerialized,
        ).then(
            () => console.log("scenario Set"),
            () => console.error("scenario set Error!"),
        );

        if (this.scenarios == undefined) {
            this.scenarios = [];
        }
        const index = this.scenarios.findIndex((sc) => sc.key == scenario.key);
        if (index == -1) {
            this.scenarios.push(scenario);
            //console.log("emitScenarios4");
            this.emitScenarios();
        }
    }

    updateEvent(scenario: Scenario, scene: Scene, event: Event) {
        const databaseVersion = this._userService.getUser().getVersion();
        const indexSc = scenario.scenes.findIndex((sc) => sc.key == scene.key);
        const indexEvt = scene.events.findIndex((ev) => ev.key == event.key);

        const jsonEvent = this.jsonConvert.serializeObject<Event>(event);

        set(
            ref(
                this.database,
                databaseVersion +
                    "/Scenarios/" +
                    scenario.key +
                    "/Scenes/" +
                    indexSc +
                    "/Events/" +
                    indexEvt +
                    "/",
            ),
            jsonEvent,
        ).then(
            () => console.log("Event Set"),
            () => console.error("Event set Error!"),
        );
    }

    updateEventVisibility(scenario: Scenario, scene: Scene, event: Event) {
        const databaseVersion = this._userService.getUser().getVersion();
        const indexSc = scenario.scenes.findIndex((sc) => sc.key == scene.key);
        const indexEvt = scene.events.findIndex((ev) => ev.key == event.key);

        const jsonEvent = this.jsonConvert.serializeObject<Event>(event);

        set(
            ref(
                this.database,
                databaseVersion +
                    "/Scenarios/" +
                    scenario.key +
                    "/Scenes/" +
                    indexSc +
                    "/Events/" +
                    indexEvt +
                    "/IsSee",
            ),
            event.isSee,
        ).then(
            () => console.log("Event Set"),
            () => console.error("Event set Error!"),
        );
    }

    updateEventLock(scenario: Scenario, scene: Scene, event: Event) {
        const databaseVersion = this._userService.getUser().getVersion();
        const indexSc = scenario.scenes.findIndex((sc) => sc.key == scene.key);
        const indexEvt = scene.events.findIndex((ev) => ev.key == event.key);

        const jsonEvent = this.jsonConvert.serializeObject<Event>(event);

        set(
            ref(
                this.database,
                databaseVersion +
                    "/Scenarios/" +
                    scenario.key +
                    "/Scenes/" +
                    indexSc +
                    "/Events/" +
                    indexEvt +
                    "/IsLock",
            ),
            event.isLock,
        ).then(
            () => console.log("Event Set"),
            () => console.error("Event set Error!"),
        );
    }

    /**
     * Push value to list if it's not included
     *
     * @param list
     * @param value
     */
    private pushIfNotInclude(list: string[], value: string) {
        if (!list.includes(value)) {
            list.push(value);
        }
    }

    /**
     * Publish scenario
     *
     * @param {Scenario} scenario
     */
    async publishScenario(
        scenario: Scenario,
        diffusion: Diffusion,
        isUpdate = false,
    ) {
        if (scenario.getKey() == "Error key") {
            return;
        }

        const databaseVersion = this._userService.getUser().getVersion();

        // Get all content keys to upload a copy
        const contentKeys: string[] = this.getAllContentsFromStory(scenario);

        if (diffusion.key.length == 0) {
            diffusion.key = this.generateKey();
        }
        scenario.languages = this.user.Languages;
        scenario.diffusionKey = diffusion.key;
        diffusion.contentKeys = contentKeys;

        if (isUpdate) {
            const title = diffusion.scenario.title;
            const description = diffusion.scenario.description;
            const preview = diffusion.scenario.Preview;

            diffusion.scenario = scenario;

            diffusion.scenario.title = title;
            diffusion.scenario.languages = this.user.Languages;
            diffusion.scenario.description = description;
            diffusion.scenario.Preview = preview;
        } else {
            diffusion.scenario = scenario;
        }

        diffusion.libraryKey = this._libraryService.library.getKey();

        if (this._userService.getUser() != undefined) {
            if (
                !this._userService
                    .getUser()
                    .getDiffusions()
                    .includes(diffusion.key)
            ) {
                this._userService.getUser().addDiffusion(diffusion.key);
            }

            this.setUser(this._userService.getUser());
        }

        this.uploadScenario(scenario);

        const diffusionSerialized =
            this.jsonConvert.serializeObject<Diffusion>(diffusion);
        if (!this.checkKeyNotEmpty(diffusion.key)) return;

        await this.updateDiffusionLangs(scenario, diffusion);

        return set(
            ref(
                this.database,
                databaseVersion + "/Diffusions/" + diffusion.key,
            ),
            diffusionSerialized,
        );
    }
    langScenesOldToNew(old: any, scenario: Scenario) {
        if (
            old !== undefined &&
            old !== null &&
            old["Scenes"] !== undefined &&
            old["Scenes"].length === 0
        ) {
            const scenes = [];
            for (let index = 0; index < scenario.scenes.length; index++) {
                if (old["Scenes"][scenario.scenes[index].key] !== undefined) {
                    old["Scenes"][scenario.scenes[index].key].Key =
                        scenario.scenes[index].key;
                    const events = [];
                    for (
                        let index2 = 0;
                        index2 < scenario.scenes[index].events.length;
                        index2++
                    ) {
                        if (
                            old["Scenes"][scenario.scenes[index].key].Events[
                                scenario.scenes[index].events[index2].key
                            ] !== undefined
                        ) {
                            old["Scenes"][scenario.scenes[index].key].Events[
                                scenario.scenes[index].events[index2].key
                            ].Key = scenario.scenes[index].events[index2].key;
                            events.push(
                                old["Scenes"][scenario.scenes[index].key]
                                    .Events[
                                    scenario.scenes[index].events[index2].key
                                ],
                            );
                        }
                    }
                    old["Scenes"][scenario.scenes[index].key].Events = events;
                    scenes.push(old["Scenes"][scenario.scenes[index].key]);
                }
            }
            old["Scenes"] = scenes;
        }
    }
    async updateDiffusionLangs(scenario: Scenario, diffusion: Diffusion) {
        for (const l of this.user.Languages) {
            const json = await get(
                ref(
                    this.database,
                    this.user.Version +
                        "/Languages/" +
                        l +
                        "/Scenarios/" +
                        scenario.key,
                ),
            );
            const obj = json?.toJSON();

            if (json != undefined && obj != undefined) {
                this.langScenesOldToNew(obj, scenario);

                const sc: TranslateScenario =
                    this.jsonConvert.deserializeObject(obj, TranslateScenario);

                await set(
                    ref(
                        this.database,
                        this.user.Version +
                            "/Languages/" +
                            l +
                            "/TDiffusions/" +
                            diffusion.key,
                    ),
                    this.jsonConvert.serializeObject(sc),
                );
            }
        }
    }

    getAllContentsFromStory(story: Scenario): string[] {
        // Get all content keys to upload a copy
        const contentKeys: string[] = [];

        if (story.Preview != undefined && story.Preview != "") {
            this.pushIfNotInclude(contentKeys, story.Preview);
        }
        if (story.audioKey != undefined && story.audioKey != "") {
            this.pushIfNotInclude(contentKeys, story.audioKey);
        }
        if (story.gpxKey != undefined && story.gpxKey != "") {
            this.pushIfNotInclude(contentKeys, story.gpxKey);
        }

        //Template
        if (story.htmlTemplate) {
            if (story.htmlOptions.logo && story.htmlOptions.logoKey != "")
                this.pushIfNotInclude(contentKeys, story.htmlOptions.logoKey);
            if (story.htmlOptions.plan && story.gpxKey !== "")
                this.pushIfNotInclude(contentKeys, story.gpxKey);
            if (
                story.htmlOptions.description &&
                story.htmlOptions.descriptionKey != ""
            )
                this.pushIfNotInclude(
                    contentKeys,
                    story.htmlOptions.descriptionKey,
                );
            if (story.htmlOptions.floor && story.htmlOptions.floorKey != "")
                this.pushIfNotInclude(contentKeys, story.htmlOptions.floorKey);
            if (story.htmlOptions.logo && story.htmlOptions.logoKey != "")
                this.pushIfNotInclude(contentKeys, story.htmlOptions.logoKey);
            if (story.htmlOptions.nadir && story.htmlOptions.nadirKey != "")
                this.pushIfNotInclude(contentKeys, story.htmlOptions.nadirKey);
        }

        this.pushIfNotInclude(contentKeys, story.audioKey);
        // Get through scenes
        for (let index = 0; index < story.scenes.length; index++) {
            const scene = story.scenes[index];

            // Scene media key
            this.pushIfNotInclude(contentKeys, scene.mediaKey);

            if (
                scene.sceneOptions.audioKey != undefined &&
                scene.sceneOptions.audioKey != ""
            )
                this.pushIfNotInclude(contentKeys, scene.sceneOptions.audioKey);

            if (
                scene.sceneOptions.obj3DKey != undefined &&
                scene.sceneOptions.obj3DKey != ""
            )
                this.pushIfNotInclude(contentKeys, scene.sceneOptions.obj3DKey);

            // Get through events
            for (let j = 0; j < scene.events.length; j++) {
                const event = scene.events[j];
                if (event == undefined) continue;

                if (
                    event.properties.Image != undefined &&
                    event.properties.Image != ""
                )
                    this.pushIfNotInclude(contentKeys, event.properties.Image);

                if (
                    event.properties.Images != undefined &&
                    event.properties.Images.lenght != 0
                )
                    for (let k = 0; k < event.properties.Images.length; k++) {
                        this.pushIfNotInclude(
                            contentKeys,
                            event.properties.Images[k],
                        );
                    }

                if (
                    event.properties.Answers != undefined &&
                    event.properties.Answers.lenght != 0
                ) {
                    for (let k = 0; k < event.properties.Answers.length; k++) {
                        const key = event.properties.Answers[k].Key;
                        if (key != undefined && key != "") {
                            this.pushIfNotInclude(contentKeys, key);
                        }
                    }
                }

                if (
                    event.properties.feedback != undefined &&
                    event.properties.feedback.lenght != 0
                ) {
                    for (let k = 0; k < event.properties.feedback.length; k++) {
                        const key = event.properties.feedback[k].AudioKey;
                        if (key != undefined && key != "") {
                            this.pushIfNotInclude(contentKeys, key);
                        }
                    }
                }

                if (event.audioProperties != undefined) {
                    this.pushIfNotInclude(
                        contentKeys,
                        event.audioProperties.openingmedia,
                    );
                }

                if (
                    event.properties.Video != undefined &&
                    event.properties.Video != ""
                )
                    this.pushIfNotInclude(contentKeys, event.properties.Video);

                if (
                    event.properties.Audio != undefined &&
                    event.properties.Audio != ""
                )
                    this.pushIfNotInclude(contentKeys, event.properties.Audio);

                if (
                    event.properties.AudioGood != undefined &&
                    event.properties.AudioGood != ""
                )
                    this.pushIfNotInclude(
                        contentKeys,
                        event.properties.AudioGood,
                    );

                if (
                    event.properties.AudioBad != undefined &&
                    event.properties.AudioBad != ""
                )
                    this.pushIfNotInclude(
                        contentKeys,
                        event.properties.AudioBad,
                    );

                if (
                    event.properties.ContentKey != undefined &&
                    event.properties.ContentKey != ""
                )
                    this.pushIfNotInclude(
                        contentKeys,
                        event.properties.ContentKey,
                    );

                if (event.iconCustom) {
                    this.pushIfNotInclude(contentKeys, event.iconImageKey);
                }
            }
        }
        return contentKeys;
    }

    //-------------------------------------------------------------------------------
    //  Share Story
    //-------------------------------------------------------------------------------

    sendStory(scenario: Scenario, dest: string): ShareScenario {
        const jsonConvert = new JsonConvert();
        const story: Scenario = this.jsonConvert.deserializeObject(
            jsonConvert.serializeObject<Scenario>(scenario),
            Scenario,
        );
        story.key = this.generateKey();
        story.diffusionKey = "";
        story.version = 0;
        story.versionDiffusion = -1;
        story.state = "";
        const contentsKey: string[] = this.getAllContentsFromStory(scenario);
        const contents: Content[] = [];
        for (let index = 0; index < this.library.contents.length; index++) {
            const content = this.library.contents[index];
            if (contentsKey.includes(content.key)) contents.push(content);
        }
        const sharedStory: ShareScenario = new ShareScenario();
        sharedStory.set(
            this.generateKey(),
            this.auth.currentUser.email,
            story,
            contents,
        );
        if (!this.checkKeyNotEmpty(dest)) return;
        if (!this.checkKeyNotEmpty(sharedStory.key)) return;
        set(
            ref(
                this.database,
                this.getDatabasePrefix() +
                    "Share/" +
                    dest +
                    "/" +
                    sharedStory.key,
            ),
            jsonConvert.serializeObject<ShareScenario>(sharedStory),
        ).catch((error) =>
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            {},
        ); /*.then(() =>
		{

		}
		);*/

        return sharedStory;
    }

    sharedStories: ShareScenario[];
    sharedStoriesSubject = new Subject<ShareScenario[]>();

    getSharedStories() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        if (
            this.auth.currentUser === undefined ||
            this.auth.currentUser === null
        ) {
            return;
        }
        get(
            ref(
                this.database,
                this.getDatabasePrefix() + "Share/" + this.auth.currentUser.uid,
            ),
        ).then((sharedSnapshot) => {
            this.sharedStories = [];
            let i = 0;

            for (const key in sharedSnapshot.toJSON()) {
                this.sharedStories[i] = this.jsonConvert.deserializeObject(
                    sharedSnapshot.child(key).toJSON(),
                    ShareScenario,
                );
                i++;
            }

            this.emitSharedStories();
        });
    }

    emitSharedStories() {
        this.sharedStoriesSubject.next(this.sharedStories);
    }

    defaultTagColor: Array<string> = [
        "#d50000",
        "#ff1744",
        "#d500f9",
        "#3d5afe",
        "#00e5ff",
        "#00c853",
        "#ffea00",
        "#ff6d00",
        "#6d4c41",
        "#757575",
        "#000000",
    ];

    addStory(shared: ShareScenario) {
        this.uploadScenario(shared.story);
        if (this.library == undefined) {
            this.library = new Library();
            this.library.Key = this.generateKey();
            this.user.setLibrary(this.library.Key);
        }
        if (this.library.Contents == undefined) {
            this.library.Contents = [];
        }

        for (let index = 0; index < shared.contents.length; index++) {
            const contentIndex = this.library.Contents.findIndex(
                (c) => c.key == shared.contents[index].key,
            );
            if (contentIndex != -1)
                this.library.Contents[contentIndex] = shared.contents[index];
            else this.library.Contents.push(shared.contents[index]);
            for (let j = 0; j < shared.contents[index].tags.length; j++) {
                if (
                    !this.library.Tags.find(
                        (val) => val.Name == shared.contents[index].tags[j],
                    )
                ) {
                    this.library.Tags.push(
                        new Tag(
                            shared.contents[index].tags[j],
                            this.defaultTagColor[
                                random(0, this.defaultTagColor.length)
                            ],
                        ),
                    );
                }
            }
        }
        this.setLibrary(this.library, false);

        this._userService.getUser().addScenario(shared.story.key);
        this.setUser(this._userService.getUser());
    }
    getUID() {
        return this.auth.currentUser.uid;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ DIFFUSION
    // -----------------------------------------------------------------------------------------------------

    diffusions: Diffusion[] = [];
    diffusionsSubject = new Subject<Diffusion[]>();
    getDiffusionsInprogress = false;

    /**
     * Emit diffusions
     */
    emitDiffusions() {
        this.diffusionsSubject.next(this.diffusions);
        this.getProjectsV2();
    }

    /**
     * Get diffusions
     */
    getDiffusions() {
        //console.log('getDiffusions0');
        //console.log(this.getDiffusionsInprogress);
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        if (this.getDiffusionsInprogress) {
            return;
        }
        this.getDiffusionsInprogress = true;
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }
        this.diffusions = [];
        //console.log('--------------------getDiffusions');

        onValue(
            ref(
                this.database,
                "Users/" +
                    user.uid +
                    "/Versions/" +
                    this.user.getVersion() +
                    "/Diffusions/",
            ),
            async (userDiffusionsSnapshot) => {
                const userDiffusionsList = userDiffusionsSnapshot.toJSON();
                //console.log('--------------------getDiffusionsList---------------');
                //console.log(userDiffusionsList);

                this.getDiffusion(userDiffusionsList, 0);
            },
        );
    }

    async getUserDiffusions(user: User): Promise<Diffusion[]> {
        const diffusions = [];

        const userDiffusionsSnapshot = await get(
            ref(
                this.database,
                "Users/" +
                    user.Key +
                    "/Versions/" +
                    user.getVersion() +
                    "/Diffusions/",
            ),
        ).then();

        const userDiffusionsList = userDiffusionsSnapshot.toJSON();

        let index = 0;

        while (
            userDiffusionsList !== undefined &&
            userDiffusionsList !== null &&
            userDiffusionsList[index] !== undefined
        ) {
            const diffusionKey = userDiffusionsList[index];
            const diffusionSnapshot = await get(
                ref(
                    this.database,
                    user.getVersion() + "/Diffusions/" + diffusionKey,
                ),
            ).then();
            const diffusion = this.jsonConvert.deserializeObject(
                diffusionSnapshot.toJSON(),
                Diffusion,
            );
            //console.log(diffusion);
            if (diffusion == null) return;
            if (diffusion.web == undefined && diffusion.box == undefined) {
                index++;
                continue;
            }

            if (diffusion.scenario == undefined) {
                await get(
                    ref(
                        this.database,
                        user.getVersion() +
                            "/Diffusions/" +
                            diffusionKey +
                            "/Scenario",
                    ),
                ).then(async (scenarioSnapshot) => {
                    diffusion.scenario = this.jsonConvert.deserializeObject(
                        scenarioSnapshot.toJSON(),
                        Scenario,
                    );
                });
            }
            //console.log(diffusion.scenario);
            if (diffusion.scenario == undefined || diffusion.scenario == null)
                return;

            diffusions.push(diffusion);

/*            if (diffusion.Tags != undefined && diffusion.Tags.length != 0) {
                diffusion.tags = diffusion.Tags;
                diffusion.Tags = undefined;
            }*/
            index++;
        }

        return diffusions;
    }

    async getDiffusion(userDiffusionsList, index) {
        //console.log('--------------------getDiffusion---'+index);
        //console.log(userDiffusionsList);

        if (
            userDiffusionsList == null ||
            userDiffusionsList[index] == undefined
        ) {
            this.getDiffusionsInprogress = false;
            this.emitDiffusions();
            return;
        }
        const diffusionKey = userDiffusionsList[index];
        await get(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + diffusionKey,
            ),
        )
            .then(async (diffusionSnapshot) => {
                const diffusion = this.jsonConvert.deserializeObject(
                    diffusionSnapshot.toJSON(),
                    Diffusion,
                );

                //console.log('-------get diff dorsnapshot' +diffusionKey);
                //console.log(diffusion);
                if (diffusion == null) {
                    index++;
                    this.getDiffusion(userDiffusionsList, index);
                    return;
                }
                if (diffusion.web == undefined && diffusion.box == undefined) {
                    diffusion.web = true;
                    this.updateWebDiffusion(diffusion.key, true);
                }

                if (diffusion.scenario == undefined) {
                    await get(
                        ref(
                            this.database,
                            this.user.getVersion() +
                                "/Diffusions/" +
                                diffusionKey +
                                "/Scenario",
                        ),
                    ).then(async (scenarioSnapshot) => {
                        diffusion.scenario = this.jsonConvert.deserializeObject(
                            scenarioSnapshot.toJSON(),
                            Scenario,
                        );
                    });
                }

                if (
                    diffusion.scenario == undefined ||
                    diffusion.scenario == null
                ) {
                    index++;
                    this.getDiffusion(userDiffusionsList, index);
                    return;
                }

                if (diffusion.scenario.PreviewUrl == "") {
                    const content = this.library.getContent(
                        diffusion.scenario.Preview,
                    );
                    if (content != undefined) {
                        diffusion.scenario.PreviewUrl = content.getDataUrl();
                    }
                }
                const i = this.diffusions.findIndex((item) => {
                    return item.key === diffusion.key;
                });
                //console.log("Find : "+i);

                if (i != -1) {
                    //console.log(this.diffusions[i]+" === "+diffusion.key);
                    this.diffusions[i] = diffusion;
                } else {
                    //console.log("New diff ! "+diffusion.key);
                    this.diffusions.push(diffusion);
                }

                /*if (diffusion.Tags != undefined && diffusion.Tags.length != 0) {
                    diffusion.tags = diffusion.Tags;

                    this.setScenario(diffusion.scenario);
                    diffusion.Tags = undefined;
                    this.updateDiffusion(diffusion);
                }*/
                index++;
                this.getDiffusion(userDiffusionsList, index);
            })
            .catch((error) => {
                index++;
                this.getDiffusion(userDiffusionsList, index);
            });
    }

    /**
     * Updates on diffusion changes
     *
     * @param diffusionKey
     */
    updateOnDiffusionChanges(diffusionKey: string) {
        onValue(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + diffusionKey,
            ),
            (diffusionSnapshot) => {
                const diffusion = this.jsonConvert.deserializeObject(
                    diffusionSnapshot.toJSON(),
                    Diffusion,
                );
                if (diffusion == undefined || diffusion == null) return;

                for (let index = 0; index < this.diffusions.length; index++) {
                    if (this.diffusions[index].key == diffusion.key) {
                        this.diffusions[index] = diffusion;
                        off(
                            ref(
                                this.database,
                                this.user.getVersion() +
                                    "/Diffusions/" +
                                    diffusionKey,
                            ),
                        );
                        this.emitDiffusions();
                        return;
                    }
                }
            },
        );
    }

    /**
     * Update diffusion
     *
     * @param diffusion
     */
    updateDiffusion(diffusion: Diffusion) {
        if (diffusion == undefined) {
            return;
        }
        if (diffusion.scenario === undefined) return;
        diffusion.scenario.author = this.user.Key; //on met à jour l'auteur si la story provient d'un envoi
        const json = this.jsonConvert.serializeObject<Diffusion>(diffusion);
        if (!this.checkKeyNotEmpty(diffusion.key)) return;
        if (!this.checkKeyNotEmpty(this.user.getVersion() + "")) return;
        set(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + diffusion.key,
            ),
            json,
        );
        for (let index = 0; index < this.diffusions.length; index++) {
            if (this.diffusions[index].key === diffusion.key) {
                this.diffusions[index] = this.jsonConvert.deserializeObject(
                    this.jsonConvert.serializeObject(diffusion),
                    Diffusion,
                );
                return;
            }
        }
    }

    /**
     * Remove diffusion
     *
     * @param diffusion
     */
    async removeDiffusion(diffusion: Diffusion) {
        if (diffusion == undefined) {
            return;
        }
        if (!this.checkKeyNotEmpty(diffusion.key)) return;
        remove(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + diffusion.key,
            ),
        );
        const i = this.diffusions.findIndex((item) => {
            item.key == diffusion.key;
        });
        if (i != -1) this.diffusions.slice(i, 1);
        if (this._userService.getUser() != undefined) {
            this._userService.getUser().removeDiffusion(diffusion.key);
            this.setUser(this._userService.getUser());
        }
        const usersKey = this.user.Versions[this.user.Version].MyUsers;
        for (let index = 0; index < usersKey.length; index++) {
            const myUserSnapshot = await get(
                ref(this.database, "Users/" + usersKey[index]),
            );
            const myUser = new JsonConvert().deserializeObject(
                myUserSnapshot.toJSON(),
                User,
            );
            if (myUser == undefined) continue;
            const subcriptionsKey =
                myUser.Versions[myUser.Version].Subscriptions.slice();
            for (let j = 0; j < subcriptionsKey.length; j++) {
                const subcriptionSnapshot = await get(
                    ref(
                        this.database,
                        myUser.Version +
                            "/Subscriptions/" +
                            myUser.Key +
                            "/" +
                            subcriptionsKey[j],
                    ),
                );
                const subcription = new JsonConvert().deserializeObject(
                    subcriptionSnapshot.toJSON(),
                    UserSubscription,
                );
                if (subcription == undefined) continue;
                if (subcription.diffusionKey == diffusion.key) {
                    this.deleteSubscriptionKey(
                        subcription.key,
                        "" + myUser.Version,
                        myUser.Key,
                    );
                    const i = myUser.Versions[
                        myUser.Version
                    ].Subscriptions.findIndex((key) => {
                        return key == subcriptionsKey[j];
                    });
                    myUser.Versions[myUser.Version].Subscriptions.splice(i);
                    this.updateUser(myUser);
                }
            }
        }
        this.getDiffusions();
    }

    removeDiffusionFromExperiences(key: string) {
        //console.log("removeDiffusionFromExperiences");
        if (this.projects == undefined) return;
        for (let index = 0; index < this.projects.length; index++) {
            const find = this.projects[index].diffusions.indexOf(key);
            //console.log("find : " +find);
            if (find != -1) this.projects[index].diffusions.splice(find, 1);
        }
        this.setProjectsV2(this.projects);
    }

    updateBoxDiffusion(key, bool) {
        if (!this.checkKeyNotEmpty(key)) return;
        set(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + key + "/Box",
            ),
            bool,
        );
    }
    updateWebDiffusion(key, bool) {
        if (!this.checkKeyNotEmpty(key)) return;
        set(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + key + "/Web",
            ),
            bool,
        );
    }

    async isDiffusionBox(key) {
        let bool = undefined;
        await get(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + key + "/Box",
            ),
        )
            .then()
            .then((snapshot) => {
                bool = !!snapshot.toJSON();
            });
        return bool;
    }
    async isDiffusionWeb(key) {
        let bool = undefined;
        await get(
            ref(
                this.database,
                this.user.getVersion() + "/Diffusions/" + key + "/Web",
            ),
        )
            .then()
            .then((snapshot) => {
                bool = !!snapshot.toJSON();
            });
        return bool;
    }

    removeTagFromScenarios(name: string) {
        //console.log('removeTagFromScenarios');
        for (let index = 0; index < this.scenarios.length; index++) {
            if (this.scenarios[index].removeTag(name)) {
                this.setScenario(this.scenarios[index]);
            }
        }
        //this.emitDiffusions();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Forms
    // -----------------------------------------------------------------------------------------------------
    forms: MyForm[][] = [];
    formsSubject = new Subject<MyForm[][]>();

    /**
     * Emit forms
     */
    emitForms() {
        this.formsSubject.next(this.forms);
    }

    /**
     * Get diffusions
     */
    async getForms() {
        //console.log("getForms !");
        if (this.user != undefined && this.user.Key != "") {
            this.forms = [];
            //console.log(this.user.Versions[this.user.Version].Scenarios);
            const keys = this.user.Versions[this.user.Version].Scenarios;
            //ref(this.database,'Users/' + user.uid + '/Versions/' + this.user.getVersion() + '/Scenarios/')).then( (userScenariosSnapshot) => {
            for (let index = 0; index < keys.length; index++) {
                //for (let key in userScenariosSnapshot.toJSON()) {
                const scenarioKey = keys[index]; //userScenariosSnapshot.toJSON()[key];

                await get(
                    ref(
                        this.database,
                        this.getDatabasePrefix() + "Forms/" + scenarioKey,
                    ),
                ).then((formSnapshot) => {
                    const form = formSnapshot.toJSON(); //jsonConvert.deserializeObject(formSnapshot.toJSON(), MyForm);
                    if (form == null) {
                        //console.log("Scenario == null")
                        return;
                    }
                    //console.log(form);
                    this.forms[scenarioKey] = form;
                });
            }
        }
        //console.log(this.forms);

        this.emitForms();
    }

    async deleteForm(storyKey: string, formKey: string) {
        await remove(
            ref(
                this.database,
                this.getDatabasePrefix() + "Forms/" + storyKey + "/" + formKey,
            ),
        );
    }

    async deleteFormData(storyKey: string, formKey: string, dataKey: string) {
        await remove(
            ref(
                this.database,
                this.getDatabasePrefix() +
                    "Forms/" +
                    storyKey +
                    "/" +
                    formKey +
                    "/" +
                    dataKey,
            ),
        );
    }
    // -----------------------------------------------------------------------------------------------------
    // @ USER
    // -----------------------------------------------------------------------------------------------------
    user: User = new User();
    userSubject = new Subject<User>();
    status: number;

    logout() {
        this.user = undefined;
        this._userService.setUser(undefined);
        this.status = -1;

        this.uploadState = undefined;

        this.adminUserList = undefined;
        this.adminUsersList = undefined;

        this.project = undefined;
        this._projectService.setProject(undefined);
        this.projects = undefined;
        this._projectV2Service.setProjects(undefined);
        this.deviceUserList = undefined;
        this.devices = undefined;
        this._devicesService.setDevices([]);
        this.userList = undefined;
        this.library = undefined;
        this._libraryService.setLibrary(undefined);
        this.interface = undefined;
        this._interfaceService.setInterface(undefined);
        this.scenarios = undefined;
        this._scenariosService.setScenarios(undefined);
        this.diffusions = undefined;
        this.diffusion = undefined;

        window.location.href = environment.firebase.hostUrl;
    }

    async checkPresence(): Promise<boolean> {
        if (this.user.Connection === undefined) {
            this.user.Connection = new Connection();
            this.user.Connection.Connected = true;
        }
        this.user.Connection.Connected = (
            await get(
                ref(
                    this.database,
                    "Users/" +
                        this.auth.currentUser.uid +
                        "/Connection/Connected",
                ),
            ).then()
        )?.val();

        return this.user.Connection === undefined ||
            this.user.Connection.Connected === undefined ||
            this.user.LastConnexion === undefined ||
            (this.user.LastConnexion !== undefined &&
                this.user.LastConnexion.getTime() !== 0 &&
                this.user.LastConnexion?.getTime() +
                    environment.settings.lastlogin * 60 * 1000 <
                    Date.now())
            ? false
            : this.user.Connection.Connected;
    }

    async connexion() {
        // stores the timestamp of my last disconnect (the last time I was seen online)
        const lastOnlineRef = ref(
            this.database,
            "users/" + this.auth.currentUser.uid + "/Connection/LastOnline",
        );

        //const con = push(myConnectionsRef);

        // When I disconnect, remove this device
        //onDisconnect(con).set(false);

        // Add this device to my connections list
        // this value could contain info about the device or a timestamp too
        //set(con, true);

        // When I disconnect, update the last time I was seen online
        onDisconnect(lastOnlineRef).set(serverTimestamp());

        if (this.user.Connection === undefined) {
            this.user.Connection = new Connection();
        }

        this.user.Connection.LastOnline = serverTimestamp();
        this.user.Connection.Connected = true;
        //console.log('this.user.Connection.Connected : ' + this.user.Connection.Connected);

        await set(
            ref(
                this.database,
                "Users/" + this.auth.currentUser.uid + "/Connection/Connected",
            ),
            true,
        );

        //this.emitUser();
        await this.setUser(this.user);

        //console.log('this.user.Connection.Connected : ' + this.user.Connection.Connected);

        onValue(
            ref(
                this.database,
                "Users/" + this.auth.currentUser.uid + "/Connection/Disconnect",
            ),
            async (snapshot) => {
                //console.log("#######################################");
                //console.log("Deco by other !");
                //console.log("#######################################");
                const val = snapshot?.val();
                //console.log("Deco : " + val);
                if (!val || val === undefined || val === null) {
                    return;
                }
                setTimeout(async () => {
                    const name = (
                        await get(
                            ref(
                                this.database,
                                "Users/" +
                                    this.auth.currentUser.uid +
                                    "/Connection/DisconnectBy",
                            ),
                        )
                    )?.val();
                    //console.log(name);
                    await set(
                        ref(
                            this.database,
                            "Users/" +
                                this.auth.currentUser.uid +
                                "/Connection/DisconnectBy",
                        ),
                        "",
                    );

                    off(
                        ref(
                            this.database,
                            "Users/" +
                                this.auth.currentUser.uid +
                                "/Connection/Disconnect",
                        ),
                    );
                    this.disconnectUser();
                    this._authService.authenticated = false;
                    this.auth.signOut();

                    const comfirmDialog = this._fuseConfirmationService.open({
                        title:
                            name !== undefined && name !== null
                                ? name +
                                  this._translocoService.translate("CONFIRM.17")
                                : this._translocoService.translate(
                                      "CONFIRM.18",
                                  ),
                        message: this._translocoService.translate("CONFIRM.19"),
                        icon: {
                            show: true,
                            name: "logout",
                            color: "warn",
                        },
                        actions: {
                            confirm: {
                                show: true,
                                label: this._translocoService.translate(
                                    "deconnexion",
                                ),
                                color: "warn",
                            },
                            cancel: {
                                show: false,
                                label: this._translocoService.translate(
                                    "GEN.NO",
                                ),
                            },
                        },
                    });

                    comfirmDialog.afterClosed().subscribe(() => {
                        /*//console.log("result");
                        //console.log(result);
                        //console.log(typeof(result));
                        console.log(result == "confirmed");*/

                        window.onbeforeunload = null;
                        this.logout();
                    });
                }, 500);
            },
        );
    }

    async disconnectUser(message: string = undefined) {
        const promises = [];

        if (this.auth.currentUser !== null && this.user !== undefined) {
            promises.push(
                set(
                    ref(
                        this.database,
                        "Users/" +
                            this.auth.currentUser.uid +
                            "/Connection/Disconnect",
                    ),
                    false,
                ),
            );
            promises.push(
                set(
                    ref(
                        this.database,
                        "Users/" +
                            this.auth.currentUser.uid +
                            "/Connection/Connected",
                    ),
                    false,
                ),
            );
            promises.push(
                set(
                    ref(
                        this.database,
                        "Users/" +
                            this.auth.currentUser.uid +
                            "/Connection/LastOnline",
                    ),
                    Date.now(),
                ),
            );
        }

        return Promise.all(promises);
    }

    async disconnectOther(name: string) {
        await set(
            ref(
                this.database,
                "Users/" + this.auth.currentUser.uid + "/Connection/Connected",
            ),
            false,
        );
        //console.log("#######################################");
        //console.log("disconnectOther");
        //console.log("#######################################");
        onValue(
            ref(
                this.database,
                "Users/" + this.auth.currentUser.uid + "/Connection/Connected",
            ),
            (snapshot) => {
                //console.log("#######################################");
                //console.log("the other user is disconnected");
                //console.log("#######################################");
                const val = snapshot.val();
                //console.log("other : " + val);
                if (val === true) {
                    return;
                }
                off(
                    ref(
                        this.database,
                        "Users/" +
                            this.auth.currentUser.uid +
                            "/Connection/Connected",
                    ),
                );

                this.connexion();
            },
        );

        set(
            ref(
                this.database,
                "Users/" + this.auth.currentUser.uid + "/Connection/Disconnect",
            ),
            true,
        );
        set(
            ref(
                this.database,
                "Users/" +
                    this.auth.currentUser.uid +
                    "/Connection/DisconnectBy",
            ),
            name,
        );
        this.user.Connection.DisconnectBy = name;
        //set(ref(this.database,'Users/'+this.auth.currentUser.uid+'/Connection/Connected'),false);
        //this.logout();
    }

    getUserStatus(): Promise<number> {
        const user = this.auth.currentUser;

        return new Promise<number>((resolve, reject) => {
            get(ref(this.database, "Users/" + user.uid + "/Status"))
                .then((snapshot) => {
                    this.user.Status = snapshot.val();
                    return resolve(this.user.Status);
                })
                .catch((error) => {
                    //console.log("loginError");
                    //console.log(error);
                    reject(error);
                });
        });
    }

    setUserStatus(status: number) {
        this.user.Status = status;
        this.user.LastConnexion = new Date(); //Date.now();
        this.setUser(this.user);
    }

    emitUser() {
        //console.log("Emit User !");
        const userSerialized = this.jsonConvert.serializeObject<User>(
            this.user,
        );
        //console.log(userSerialized);
        this._userService.setUser(this.user);
        this.userSubject.next(this.user);
        this.getMyUsersList();
    }

    checkUserExist() {
        if (this.auth.currentUser === null) {
            return;
        }
        return get(
            ref(this.database, "Users/" + this.auth.currentUser.uid),
        ).then((snapshot) => {
            if (!snapshot.exists()) {
                //Create defaut data !

                this.user = new User();
                this.user.setKey(this.auth.currentUser.uid);
                this.user.Email = this.auth.currentUser.email;
                this.user.Username = this.auth.currentUser.email;
                this.user.Version = parseInt(environment.projectVersion);
                this.user.Versions = [];
                for (let i = 0; i <= this.user.Version; i++) {
                    this.user.Versions.push({
                        Devices: [],
                        Library: "",
                        Projects: [],
                        ProjectsV2: [],
                        Scenarios: [],
                        Diffusions: [],
                        Interface: "",
                        Categories: [],
                        Subscriptions: [],
                        MyUsers: [],
                    });
                }
                this.library = new Library();
                this.library.setKey(this.generateKey());
                this.library.LastUpdate = Date.now().toString();
                this.user.Versions[this.user.Version].Library =
                    this.library.Key;
                this.setLibrary(this.library);
                this.setUser(this.user);

                return false;
            }
            return true;
        });
    }

    tutorialSubject = new Subject<boolean>();
    hidden = [];
    addDoNotShow(label: string) {
        this.user.DoNotShow.push(label);
        this.setUser(this.user);
        this.tutorialSubject.next(
            !(this.user.DoNotShow?.includes(label) ?? false) &&
                !this.hidden.includes(label),
        );
    }
    hide(label: string) {
        this.hidden.push(label);
        this.tutorialSubject.next(
            !(this.user.DoNotShow?.includes(label) ?? false) &&
                !this.hidden.includes(label),
        );
    }

    getUser() {
        if (
            this.inProgressUser ||
            this.user.Key !== "" ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }

        const currentUser = this.auth.currentUser;
        //console.log(currentUser);
        if (currentUser === undefined || currentUser === null) return;
        this.inProgressUser = true;
        if (!this._authService.authenticated) {
            this._authService.authenticated == true;
        }
        //this._analyticsService.login(currentUser.uid);
        get(ref(this.database, "Users/" + currentUser.uid)).then(
            async (userSnapshot) => {
                let version = -1;
                //console.log(userSnapshot);
                if (this.user != undefined) version = this.user.getVersion();

                this.user = this.jsonConvert.deserializeObject(
                    userSnapshot.toJSON(),
                    User,
                );

                if (this.user.Status < 2) {
                    if (this.user.Connection == undefined) {
                        this.user.Connection = new Connection();
                    }
                    this.user.Connection.Connected = false;
                }
                if (version == -1 || version != this.user.getVersion()) {
                    this.getRefresh();
                    version = this.user.getVersion();
                }
                if (this.user.UserPref == undefined)
                    this.user.UserPref = new UserPref();
                if (this.user.UserPref.Diffusion == undefined)
                    this.user.UserPref.Diffusion = new PagePref();
                if (this.user.UserPref.Lang == undefined)
                    this.user.UserPref.Lang =
                        this._translocoService.getActiveLang();

                const lg = localStorage.getItem("user.language");
                if (lg === null || lg == undefined) {
                    this._translocoService.setActiveLang(
                        this.user.UserPref.Lang,
                    );
                } else {
                    if (lg != this.user.UserPref.Lang) {
                        this._translocoService.setActiveLang(lg);
                        this.user.UserPref.Lang = lg;
                        //	this._dataService.setUserPref(this.user.UserPref);
                    }
                }

                //console.log(!(this.user.DoNotShow?.includes('tutorial') ?? false));
                this.tutorialSubject.next(
                    !(this.user.DoNotShow?.includes("tutorial") ?? false) &&
                        !this.hidden.includes("tutorial") &&
                        this.user.Status >= 2,
                );

                if (this.user.Stripe === undefined) {
                    this.user.Stripe = new Stripe();
                }
                if (
                    this.user.Stripe?.CustomerId === undefined ||
                    this.user.Stripe?.CustomerId === ""
                ) {
                    const snapshot = await get(
                        ref(
                            this.database,
                            "Users/" + this.user.Key + "/Stripe/CustomerId",
                        ),
                    );
                    if (snapshot.exists() && snapshot.val() !== "") {
                        this.user.Stripe.CustomerId = snapshot.val();
                    }

                    if (
                        this.user.Stripe?.CustomerId === undefined ||
                        this.user.Stripe?.CustomerId === ""
                    ) {
                        this.createCustomerSubject.subscribe(async (id) => {
                            await this.endGetUser();
                        });
                        const id = this.createCustomer(this.user);
                    } else {
                        await this.endGetUser();
                    }
                } else {
                    await this.endGetUser();
                }
            },
        );
    }

    async endGetUser() {
        this.setUser(this.user);

        if (
            this.subscriptions?.subscriptions === undefined ||
            this.subscriptions?.subscriptions?.length === 0
        ) {
            await this.getSubscriptions();
        }
        let sub = undefined;

        if (
            this.subscriptions?.subscriptions !== undefined &&
            this.subscriptions?.subscriptions?.length > 0 &&
            this.user.Stripe?.CustomerId !== undefined &&
            this.user.Stripe?.CustomerId !== "" &&
            (this.user.Stripe?.SubscriptionId === undefined ||
                this.user.Stripe?.SubscriptionId === "") &&
            this.user.Stripe?.Type !== SubscriptionType.Pro
        ) {
            //Check if user has a subscription on Stripe
            const subs = await firstValueFrom(
                this._dataService.GetSubscriptions({
                    customerID: this.user.Stripe.CustomerId,
                }),
            );

            console.log("All retrieved subscriptions");
            console.log(subs);

            const subscriptions: any[] = subs["subscriptions"]?.data;
            let list_subs = subscriptions.filter(
                (s) =>
                    s["status"] === "active" &&
                    s["customer"] === this.user.Stripe.CustomerId,
            );
            sub = this.getHighSubscription(list_subs);

            if (sub !== undefined) {
                if (
                    sub["id"] !== undefined &&
                    sub["id"] !== "" &&
                    this.user.Stripe.SubscriptionId !== sub["id"]
                ) {
                    //USER CHANGE SUBSCRIPTION !
                }
                this.user.Stripe.SubscriptionId = sub["id"];
                const subDB = this.subscriptions.subscriptions.find(
                    (s) => s.productID === sub.items.data[0].price.product,
                );
                if (subDB !== undefined) {
                    this.user.Stripe.Type = subDB.type;
                }

                if (
                    this.user.Stripe.SubscriptionId === undefined ||
                    this.user.Stripe.SubscriptionId === ""
                ) {
                    await this.subscribeFree();
                }
            } else {
                await this.subscribeFree();
            }
        }
        console.log("subscription check");
        console.log(sub);
        await this.checkSubscription(sub);

        if (this.user.Stripe.Type !== SubscriptionType.Pro) {
            //console.log(this.subscriptions);
            const feature = this.subscriptions.subscriptions[
                this.user.Stripe.Type
            ].features.find((f) => f.key === "storage");
            //console.log('---------------- 7 ----------------'+feature);
            if (this.user.DiskLimit !== feature.value) {
                this.user.DiskLimit = feature.value;
                this.setUser(this.user);
                this.emitUser();
            }
        }
        this.SetNavigation();
        this._statisticsService.setUser(this.user);

        if (this.user.getDiskUsage() == 0) {
            await this.syncUsage();
            //this._notificationService.sendAlert("Espace Disque Faible !","Ok");
        }
        const pourcent =
            (this.user.getDiskUsage() * 100) / this.user.getDiskLimit();
        //console.log(pourcent + "% d'utilisation");
        if (pourcent >= 99)
            this._notificationsService.create({
                id: this.generateKey(),
                title: "Espace Disque Remplit !",
                icon: "warning",
                description:
                    "Pour continuer à ajouter des médias contactez-nous !",
                time: new Date().toString(),
                read: false,
            });
        //this._notificationService.notify("Espace Disque Remplit ! ", "Fermer", NotificationType.Alert,0);
        else if (pourcent >= 95)
            this._notificationsService.create({
                id: this.generateKey(),
                title: "Espace Disque Faible !",
                icon: "warning",
                description:
                    "Attention votre espace de stockage est bientôt saturé !",
                time: new Date().toString(),
                read: false,
            });
        //this._notificationService.notify("Espace Disque Faible ! ", "Fermer", NotificationType.Warning,0);
        this.emitUser();
        this.initDatabase();
        this.inProgressUser = false;
        /*}).then(()=>{
        this.inProgressUser = false;
    },()=>{
        this.inProgressUser = false;
    }); */
    }

    getHighSubscription(list_subs: any[]) {
        const subscriptionTypes = [
            SubscriptionType.Business,
            SubscriptionType.Premium,
            SubscriptionType.Simple,
            SubscriptionType.Free,
        ];

        for (const type of subscriptionTypes) {
            const plan = this.subscriptions.subscriptions.find(
                (s) => s.type === type,
            );
            if (plan) {
                const sub = list_subs.find(
                    (s) => s["plan"].product === plan.productID,
                );
                if (sub !== undefined) {
                    return sub;
                }
            }
        }

        return undefined;
    }

    async subscribeFree() {
        console.log("---------------- subscribeFree ----------------");
        const subscription = this.subscriptions.subscriptions.find(
            (s) => s.type === SubscriptionType.Free,
        );
        try {
            const res = await firstValueFrom(
                this._dataService.StartSubscription({
                    customerID: this.user.Stripe.CustomerId,
                    priceID: subscription.monthlyID,
                }),
            );
            console.log(res);

            this.user.Stripe.SubscriptionId = res["subscriptionId"];
        } catch (e) {
            console.log(e);
        }
    }

    async checkSubscription(sub) {
        console.log("---------------- checkSubscription ----------------");
        console.log("SUBSCRIPTION ID : " + this.user.Stripe?.SubscriptionId);
        console.log(sub);
        if (this.user.Stripe?.Type === SubscriptionType.Pro) {
            return;
        }

        if (
            this.user.Stripe?.SubscriptionId === undefined ||
            this.user.Stripe?.SubscriptionId === ""
        ) {
            console.log("---------------- checkSubscription ----------------2");
            this.subscribeFree();
            this.user.EndDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
            console.log(this.user.EndDate);
            return;
        }
        if (sub === undefined) {
            const subs = await firstValueFrom(
                this._dataService.GetSubscriptions({
                    customerID: this.user.Stripe.CustomerId,
                }),
            );
            const subscriptions: any[] = subs["subscriptions"]?.data;
            console.log("---------------- checkSubscription ----------------3");
            console.log(this.user.Stripe.CustomerId);
            console.log(subscriptions);
            sub = this.getHighSubscription(
                subscriptions.filter(
                    (s) =>
                        s["status"] === "active" &&
                        s["customer"] === this.user.Stripe.CustomerId,
                ),
            );
            if (sub === undefined) {
                //			console.log('---------------- checkSubscription ----------------3');
                this.user.EndDate = new Date(
                    Date.now() + 30 * 24 * 60 * 60 * 1000,
                );
                //		console.log(this.user.EndDate);
                this.subscribeFree();
                return;
            } else {
                //			console.log('---------------- checkSubscription ----------------4');
                //			console.log('---------------- checkSubscription ----------------'+sub);
                const subscription = this.subscriptions.subscriptions.find(
                    (s) =>
                        s.annualyID === sub["plan"].id ||
                        s.monthlyID === sub["plan"].id,
                );
                //console.log(this.user.Stripe.SubscriptionId);
                //console.log(subscription);
                //console.log(sub);
                //console.log(this.user.Stripe.Type);
                this.user.Stripe.Type = subscription.type;
                this.user.Stripe.SubscriptionId = sub["id"];
                console.log(this.user.Stripe.Type);
                console.log(this.user.Stripe.SubscriptionId);
            }
        }

        //console.log('---------------- checkSubscription ----------------6');
        //const dateStart = this.timestampToDate(sub['current_period_start']);
        const dateEnd = this.timestampToDate(sub["current_period_end"]);

        this.user.EndDate = dateEnd;
        console.log(this.user.Stripe.Type);
        console.log(this.user);
        await this.setUser(this.user);
    }

    SetNavigation() {
        //console.log('---------------- SetNavigation ------------------');
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                "mainNavigation",
            );
        //console.log(navComponent);
        if (navComponent == undefined) {
            setTimeout(() => this.SetNavigation(), 200);
            return;
        }
        const nav = navComponent.navigation;

        const pages: any = this._fuseNavigationService.getItem("pages", nav);
        const gestion: any = this._fuseNavigationService.getItem(
            "gestion",
            nav,
        );
        const stats: any = this._fuseNavigationService.getItem("report", nav);
        const diffs: any = this._fuseNavigationService.getItem(
            "diffusion",
            nav,
        );
        let index = -1;
        //console.log(pages);
        if (pages == null && gestion == null) return;

        const hidden = this.user.getType() != UserType.Admin;

        //la partie admin
        if (!hidden) {
            //Admin
            if (this.checkAdminUsersList) this.getAdminUsersList();
        } else {
            index = nav.findIndex((i) => i.id == "Admin");
            if (index != -1) nav.splice(index, 1);
        }

        //la partie interface

        if (!this.user.SpecificData.includes(UserSpecific.Interface)) {
            if (diffs !== null) {
                index = diffs.children.findIndex((i) => i.id == "interface");
                if (index != -1) diffs.children.splice(index, 1);
            }
        }

        if (gestion != null) {
            if (this.subscriptions !== undefined) {
                ///gestion des utlisateurs On les masque si non inclut
                /*			const feature = this.subscriptions.subscriptions[this.user.Stripe.Type].features.find(f => f.key === 'users');
				if(!feature?.included)
				{
					index = gestion.children.findIndex((i)=> i.id == "myusers");
					if(index!=-1)  gestion.children.splice(index,1);
				}*/
                ///gestion de l'abonnement
                if (this.user.Stripe.Type === SubscriptionType.Pro) {
                    index = gestion.children.findIndex(
                        (i) => i.id == "pricing",
                    );
                    if (index != -1) gestion.children.splice(index, 1);
                }
            } else {
                index = gestion.children.findIndex((i) => i.id == "myusers");
                if (index != -1) gestion.children.splice(index, 1);
            }
        }

        //console.log('---------------- STATS ------------------');
        /*		if(stats != null)
		{
			//les stats
			if(this.subscriptions!==undefined)
			{
				const feature1 = this.subscriptions.subscriptions[this.user.Stripe.Type].features.find(f => f.key === 'stats');
				//console.log(feature1);
				if(!feature1?.included){
					const parent = this._fuseNavigationService.getItem('stats',nav);
					parent.lock=true;
					parent.link="";
					parent.function = ()=>{ this.stopNav(); };
				}

				const feature2 = this.subscriptions.subscriptions[this.user.Stripe.Type].features.find(f => f.key === 'event-form');
				//console.log(feature2);
				if(!feature2?.included){
					const parent = this._fuseNavigationService.getItem('form',nav);
					parent.lock=true;
					parent.link="";
					parent.function = ()=>{ this.stopNav(); };
				}
			}
		}*/

        if (this.user.Type === UserType.ShareLib) {
            index = gestion.children.findIndex((i) => i.id == "pricing");
            if (index != -1) gestion.children.splice(index, 1);

            index = gestion.children.findIndex((i) => i.id == "myusers");
            if (index != -1) gestion.children.splice(index, 1);

            if (diffs !== null) {
                index = diffs.children.findIndex((i) => i.id == "interface");
                if (index != -1) diffs.children.splice(index, 1);
            }
            index = nav.findIndex((i) => i.id == "gestion");
            if (index != -1) nav.splice(index, 1);

            index = nav.findIndex((i) => i.id == "report");
            if (index != -1) nav.splice(index, 1);
        }

        navComponent.refresh();
    }

    stopNav() {
        this.confirmDialogRef = this._fuseConfirmationService.open({
            title: this._translocoService.translate("CONFIRM.UPGRADE.TITLE"),
            message: this._translocoService.translate(
                "CONFIRM.UPGRADE.MESSAGE",
            ),
            icon: {
                show: true,
                name: "workspace_premium",
                color: "primary",
            },
            actions: {
                confirm: {
                    show: true,
                    label: this._translocoService.translate(
                        "CONFIRM.UPGRADE.BUTTON.UPGRADE",
                    ),
                    color: "primary",
                },
                cancel: {
                    show: true,
                    label: this._translocoService.translate(
                        "CONFIRM.UPGRADE.BUTTON.CONTINUE",
                    ),
                },
            },
        });

        this.confirmDialogRef.afterClosed().subscribe(async (result) => {
            //console.log('confirmDialogRef');
            if (result == "confirmed") {
                //console.log('confirmDialogRef');
                //console.log(this._router);
                this._router.navigate(["pages/pricing"]);

                return;
            }

            this.confirmDialogRef = null;
        });
    }

    sizeComputing = false;
    sizeSubject = new Subject<boolean>();

    async syncUsage() {
        //console.log('syncUsage');
        this.sizeComputing = true;
        this.sizeSubject.next(this.sizeComputing);
        let size = 0;
        //console.log('before lib');
        if (this.user.getLibrary() != "")
            size += await this._storageService.calculateSize(
                "Library/" + this.user.getLibrary(),
            );
        //console.log('after lib');
        if (this.user.getInterface() != "")
            size += await this._storageService.calculateSize(
                "Interface/" + this.user.getInterface(),
            );
        //console.log('after interface');
        if (this.library !== undefined && this.library !== null)
            for (const content of this.library.Contents) {
                if (
                    content.category == ContentCategory.Video &&
                    content.VimeoData?.uri !== undefined &&
                    content.VimeoData?.uri !== ""
                ) {
                    this._vimeoService
                        .getVideo(content.VimeoData.uri)
                        .subscribe((request) => {
                            let s = 0;
                            let indexvim = 0;
                            if (request["body"] !== undefined) {
                                const download = request["body"]?.download;
                                while (
                                    download !== undefined &&
                                    download[indexvim] !== undefined
                                ) {
                                    const video = download[indexvim];
                                    s = Math.max(s, video.size);
                                    indexvim++;
                                }
                                size += s / Math.pow(10, 9);
                            }
                        });
                } else {
                    if (this.user.Stripe.Type !== SubscriptionType.Pro) {
                        //en pro c'est deja pris en compte par le premier calcul ds library
                        if (
                            content.category == ContentCategory.Video &&
                            (content.VimeoData?.status === "in_progress" ||
                                content.VimeoData?.status === "uploading" ||
                                content.VimeoData?.status === "error")
                        ) {
                            size += content.DataSize / Math.pow(10, 9);
                        }
                    }
                }
            }
        //console.log('after Contents');

        this.user.setDiskUsage(size);
        //console.log(size);
        //console.log(this.user.getDiskUsage());
        this.setUser(this.user);
        this.emitUser();
        this.sizeComputing = false;
        this.sizeSubject.next(this.sizeComputing);
    }

    async setUser(user: User, connected = undefined) {
        if (user == undefined || user.Email == "") return;

        //console.log(this.jsonConvert.serializeObject<User>(user));
        //console.log(user.Connection?.Connected);

        for (let i = 0; i < user.Versions.length; i++) {
            if (user.Versions[i] == undefined) {
                user.Versions[i] = new UserProject();
            }
        }
        user.LastConnexion = new Date();
        //user.Connection.Connected=true;
        user.Connection.Connected =
            connected !== undefined
                ? connected
                : (
                      await get(
                          ref(
                              this.database,
                              "Users/" + user.Key + "/Connection/Connected",
                          ),
                      )
                  )?.val();
        const userSerialized = this.jsonConvert.serializeObject<User>(user);
        //console.log(userSerialized);

        this.user = user;

        const currentUser = this.auth.currentUser;

        if (!this.checkKeyNotEmpty(currentUser.uid)) return;
        await set(
            ref(this.database, "Users/" + currentUser.uid),
            userSerialized,
        ).then((data: any) => this.emitUser());
    }

    newUsers(users: User[]) {
        const usersSerialized = this.jsonConvert.serializeArray<User>(users);

        const currentUser = this.auth.currentUser;
        if (!this.checkKeyNotEmpty(currentUser.uid)) return;
        set(
            ref(this.database, "Users/" + currentUser.uid + "/NewUsers/"),
            usersSerialized,
        );
    }

    checkUserDiskUsage(size = -1) {
        if (size == -1) size = this.user.getDiskUsage();
        if (this.user.getDiskLimit() - size <= 0) {
            //console.log("NOTIF !");
            //this._notificationService.notify("Espace restant trop faible !","Fermer",NotificationType.Alert,8000);
            this._notificationsService.create({
                id: this.generateKey(),
                title: "Espace restant trop faible !",
                icon: "warning",
                description:
                    "Pour continuer à ajouter des médias contactez-nous !",
                time: new Date().toString(),
                read: false,
            });
        } //else
        //console.log("Size ok !");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PROJECT
    // -----------------------------------------------------------------------------------------------------
    project: Project;
    projectSubject = new Subject<Project>();

    emitProject() {
        this._projectService.setProject(this.project);
        this.projectSubject.next(this.project);
    }

    getProject() {
        //console.log("Get Project");
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        const user = this.auth.currentUser;
        //console.log(user);

        if (user === undefined || user === null) {
            return;
        }

        get(
            ref(
                this.database,
                "Users/" +
                    user.uid +
                    "/Versions/" +
                    this.user.getVersion() +
                    "/Projects/0/",
            ),
        ).then((keySnapshot) => {
            //console.log("Get Project From Database");
            //console.log('Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/Projects/0/');
            const key = keySnapshot.val();
            //console.log(key);
            if (key == undefined || key == null || key == "") {
                //console.log("User doesn't have project !");
                this._projectService.createNewProject();
                this.project = this._projectService.getProject();
                this.user.addProject(this.project.getKey());
                this._userService.setUser(this.user);
                this.setUser(this.user);
                /*get(ref(this.database,'Users/' + user.uid )).then((userSnapshot) => {

					this.user = this.jsonConvert.deserializeObject(userSnapshot.toJSON(), User);
					this.user.addProject(this.project.getKey());
					this._userService.setUser(this.user);
					this.setUser(this.user);
					this.emitUser();
				});*/
            }

            get(
                ref(
                    this.database,
                    this.getDatabasePrefix() + "Projects/" + key,
                ),
            ).then((projectSnapshot) => {
                this.project = this.jsonConvert.deserializeObject(
                    projectSnapshot.toJSON(),
                    Project,
                );

                //console.log("project is null?");
                if (this.project == undefined || this.project == null) {
                    //console.log("User doesn't have project !");
                    this._projectService.createNewProject();
                    this.project = this._projectService.getProject();
                    /*get(ref(this.database,'Users/' + user.uid )).then((userSnapshot) => {

						this.user = this.jsonConvert.deserializeObject(userSnapshot.toJSON(), User);
						this.user.addProject(this.project.getKey());
						this._userService.setUser(this.user);
						this.setUser(this.user);
						this.emitUser();
					});*/
                    this.user.addProject(this.project.getKey());
                    this._userService.setUser(this.user);
                    this.setUser(this.user);
                    //console.log(this.project);
                    //this.setProject(this.project);
                    //console.log("firebase json");
                }
                //console.log('Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/Projects/0/');
                //console.log(projectSnapshot.toJSON());
                //console.log("this.project");
                //console.log(this.project);
                this.emitProject();
            });
        });
    }

    setProject(project: Project) {
        const projectSerialized =
            this.jsonConvert.serializeObject<Project>(project);

        //console.log("set project");
        //console.log(project);
        if (!this.checkKeyNotEmpty(project.getKey())) return;
        set(
            ref(
                this.database,
                this.getDatabasePrefix() + "Projects/" + project.getKey(),
            ),
            projectSerialized,
        );
    }
    // -----------------------------------------------------------------------------------------------------
    // @ PROJECT V2
    // -----------------------------------------------------------------------------------------------------
    projects: ProjectV2[];
    projectV2Subject = new Subject<ProjectV2[]>();

    emitProjectV2() {
        this._projectV2Service.setProjects(this.projects);
        this.projectV2Subject.next(this.projects);
    }
    initProjectV2() {
        this.initDatabase();
    }

    getProjectsV2() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        if (this.inProgressProjectsV2) {
            return;
        }
        this.inProgressProjectsV2 = true;
        //console.log("Get Projects V2");

        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }
        //console.log("Mes Contenus !");
        //console.log(this.user);

        if (this.user != undefined && this.user.Key != "") {
            //console.log(this.user.Versions[this.user.Version].ProjectsV2);
            const listKey = this.user.Versions[this.user.Version];
            //console.log(listKey);
            //ref(this.database,'Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/')).then( (userProjectSnapshot) => {
            //console.log(userProjectSnapshot.toJSON());
            //
            //let listKey = this.jsonConvert.deserializeObject(userProjectSnapshot.toJSON(), UserProject);
            //console.log(listKey);
            if (
                listKey.ProjectsV2 == undefined ||
                listKey.ProjectsV2 == null ||
                listKey.ProjectsV2.length == 0
            ) {
                const tmpKey = this._projectV2Service.createNewProject(this);
                this.projects = this._projectV2Service.getProjects();
                this.user.addProjectV2(tmpKey);
                this._userService.setUser(this.user);
                this.setUser(this.user);
                this.setProjectsV2(this.projects);
                listKey.ProjectsV2.push(tmpKey);
            }
            const allPromise = [];
            for (let projectKey of listKey.ProjectsV2) {
                if (projectKey == "") continue;
                //let projectKey = keySnapshot.val();
                //console.log("PROJECT KEY :");
                //console.log(projectKey);
                allPromise.push(
                    get(
                        ref(
                            this.database,
                            "Users/" +
                                user.uid +
                                "/Versions/" +
                                this.user.getVersion() +
                                "/ProjectsV2/" +
                                projectKey +
                                "/",
                        ),
                    ).then(async (keySnapshot) => {
                        //console.log("Get ProjectV2 From Database");
                        //console.log('Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/ProjectsV2/'+projectKey+'/');
                        const key = keySnapshot.val();
                        //console.log(key);
                        if (
                            projectKey == undefined ||
                            projectKey == null ||
                            projectKey == ""
                        ) {
                            //console.log("User doesn't have projectV2 !");
                            projectKey =
                                this._projectV2Service.createNewProject(this);
                            this.projects =
                                this._projectV2Service.getProjects();
                            this.user.addProjectV2(projectKey);
                            this._userService.setUser(this.user);
                            this.setUser(this.user);
                            this.setProjectsV2(this.projects);
                        }

                        await get(
                            ref(
                                this.database,
                                this.getDatabasePrefix() +
                                    "ProjectsV2/" +
                                    projectKey,
                            ),
                        ).then((projectSnapshot) => {
                            const project = this.jsonConvert.deserializeObject(
                                projectSnapshot.toJSON(),
                                ProjectV2,
                            );

                            this.setProjectV2(project);
                            this.emitProjectV2();
                        });
                    }),
                );
            }
            Promise.all(allPromise).then(
                () => {
                    this.inProgressProjectsV2 = false;
                },
                () => {
                    this.inProgressProjectsV2 = false;
                },
            );
        } else {
            this.inProgressProjectsV2 = false;
        }
    }

    setProjectV2(project: ProjectV2) {
        if (project == null) return;
        //project.setMosaic(this._projectV2Service.UpdateMosaic(project.getContents(),this.library));
        if (project.getContents().length > 0) {
            //      //console.log("Try get preview from contents");
            const content = this.library.getContent(project.getContents()[0]);
            if (content != undefined) project.setPreviewURL(content.DataUrl);

            //	//console.log("Try get preview from contents finisj");
        } else if (
            project.diffusions != undefined &&
            project.diffusions.length > 0
        ) {
            //       //console.log("Try get preview from diffusions");
            let index = -1; //this.diffusions.findIndex((diffusion)=>{ //console.log(diffusion.key); diffusion.key == project.diffusions[0]});
            for (let i = 0; i < this.diffusions.length; i++) {
                if (this.diffusions[i].key == project.diffusions[0]) {
                    index = i;
                    break;
                }
            }
            //console.log(project.diffusions[0]);
            //console.log(this.diffusions.length);
            //console.log(index);

            if (index != -1) {
                //console.log("Diffusions found !");
                if (this.diffusions[index].scenario == undefined) {
                    //console.log("scenario undefined !");
                    const indexScenario = this.scenarios.findIndex(
                        (scenario) => {
                            scenario.diffusionKey == this.diffusions[index].key;
                        },
                    );
                    if (indexScenario != -1)
                        this.diffusions[index].scenario =
                            this.scenarios[indexScenario];
                }
                if (this.diffusions[index].scenario != undefined) {
                    //let main = this.diffusions[index].scenario.scenes.findIndex((scene)=>{scene.isMain});
                    const content = this.library.getContent(
                        this.diffusions[index].scenario.Preview,
                    );
                    if (content != undefined)
                        project.setPreviewURL(content.DataUrl);
                    //project.setPreviewURL(this.diffusions[index].scenario.getPreviewUrl());
                }
                //console.log(this.diffusions[index].scenario);
            }
        }

        if (this.projects == undefined) {
            this.projects = [];
            this.projects.push(project);
            return;
        }
        for (let i = 0; i < this.projects.length; i++) {
            if (this.projects[i].getKey() == project.getKey()) {
                this.projects[i] = project;
                return;
            }
        }

        this.projects.push(project);
    }
    setProjectsV2(projectV2: Array<ProjectV2>) {
        if (projectV2 != undefined)
            projectV2.forEach((p) => {
                //console.log(p);

                const projectSerialized =
                    this.jsonConvert.serializeObject<ProjectV2>(p);

                //console.log("set project");
                if (!this.checkKeyNotEmpty(p.getKey())) return;
                set(
                    ref(
                        this.database,
                        this.getDatabasePrefix() + "ProjectsV2/" + p.getKey(),
                    ),
                    projectSerialized,
                );
            });
    }
    async deleteProjectV2(projectKey: string, version: string = undefined) {
        if (version == undefined) {
            version = this.getDatabasePrefix();
        }
        this._projectV2Service.deleteProject(projectKey);
        this.projects = this._projectV2Service.getProjects();
        if (!this.checkKeyNotEmpty(projectKey)) return;
        await remove(ref(this.database, version + "/ProjectsV2/" + projectKey))
            .then((value) => {
                //console.log("ProjectV2 " + projectKey + " Remove !");
                //console.log(value);
            })
            .catch((reason) => {
                //console.log("ProjectV2 " + projectKey + " Error !");
                //console.log(reason);
            });
        this.user.removeProjectV2(projectKey);
        this.deleteProjectFromDevice(projectKey);
        this.setUser(this.user);
        this.emitUser();
        this.emitProjectV2();
    }
    deleteContent(contentKey: string) {
        if (this.projects != undefined)
            this.projects.forEach((p) => {
                if (p.getContents().includes(contentKey)) {
                    p.deleteContent(contentKey);
                    p.setLastUpdate(Date.now().toString());
                    this.setProjectV2(p);
                }
            });
        // Delete on storage
        const library = this._libraryService.getLibrary();

        this._storageService.deleteFolderContents(
            "Library/" + library.getKey() + "/Contents/" + contentKey,
            contentKey,
        );
        // Update
        this._libraryService.getLibrary().setLastUpdate(Date.now().toString());
        this.syncUsage();
        // Change Realtime Database
        this.setLibrary(this._libraryService.getLibrary(), false);
        this.setProjectsV2(this.projects);
    }

    getContentsList(list: string[]) {
        const tmp: string[] = [];
        for (let index = 0; index < list.length; index++) {
            const contentKey = list[index];
            if (this.library.getContent(contentKey) != null) {
                tmp.push(contentKey);
            }
        }
        return tmp;
    }
    getScenariosList(list: string[]) {
        const tmp: string[] = [];
        for (let index = 0; index < this.scenarios.length; index++) {
            const sc = this.scenarios[index];
            for (let index = 0; index < list.length; index++) {
                const scenarioKey = list[index];
                if (scenarioKey == sc.getKey()) {
                    tmp.push(scenarioKey);
                }
            }
        }
        return tmp;
    }

    getDiffusionsList(list: string[]) {
        const tmp: string[] = [];
        for (let index = 0; index < this.diffusions.length; index++) {
            const df = this.diffusions[index];
            for (let index = 0; index < list.length; index++) {
                const diffusionKey = list[index];
                if (diffusionKey == df.key) {
                    tmp.push(diffusionKey);
                }
            }
        }
        return tmp;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIBRARY
    // -----------------------------------------------------------------------------------------------------
    library: Library;
    librarySubject = new Subject<Library>();
    contentSizeloaded = 0;

    emitLibrary() {
        console.log("---------Emit Library---------" + this.library);
        if (this.library === undefined || this.library === null) return;

        this._libraryService.setLibrary(this.library);
        this.librarySubject.next(this.library);
        this.SetScenariosPreview();
    }

    initLibrary() {
        this.initDatabase();
    }
    async getLibrary() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        if (this.inProgressLibrary) {
            return;
        }
        this.inProgressLibrary = true;
        this.contentSizeloaded = 0;
        console.log("-------------Get Library-------------------");
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }
        if (this.user.Type == UserType.ShareLib) {
            await this.getLibraryKey(this.user.CreatorLibrary);
        } else {
            await get(
                ref(
                    this.database,
                    "Users/" +
                        user.uid +
                        "/Versions/" +
                        this.user.getVersion() +
                        "/Library/",
                ),
            ).then(async (keySnapshot) => {
                //console.log("Get Library From Database");
                const key = keySnapshot.val();
                await this.getLibraryKey(key);
            });
        }
    }
    async getLibraryKey(key: string) {
        console.log(this.user.Type);
        console.log("lib key : " + key);
        if (key == undefined || key == null || key == "") {
            //console.log("User doesn't have Library !");
            this._libraryService.createNewLibrary(this);
            this.library = this._libraryService.getLibrary();
            this.user.addLibrary(this.library.getKey());
            this._userService.setUser(this.user);
            this.setUser(this.user);
        }
        console.log(this.getDatabasePrefix() + "Library/" + key);
        await get(
            ref(this.database, this.getDatabasePrefix() + "Library/" + key),
        )
            .then(async (librarySnapshot) => {
                console.log(librarySnapshot.toJSON());
                if (librarySnapshot.toJSON() != null)
                    this.library = this.jsonConvert.deserializeObject(
                        librarySnapshot.toJSON(),
                        Library,
                    );
                console.log(this.library);
                console.log("Nb Medias : " + this.library?.Contents.length);
                if (this.library == undefined || this.library == null) {
                    //console.log("User doesn't have project !");
                    this._libraryService.createNewLibrary(this);
                    this.library = this._libraryService.getLibrary();
                    this.user.addLibrary(this.library.getKey());
                    this._userService.setUser(this.user);
                    this.setUser(this.user);
                    this.emitLibrary();
                    console.log(this.library);
                } else {
                    this.getAllPreviewContentURL();
                    this.getAllAudioContentURL();
                    for (
                        let index = 0;
                        index < this.library.contents.length;
                        index++
                    ) {
                        const content = this.library.contents[index];
                        //vimeo in progress
                        if (
                            content.category === ContentCategory.Video &&
                            content.VimeoData !== undefined &&
                            content.VimeoData.status === "in_progress"
                        ) {
                            console.log("call APIVIDEO");
                            await get(ref(this.database, "ApiVideo"))
                                .then((tmp) => {
                                    //console.log('get form database');
                                    const snapshot = tmp.val();

                                    console.log("APIVIDEO snapshot");
                                    console.log(snapshot);
                                    let index2 = 0;
                                    let find = false;
                                    while (
                                        !find &&
                                        snapshot !== null &&
                                        snapshot[index2] !== undefined
                                    ) {
                                        const vid = snapshot[index2];
                                        if (vid.key === content.key) {
                                            find = true;
                                        }

                                        index2++;
                                    }

                                    if (!find) {
                                        if (
                                            content === undefined ||
                                            content.VimeoData === undefined
                                        ) {
                                            return;
                                        }
                                        content.VimeoData.status = "error";
                                        this.setContentVimeoData(content);
                                        const notif: CustomNotif = {
                                            id: this.generateKey(),
                                            title: "Erreur liens de streaming",
                                            icon: "error",
                                            description:
                                                'Echec de la création des liens de streaming pour La vidéo "' +
                                                content.title +
                                                '".\nVous pouvez recréer les liens.',
                                            time: new Date().toString(),
                                            read: false,
                                        };
                                        this._notificationsService.create(
                                            notif,
                                        );
                                    }
                                })
                                .catch((reason) => {
                                    console.error(reason);
                                });
                        } else {
                            if (content.category === ContentCategory.Video) {
                                if (content.VimeoData !== undefined) {
                                    if (content.VimeoData.status !== "error") {
                                        this._vimeoService
                                            .getVideo(content.VimeoData.uri)
                                            .subscribe((request) => {
                                                let s = 0;
                                                let indexvim = 0;
                                                if (
                                                    request["body"] !==
                                                    undefined
                                                ) {
                                                    const download =
                                                        request["body"]
                                                            ?.download;
                                                    while (
                                                        download !==
                                                            undefined &&
                                                        download[indexvim] !==
                                                            undefined
                                                    ) {
                                                        const video =
                                                            download[indexvim];
                                                        s = Math.max(
                                                            s,
                                                            video.size,
                                                        );
                                                        indexvim++;
                                                    }
                                                    content.DataSize = s; ///Math.pow(10,9);
                                                    //console.log(content.DataSize);
                                                }
                                            });
                                    }
                                }
                            }
                        }
                    }
                    this.emitLibrary();
                }
            })
            .then(
                () => {
                    this.inProgressLibrary = false;
                },
                () => {
                    this.inProgressLibrary = false;
                },
            );
    }

    async setTags(libkey: string, tags: Tag[]) {
        const tagsSerialized = this.jsonConvert.serializeArray<Tag>(tags);
        if (!this.checkKeyNotEmpty(libkey)) return;
        set(
            ref(
                this.database,
                this.getDatabasePrefix() + "Library/" + libkey + "/Tags/",
            ),
            tagsSerialized,
        );
    }
    async SetDiffusiontags(libkey: string, tags: Tag[]) {
        const tagsSerialized = this.jsonConvert.serializeArray<Tag>(tags);
        if (!this.checkKeyNotEmpty(libkey)) return;
        set(
            ref(
                this.database,
                this.getDatabasePrefix() +
                    "Library/" +
                    libkey +
                    "/DiffusionTags/",
            ),
            tagsSerialized,
        );
    }

    async setLibrary(library: Library, updtvimeostatus = true) {
        let index = library.Tags.findIndex(
            (t) =>
                t.Name ===
                this._translocoService.translate("BIBLI.WITOUT_FILTER"),
        );
        while (index !== -1) {
            library.Tags.splice(index, 1);
            index = library.Tags.findIndex(
                (t) =>
                    t.Name ===
                    this._translocoService.translate("BIBLI.WITOUT_FILTER"),
            );
        }
        this.library = library;
        this._libraryService.setLibrary(this.library);
        if (!this.checkKeyNotEmpty(library.getKey())) return;

        const librarySerialized =
            this.jsonConvert.serializeObject<Library>(library);
        set(
            ref(
                this.database,
                this.getDatabasePrefix() + "Library/" + library.getKey() + "/",
            ),
            librarySerialized,
        ).then((value) => {
            if (updtvimeostatus) {
                for (const c of this.library.Contents) {
                    //console.log(c);
                    //console.log(parseInt(c.LastUpdate));
                    //console.log(Date.now());
                    if (
                        c.category !== ContentCategory.Video ||
                        c.VimeoData === undefined ||
                        parseInt(c.LastUpdate) < Date.now() - 86400000
                    ) {
                        continue;
                    }
                    if (
                        c.VimeoData !== undefined &&
                        (c.VimeoData.status === "complete" ||
                            c.VimeoData.status === "error")
                    )
                        //s'il était en erreur ou complter pas besoin de referifier
                        continue;
                    this.getVimeoStatus(c, undefined);
                }
            }

            if (
                this.user.Versions[this.user.Version].Library !=
                library.getKey()
            ) {
                this.user.Versions[this.user.Version].Library =
                    library.getKey();
                this.setUser(this.user);
                this._userService.setUser(this.user);
            }
            this.emitLibrary();
        });
    }

    /*    getDuration(content: Content){
        window.URL.revokeObjectURL(content.DataUrl);

        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () =>{

            window.URL.revokeObjectURL(video.src);

            if (video.duration < 1) {

                //console.log("Invalid Video! video is less than 1 second");
                return;
            }

            content.duration = video.duration;
            this.emitLibrary();
        }

        video.src = content.DataUrl;
    }*/
    getAllPreviewContentURL() {
        console.log(
            "-------------------getAllPreviewContentURL-------------------",
        );
        /*  this.library.Contents.forEach(content => {
            this.getPreviewURL(this.library.Key,content);
        });*/
        for (let i = this.library.Contents.length - 1; i >= 0; i--) {
            const content = this.library.Contents[i];
            this.getPreviewURL(this.library.Key, content);
        }
    }

    getAllAudioContentURL() {
        this.library.Contents.forEach((content) => {
            if (
                content.category == ContentCategory.Audio ||
                content.category == ContentCategory.Gps
            ) {
                this.getContentUrls(this.library, content);
            }
        });
    }
    getAllContentURL(library: Library = undefined) {
        return new Promise((resolve) => {
            //Medias
            if (library == undefined) library = this.library;
            if (library == undefined) return;
            library.Contents.forEach((content) => {
                this.getContentUrls(library, content);
            });
        });
    }

    nbComputed = 0;
    nbToCompute = 0;

    tryEmitLibrary() {
        this.nbComputed++;
        //console.log(this.nbComputed +" / " + this.nbToCompute);
        if (this.nbComputed >= this.nbToCompute) {
            // || this.nbComputed%2000==0)
            this.emitLibrary();
        }
    }

    async getContentUrls(library: Library, content: Content) {
        console.log("GetContentsUrls !");
        if (content == null) return;

        let filename = "";
        await this.getDataURL(library, content);
        //console.log(content.Resize);
        if (content.category === ContentCategory.Image && content.Resize) {
            await this.getDataHighURL(library, content);
            await this.getDataMedURL(library, content);
            await this.getDataLowURL(library, content);
        } else if (content.DataLow != "" && content.DataLowUrl == "") {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.Data;
                filename =
                    "datalow." +
                    filename.substring(filename.lastIndexOf(".") + 1);
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    library.Key +
                    "/Contents/" +
                    content.Key +
                    "/DataLow/" +
                    filename;
                this.checkCdnUrl(url, (res) => {
                    content.DataLowUrl = res;
                    this.tryEmitLibrary();
                });
            } else {
                try {
                    filename = content.Data;
                    filename =
                        "datalow." +
                        filename.substring(filename.lastIndexOf(".") + 1);
                    const url = getDownloadURL(
                        Sref(
                            this.storage,
                            "Library/" +
                                library.Key +
                                "/Contents/" +
                                content.Key +
                                "/DataLow/" +
                                filename,
                        ),
                    ).then(
                        (url) => {
                            if (url !== undefined && typeof url === "string") {
                                content.DataLowUrl = url;
                            }
                        },
                        () => {
                            //this.tryEmitLibrary();
                        },
                    );
                } catch (error) {
                    //console.log(error);
                }
                this.tryEmitLibrary();
            }
        }

        if (content.category === ContentCategory.Image)
            await this.getDataEditorURL(library.Key, content);
        if (
            content.category === ContentCategory.Image ||
            content.category === ContentCategory.Video
        )
            await this.getPreviewURL(library.Key, content);
    }

    async getDataHighURL(library: Library, content: Content, jpg = false) {
        let filename = "";
        if (
            content.DataHighUrl == "" &&
            content.Data != "" &&
            content.Status > 0
        ) {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.GenWebp
                    ? "data_high.webp"
                    : "data_high." +
                      (!jpg
                          ? content.Data.substring(
                                content.Data.lastIndexOf(".") + 1,
                            )
                          : "jpg");
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    library.Key +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    //content.DataHighUrl=res;
                    //this.tryEmitLibrary();
                    this.dataHighCallback(content, url, res, 0);
                });
            } else {
                try {
                    filename = content.GenWebp
                        ? "data_high.webp"
                        : "data_high." +
                          (!jpg
                              ? content.Data.substring(
                                    content.Data.lastIndexOf(".") + 1,
                                )
                              : "jpg");
                    const path =
                        "Library/" +
                        library.Key +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename;
                    const url = getDownloadURL(Sref(this.storage, path));
                    if (url !== undefined && typeof url === "string") {
                        content.DataHighUrl = url;
                        this.contentSizeloaded++;
                        this.tryEmitLibrary();
                    } else {
                        this.tryEmitLibrary();
                        if (!jpg)
                            await this.getDataHighURL(library, content, !jpg);
                    }
                } catch (error) {
                    this.tryEmitLibrary();
                }
            }
        }
    }
    dataHighCallback(content, url, res, nb) {
        nb++;

        if (res == "" && nb < 5) {
            setTimeout(() => {
                this.checkCdnUrl(url, (res) => {
                    this.dataHighCallback(content, url, res, nb);
                }),
                    500;
            });
            return;
        }
        this.contentSizeloaded++;
        content.DataUrl = res;
        this.tryEmitLibrary();
    }
    async getDataMedURL(library: Library, content: Content, jpg = false) {
        let filename = "";
        if (
            content.DataHighUrl == "" &&
            content.Data != "" &&
            content.Status > 0
        ) {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.GenWebp
                    ? "data_med.webp"
                    : "data_med." +
                      (!jpg
                          ? content.Data.substring(
                                content.Data.lastIndexOf(".") + 1,
                            )
                          : "jpg");
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    library.Key +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    content.DataMedUrl = res;
                    this.tryEmitLibrary();
                });
            } else {
                try {
                    filename = content.GenWebp
                        ? (filename = "data_med.webp")
                        : "data_med." +
                          (!jpg
                              ? content.Data.substring(
                                    content.Data.lastIndexOf(".") + 1,
                                )
                              : "jpg");

                    //filename = 'data_med.' + filename.substring(filename.lastIndexOf('.') + 1);
                    const path =
                        "Library/" +
                        library.Key +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename;
                    await getDownloadURL(Sref(this.storage, path)).then(
                        (url) => {
                            content.DataMedUrl = url;
                            // this.refreshMosaic();
                            this.contentSizeloaded++;
                        },
                        async (error) => {
                            //if(!jpg)
                            // await this.getDataMedURL(library,content,!jpg);
                        },
                    );
                } catch (error) {
                    //console.log(error);
                }
                this.tryEmitLibrary();
            }
        }
    }
    async getDataLowURL(library: Library, content: Content, jpg = false) {
        let filename = "";
        if (
            content.DataHighUrl == "" &&
            content.Data != "" &&
            content.Status > 0
        ) {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.GenWebp
                    ? "data_low.webp"
                    : "data_low." +
                      (!jpg
                          ? content.Data.substring(
                                content.Data.lastIndexOf(".") + 1,
                            )
                          : "jpg");
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    library.Key +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    content.DataLowUrl = res;
                    this.tryEmitLibrary();
                });
            } else {
                try {
                    filename = content.GenWebp
                        ? "data_low.webp"
                        : "data_low." +
                          (!jpg
                              ? content.Data.substring(
                                    content.Data.lastIndexOf(".") + 1,
                                )
                              : "jpg");
                    //filename = 'data_low.' + filename.substring(filename.lastIndexOf('.') + 1);
                    const path =
                        "Library/" +
                        library.Key +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename;
                    await getDownloadURL(Sref(this.storage, path)).then(
                        (url) => {
                            content.DataLowUrl = url;
                            this.contentSizeloaded++;
                        },
                        async (error) => {
                            console.error(error);
                        },
                    );
                } catch (error) {
                    //console.log(error);
                }
                this.tryEmitLibrary();
            }
        }
    }
    async getDataEditorURL(librarykey: string, content: Content, jpg = false) {
        let filename = "";
        if (
            content.DataEditorUrl == "" &&
            content.Data != "" &&
            content.Status > 0
        ) {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.GenWebp
                    ? "data_editor.webp"
                    : "data_editor." +
                      (!jpg
                          ? content.Data.substring(
                                content.Data.lastIndexOf(".") + 1,
                            )
                          : "jpg");
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    librarykey +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    content.DataEditorUrl = res;
                    this.tryEmitLibrary();
                });
            } else {
                try {
                    filename = content.GenWebp
                        ? "data_editor.webp"
                        : "data_editor." +
                          (!jpg
                              ? content.Data.substring(
                                    content.Data.lastIndexOf(".") + 1,
                                )
                              : "jpg");
                    const path =
                        "Library/" +
                        librarykey +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename;
                    await getDownloadURL(Sref(this.storage, path)).then(
                        (url) => {
                            content.DataEditorUrl = url;
                            // this.refreshMosaic();
                            this.contentSizeloaded++;
                        },
                        async (error) => {
                            console.error(error);
                        },
                    );
                } catch (error) {
                    //console.log(error);
                }
                this.tryEmitLibrary();
            }
        }
    }
    async getDataURL(library: Library, content: Content, jpg = false) {
        if (content == undefined) return;
        let filename = "";
        if (content.DataUrl == "" && content.Data != "" && content.Status > 0) {
            this.nbToCompute++;
            if (environment.useCDN) {
                filename = content.Data;
                filename =
                    "data." +
                    (!jpg
                        ? filename.substring(filename.lastIndexOf(".") + 1)
                        : "jpg");
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    library.Key +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    this.dataCallback(content, url, res, 0);
                });
            } else {
                filename = content.Data;
                filename =
                    "data." +
                    (!jpg
                        ? filename.substring(filename.lastIndexOf(".") + 1)
                        : "jpg");
                //filename = 'data.' + filename.substring(filename.lastIndexOf('.') + 1);
                try {
                    const url = await getDownloadURL(
                        Sref(
                            this.storage,
                            "Library/" +
                                library.Key +
                                "/Contents/" +
                                content.Key +
                                "/Data/" +
                                filename,
                        ),
                    );

                    if (url !== undefined && typeof url === "string") {
                        content.DataUrl = url;
                        /*	if(content.category == ContentCategory.Video)
						{
							this.getDuration(content);
						}*/
                    } else {
                        /*if(!jpg)
						await this.getDataURL(library,content,!jpg);	*/
                    }
                } catch (error) {
                    //console.log(error);
                }
                this.tryEmitLibrary();
            }
        }
    }
    dataCallback(content, url, res, nb) {
        nb++;
        //console.log("dataCallback : " + nb);
        //console.log("url->" + url);
        //console.log("res->" + res);
        if (res == "" && nb < 50) {
            setTimeout(() => {
                this.checkCdnUrl(url, (res) => {
                    this.dataCallback(content, url, res, nb);
                }),
                    500;
            });
            return;
        }
        this.contentSizeloaded++;
        content.DataUrl = res;
        this.tryEmitLibrary();
    }
    async getPreviewURL(librarykey: string, content: Content) {
        if (
            content === undefined ||
            content === null ||
            librarykey === undefined
        ) {
            return;
        }

        /*if(content.category===ContentCategory.Obj3D)
        {
            console.log("Obj3D");
        }*/

        this.nbToCompute++;
        if (
            content.category === ContentCategory.Video &&
            (content.Preview === "" ||
                content.Preview === undefined ||
                content.Preview.includes("preview_auto")) &&
            content.VimeoData?.uri !== undefined &&
            content.VimeoData?.uri !== ""
        ) {
            //const response = await firstValueFrom(this._vimeoService.getThumbnails(content.VimeoData.uri));
            this._vimeoService
                .getThumbnails(content.VimeoData.uri)
                ./*then((request)=>{
                request?.*/ subscribe((response) => {
                    if (response["body"] !== undefined) {
                        const thumbnail = response["body"]?.base_link;
                        content.PreviewUrl = thumbnail;

                        //console.log("thumbnail of '"+content.title+"' : " + thumbnail);
                        this.tryEmitLibrary();
                        if (
                            this.template !== undefined &&
                            this.template !== null
                        ) {
                            if (this.template.Key === librarykey) {
                                this.emitTemplate();
                            }
                        }
                    }
                });
            //})
            return;
        }
        if (
            content.Preview != "" &&
            !content.Preview.startsWith("preview_auto")
        ) {
            if (environment.useCDN) {
                let filename = content.Preview;
                filename =
                    "preview." +
                    filename.substring(filename.lastIndexOf(".") + 1);
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    librarykey +
                    "/Contents/" +
                    content.Key +
                    "/Preview/" +
                    filename;

                this.checkCdnUrl(url, (res) => {
                    content.PreviewUrl = res;
                    this.tryEmitLibrary();
                });
            } else {
                let filename = content.Preview;
                filename =
                    "preview." +
                    filename.substring(filename.lastIndexOf(".") + 1);
                this._storageService.checkIfFileExists(
                    "Library/" +
                        librarykey +
                        "/Contents/" +
                        content.Key +
                        "/Preview/",
                    content.Preview,
                );
                try {
                    const url = await getDownloadURL(
                        Sref(
                            this.storage,
                            "Library/" +
                                librarykey +
                                "/Contents/" +
                                content.Key +
                                "/Preview/" +
                                filename,
                        ),
                    );

                    if (url !== undefined && typeof url === "string") {
                        content.PreviewUrl = url;
                        console.log("getPreviewURL " + content.PreviewUrl);
                    }
                } catch (e) {
                    //console.log(e);
                }

                this.tryEmitLibrary();
            }
        } else {
            if (environment.useCDN) {
                if (content.PreviewUrl == "" && content.AutoPreview) {
                    const filename = content.GenWebp
                        ? "preview_auto.webp"
                        : content.Preview;
                    //filename = 'preview_auto.' + ((!jpg)?filename.substring(filename.lastIndexOf('.') + 1):'jpg');
                    const url =
                        environment.firebase.cdnURL +
                        "/Library/" +
                        librarykey +
                        "/Contents/" +
                        content.Key +
                        "/Preview/" +
                        filename;

                    this.checkCdnUrl(url, (res) => {
                        content.PreviewUrl = res;
                        this.tryEmitLibrary();
                    });
                }
            } else {
                if (content.PreviewUrl == "" && content.AutoPreview) {
                    const filename = content.GenWebp
                        ? "preview_auto.webp"
                        : content.Preview;
                    //console.log('Library/' + library.Key + '/Contents/' + content.Key +'/Preview/' + filename);
                    //filename = 'preview_auto.' + ((!jpg)?filename.substring(filename.lastIndexOf('.') + 1):'jpg');
                    this._storageService.checkIfFileExists(
                        "Library/" +
                            librarykey +
                            "/Contents/" +
                            content.Key +
                            "/Preview/",
                        filename,
                    );
                    try {
                        //console.log(filename);
                        const reference = Sref(
                            this.storage,
                            "Library/" +
                                librarykey +
                                "/Contents/" +
                                content.Key +
                                "/Preview/" +
                                filename,
                        ); //content.Preview);

                        const url = await getDownloadURL(reference);

                        if (url !== undefined && typeof url === "string") {
                            content.PreviewUrl = url;
                            //content.Preview=filename;
                            this.contentSizeloaded++;
                        } else {
                            await this.getPreviewURL(librarykey, content);
                            return;
                        }
                    } catch (e) {
                        //console.log(e);
                    }
                    this.tryEmitLibrary();
                }
            }
        }
    }

    async getStorageUrl(content: Content, lang = "") {
        let filename = content.Data;
        filename = "data." + filename.substring(filename.lastIndexOf(".") + 1);

        if (lang === "") {
            return await getDownloadURL(
                Sref(
                    this.storage,
                    "Library/" +
                        this.library.Key +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename,
                ),
            );
        } else {
            return await getDownloadURL(
                Sref(
                    this.storage,
                    lang +
                        "/Library/" +
                        this.library.Key +
                        "/Contents/" +
                        content.Key +
                        "/Data/" +
                        filename,
                ),
            );
        }
    }

    async getContentVimeoData(content: Content) {
        const index = this.library.Contents.findIndex(
            (c) => c.Key === content.Key,
        );
        const data = await get(
            ref(
                this.database,
                this.getDatabasePrefix() +
                    "Library/" +
                    this.library.Key +
                    "/Contents/" +
                    index +
                    "/VimeoData/",
            ),
        );

        return data?.val();
    }
    async setContentVimeoData(content: Content) {
        const index = this.library.Contents.findIndex(
            (c) => c.Key === content.Key,
        );
        if (content !== undefined && content.VimeoData !== undefined) {
            await set(
                ref(
                    this.database,
                    this.getDatabasePrefix() +
                        "Library/" +
                        this.library.Key +
                        "/Contents/" +
                        index +
                        "/VimeoData/",
                ),
                this.jsonConvert.serializeObject<VimeoData>(content.VimeoData),
            );
        } else {
            await remove(
                ref(
                    this.database,
                    this.getDatabasePrefix() +
                        "Library/" +
                        this.library.Key +
                        "/Contents/" +
                        index +
                        "/VimeoData/",
                ),
            );
        }
    }

    refreshMosaic() {
        if (this.projects != undefined)
            this.projects.forEach((project) => {
                project.setMosaic(
                    this._projectV2Service.UpdateMosaic(
                        project.getContents(),
                        this.library,
                    ),
                );
            });
    }

    copyLibrary(userKey, fromVersion, toVersion, newKey) {
        get(
            ref(
                this.database,
                "Users/" + userKey + "/Versions/" + fromVersion + "/Library/",
            ),
        ).then((keySnapshot) => {
            //console.log("Get Library From Database");
            const key = keySnapshot.val();
            //console.log(key);
            //console.log(newKey);

            get(ref(this.database, fromVersion + "/Library/" + key)).then(
                (librarySnapshot) => {
                    //console.log(librarySnapshot);

                    const library = this.jsonConvert.deserializeObject(
                        librarySnapshot.toJSON(),
                        Library,
                    );
                    const json =
                        this.jsonConvert.serializeObject<Library>(library);
                    if (!this.checkKeyNotEmpty(newKey)) return;
                    set(
                        ref(
                            this.database,
                            toVersion + "/Library/" + newKey + "/",
                        ),
                        json,
                    );
                },
            );
        });
    }

    copyContent(content: Content) {
        return this.jsonConvert.deserializeObject(
            this.jsonConvert.serializeObject<Content>(content),
            Content,
        );
    }

    renameTag(oldTag: string, newTag: string) {
        let index = -1; // this.library.tags.findIndex((t)=>{oldTag == t.Name});
        for (let j = 0; j < this.library.tags.length; j++) {
            if (this.library.tags[j].Name == oldTag) {
                index = j;
                break;
            }
        }

        if (index == -1) return;

        for (let i = 0; i < this.library.contents.length; i++) {
            const t = this.library.contents[i].tags.indexOf(oldTag);
            if (t != -1) this.library.contents[i].tags[t] = newTag;
        }
        this.library.tags[index].Name = newTag;
        this._libraryService.setLibrary(this.library);
        this.setLibrary(this.library, false);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ LIBRARY
    // -----------------------------------------------------------------------------------------------------
    template: Library;
    templateSubject = new Subject<Library>();
    inProgressTemplate = false;
    //contentSizeloaded = 0;

    emitTemplate() {
        this.templateSubject.next(this.template);
    }

    initTemplate() {
        this.initDatabase();
    }

    async getTemplate() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        if (this.inProgressTemplate) {
            return;
        }
        this.inProgressTemplate = true;
        //console.log("Get Library");
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }

        await get(
            ref(
                this.database,
                environment.template.version +
                    "/Library/" +
                    environment.template.libraryKey,
            ),
        )
            .then(async (templateSnapshot) => {
                this.template = this.jsonConvert.deserializeObject(
                    templateSnapshot.toJSON(),
                    Library,
                );

                if (this.template == undefined || this.template == null) {
                    console.error("Default content not found !");
                } else {
                    //console.log("Default content found !");
                    //console.log(this.template);
                    this.template.Contents.forEach((content) => {
                        this.getPreviewURL(this.template.Key, content);
                        if (
                            content.category == ContentCategory.Audio ||
                            content.category == ContentCategory.Gps
                        )
                            this.getContentUrls(this.template, content);
                    });
                }
            })
            .then(
                () => {
                    this.inProgressTemplate = false;
                    this.emitTemplate();
                },
                () => {
                    this.inProgressTemplate = false;
                    this.emitTemplate();
                },
            );
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Content
    // -----------------------------------------------------------------------------------------------------
    uploadInProgress = 0;

    async addOrUpdateContent(content: Content, action = "new") {
        //console.log('----------addOrUpdateContent----------');
        if (!this.library) {
            this.getLibrary();
            return;
        }

        if (action == "new") {
            content.setKey(this.generateKey());
            this._libraryService.getLibrary().addContent(content);
            this.getPreviewURL(this.library?.Key, content);
            content.VimeoData = undefined;
        }

        // Update
        const datetoupd = Date.now().toString();
        let nbFileToUpload = 0;
        let nbFileUploaded = 0;

        if (content.getPreviewFile() != undefined) {
            nbFileToUpload++;
            this.uploadInProgress++;
        }
        if (content.getDataFile() != undefined) {
            nbFileToUpload++;
            this.uploadInProgress++;
        }
        if (content.getDataLowFile() != undefined) {
            nbFileToUpload++;
            this.uploadInProgress++;
        }
        if (nbFileToUpload == 0 && content.Status != 0) {
            content.setStatus(1);
            this._libraryService.getLibrary().setLastUpdate(datetoupd);
        } else {
            content.setStatus(0);
            content.setLastUpdate(datetoupd);
        }

        if (content.category === ContentCategory.Video) {
            const vimeoData: VimeoData =
                await this.getContentVimeoData(content);

            if (
                vimeoData !== null &&
                vimeoData !== undefined &&
                vimeoData.status !== null &&
                vimeoData.status !== undefined
            ) {
                if (vimeoData.files == undefined) {
                    vimeoData.files = [];
                }
                content.VimeoData = this.jsonConvert.deserializeObject(
                    this.jsonConvert.serializeObject(vimeoData, VimeoData),
                    VimeoData,
                );
            }
        }
        this._libraryService.getLibrary().changeContent(content);

        this.setLibrary(this._libraryService.getLibrary());

        // Upload file
        const library = this._libraryService.getLibrary();
        if (content.category !== ContentCategory.Video && content.Resize) {
            if (!this.checkKeyNotEmpty(content.key)) return;
            set(ref(this.database, "Temp/" + content.key + "/high"), false);
            set(ref(this.database, "Temp/" + content.key + "/editor"), false);
            set(ref(this.database, "Temp/" + content.key + "/med"), false);
            set(ref(this.database, "Temp/" + content.key + "/low"), false);
            this.checkIfPrensent(content, "/high", () =>
                this.getDataHighURL(library, content),
            );
            this.checkIfPrensent(content, "/editor", () =>
                this.getDataEditorURL(library.Key, content),
            );
            this.checkIfPrensent(content, "/med", () =>
                this.getDataMedURL(library, content),
            );
            this.checkIfPrensent(content, "/low", () =>
                this.getDataLowURL(library, content),
            );
        }
        if (content.Preview.startsWith("preview_auto")) {
            const status = {
                preview: false,
                editor: false,
                high: false,
                med: false,
                low: false,
            };
            if (!this.checkKeyNotEmpty(content.key)) return;
            set(ref(this.database, "Temp/" + content.key + "/preview"), false);
            this.checkIfPrensent(content, "/preview", () =>
                this.getPreviewURL(library.Key, content),
            );
        }
        // Preview
        if (content.getPreview() == "") {
            // Delete preview on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/Preview",
            );
            this.syncUsage();
        } else if (content.getPreviewFile() != undefined) {
            // Delete old preview on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/Preview",
            );

            // Upload new preview on storage
            // NB: content.getPreviewFile() is OK
            await this._storageService
                .uploadFileV2(
                    library.getKey(),
                    content.getKey(),
                    content.getPreviewFile(),
                    DlType.Preview,
                )
                .then(async (data: any) => {
                    nbFileUploaded++;
                    this.uploadInProgress--;
                    content.setPreviewUrl(data.url);
                    this.writeDateToDataBase(
                        nbFileToUpload,
                        nbFileUploaded,
                        datetoupd,
                        this._libraryService.getLibrary(),
                        content,
                    );
                    if (content.category === ContentCategory.Video) {
                        const vimeoData: VimeoData =
                            await this.getContentVimeoData(content);

                        if (
                            vimeoData !== null &&
                            vimeoData !== undefined &&
                            vimeoData.status !== null &&
                            vimeoData.status !== undefined
                        ) {
                            if (vimeoData.files == undefined) {
                                vimeoData.files = [];
                            }
                            content.VimeoData =
                                this.jsonConvert.deserializeObject(
                                    this.jsonConvert.serializeObject(
                                        vimeoData,
                                        VimeoData,
                                    ),
                                    VimeoData,
                                );
                        }
                    }
                    this._libraryService.getLibrary().changeContent(content);
                    this.setLibrary(this._libraryService.getLibrary());
                    this.syncUsage();
                })
                .catch((errorData) => {
                    this.uploadInProgress--;
                    console.error(errorData);
                    if (errorData.error.code != "storage/canceled") {
                        this._libraryService.addErrorOnContent(
                            errorData.content,
                        );
                    }
                });
        } else {
            this.getPreviewURL(this.library.Key, content);
        }

        //console.log("add or upd 2");
        // Data
        if (content.getData() == "") {
            if (
                content.VimeoData !== undefined &&
                content.VimeoData !== null &&
                content.VimeoData.uri !== undefined
            ) {
                await this._functionsService.deleteVimeo(content.VimeoData.uri);
            }

            // Delete data on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/Data",
            );

            this.syncUsage();
        } else if (content.getDataFile() != undefined) {
            if (content.AutoPreview && content.PreviewUrl != "") {
                URL.revokeObjectURL(content.PreviewUrl);
                content.PreviewUrl = "";
            }

            if (content.Resize && content.DataLowUrl != "") {
                content.DataLowUrl = "";
                URL.revokeObjectURL(content.DataLowUrl);
            }
            if (content.Resize && content.DataMedUrl != "") {
                content.DataMedUrl = "";
                URL.revokeObjectURL(content.DataMedUrl);
            }
            if (content.Resize && content.DataHighUrl != "") {
                content.DataHighUrl = "";
                URL.revokeObjectURL(content.DataHighUrl);
            }
            if (content.Resize && content.DataEditorUrl != "") {
                content.DataEditorUrl = "";
                URL.revokeObjectURL(content.DataEditorUrl);
            }
            if (content.DataUrl != "") {
                URL.revokeObjectURL(content.DataUrl);
                content.DataUrl = "";
            }

            if (content.category == ContentCategory.Image) {
                //juste pour les image sinon ca palnte
                try {
                    await exifr
                        .gps(content.getDataFile())
                        .catch(console.error)
                        .then((output) => {
                            if (output) {
                                content.markerData = new MarkerData(
                                    output.latitude,
                                    output.longitude,
                                );
                            }
                        });
                } catch (e) {
                    console.error(e);
                }
            }

            if (
                content.VimeoData !== undefined &&
                content.VimeoData !== null &&
                content.VimeoData.uri !== undefined
            ) {
                await this._functionsService.deleteVimeo(content.VimeoData.uri);
            }

            // Delete old data on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/Data",
            );

            // Upload new data on storage
            // NB: content.getDataFile() is OK
            if (
                this.user.Stripe.Type === SubscriptionType.Pro ||
                content.category !== ContentCategory.Video
            ) {
                await this._storageService
                    .uploadFileV2(
                        library.getKey(),
                        content.getKey(),
                        content.getDataFile(),
                        DlType.Data,
                        content,
                    )
                    .then(async (data: any) => {
                        nbFileUploaded++;
                        this.uploadInProgress--;
                        content.setDataUrl(data.url);
                        content.setDataSize(data.size);
                        const filename =
                            "data" +
                            content.Data.substring(
                                content.Data.lastIndexOf("."),
                            );
                        //const indexRef = library.Contents.findIndex((c)=>{return c.key == content.key});
                        console.log("content.category : " + content.category);
                        console.log("action : " + action);
                        console.log(this.user.SpecificData);

                        if (
                            content.category === "Video" &&
                            !(
                                //action === "new" &&
                                this.user.SpecificData.includes(
                                    UserSpecific.RemoveStream,
                                )
                            )
                        ) {
                            console.log("uploadToVimeo");
                            await this.uploadToVimeo(content);
                        }
                        //await this._vimeoService.uploadToVimeo(library.getKey(),content.getKey(),filename);
                        await this._functionsService.generateContent(
                            library.getKey(),
                            content.getKey(),
                            filename,
                            content.category,
                            content.Type,
                        );

                        this.getContentUrls(this.library, content);
                        this.writeDateToDataBase(
                            nbFileToUpload,
                            nbFileUploaded,
                            datetoupd,
                            this._libraryService.getLibrary(),
                            content,
                        );
                        if (content.category === ContentCategory.Video) {
                            const vimeoData: VimeoData =
                                await this.getContentVimeoData(content);

                            if (
                                vimeoData !== null &&
                                vimeoData !== undefined &&
                                vimeoData.status !== null &&
                                vimeoData.status !== undefined
                            ) {
                                if (vimeoData.files == undefined) {
                                    vimeoData.files = [];
                                }
                                content.VimeoData =
                                    this.jsonConvert.deserializeObject(
                                        this.jsonConvert.serializeObject(
                                            vimeoData,
                                            VimeoData,
                                        ),
                                        VimeoData,
                                    );
                            }
                        }
                        this._libraryService
                            .getLibrary()
                            .changeContent(content);
                        this.setLibrary(this._libraryService.getLibrary());

                        this.syncUsage();
                    })
                    .catch((errorData) => {
                        this.uploadInProgress--;
                        console.error(errorData);

                        if (
                            errorData.error != undefined &&
                            errorData.error.code != "storage/canceled"
                        ) {
                            this._libraryService.addErrorOnContent(
                                errorData.content,
                            );
                        }
                    });
            } else {
                //directUpload !

                const formData = new FormData();
                //console.log('content.dataFile');
                //console.log(content.dataFile);
                const size = content.dataFile.file.file;
                formData.append(
                    "file",
                    content.dataFile.file.file,
                    content.dataFile.file.filename,
                );
                formData.append("librarykey", this.library.Key);
                formData.append("contentkey", content.key);
                formData.append(
                    "contentfilename",
                    content.dataFile.file.filename,
                );
                formData.append("contentname", content.title);
                formData.append(
                    "ref",
                    this.user.Version +
                        "/Library/" +
                        this.library.Key +
                        "/Contents/",
                );
                formData.append("token", environment.vimeo.token);
                content.VimeoData = {
                    status: "uploading",
                    uri: "",
                    files: [],
                };
                content.setDataSize(size.size);
                content.DirectUpload = true;
                //console.log('directUpload');
                //console.log(size);
                this.user.DiskUsage += size.size / Math.pow(10, 9);
                this.setUser(this.user);

                this._vimeoService.directUploadKey(
                    formData,
                    content.key,
                    () => {
                        this.uploadInProgress--;
                        content.VimeoData = {
                            status: "in_progress",
                            uri: "",
                            files: [],
                        };
                        this._libraryService
                            .getLibrary()
                            .changeContent(content);
                        this.setLibrary(this._libraryService.getLibrary());
                        this.getVimeoStatus(content, undefined);
                    },
                );

                this._libraryService.getLibrary().changeContent(content);
                this.setLibrary(this._libraryService.getLibrary());
            }
        }

        // Data Low
        if (content.getDataLow() == "") {
            // Delete data on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/DataLow",
            );
            this.syncUsage();
        } else if (content.getDataLowFile() != undefined) {
            // Delete old data on storage
            await this._storageService.deleteFolderContents(
                "Library/" +
                    library.getKey() +
                    "/Contents/" +
                    content.getKey() +
                    "/DataLow",
            );

            // Upload new data on storage
            // NB: content.getDataLowFile() is OK
            await this._storageService
                .uploadFileV2(
                    library.getKey(),
                    content.getKey(),
                    content.getDataLowFile(),
                    DlType.DataLow,
                )
                .then(async (data: any) => {
                    nbFileUploaded++;
                    this.uploadInProgress--;
                    content.setDataLowUrl(data.url);
                    content.setDataLowSize(data.size);
                    this.writeDateToDataBase(
                        nbFileToUpload,
                        nbFileUploaded,
                        datetoupd,
                        this._libraryService.getLibrary(),
                        content,
                    );
                    if (content.category === ContentCategory.Video) {
                        const vimeoData: VimeoData =
                            await this.getContentVimeoData(content);

                        if (
                            vimeoData !== null &&
                            vimeoData !== undefined &&
                            vimeoData.status !== null &&
                            vimeoData.status !== undefined
                        ) {
                            if (vimeoData.files == undefined) {
                                vimeoData.files = [];
                            }
                            content.VimeoData =
                                this.jsonConvert.deserializeObject(
                                    this.jsonConvert.serializeObject(
                                        vimeoData,
                                        VimeoData,
                                    ),
                                    VimeoData,
                                );
                        }
                    }
                    this._libraryService.getLibrary().changeContent(content);
                    this.setLibrary(this._libraryService.getLibrary());

                    this.syncUsage();
                })
                .catch((errorData) => {
                    this.uploadInProgress--;
                    console.error(errorData);

                    if (errorData.error.code != "storage/canceled") {
                        this._libraryService.addErrorOnContent(
                            errorData.content,
                        );
                    }
                });
        }
    }

    async addOrUpdateLangs(langs: Content[], content: Content) {
        const key = content.key;
        //console.log('Key : ' + key);
        if (key === undefined) {
            console.error("Content key undefined for translated contents !");
            return;
        }
        //console.log("---------------add update langs--------------------")
        //console.log(langs)
        for (let index = 0; index < langs.length; index++) {
            if (langs[index] != undefined) {
                langs[index].key = key;
                //console.log(langs[index]);
                if (langs[index].dataFile != undefined) {
                    const data: any = await this._storageService.uploadFileV2(
                        this.library.Key,
                        key,
                        langs[index].dataFile,
                        DlType.Data,
                        langs[index],
                        this.user.Languages[index],
                    );
                    langs[index].setData(
                        data !== undefined ? data.name : undefined,
                    );
                    langs[index].setDataUrl(
                        data !== undefined ? data.url : undefined,
                    );
                    if (content.category === ContentCategory.Video) {
                        langs[index].category = content.category;
                        //console.log('uploadToVimeo for ' + this.user.Languages[index]);
                        this.uploadToVimeo(
                            langs[index],
                            this.user.Languages[index],
                        );
                    }
                }

                langs[index].setStatus(1);
                await this.setContentLanguage(
                    this.user.Languages[index],
                    this.library.Key,
                    langs[index],
                );
                const c = this.library.getContent(key);
                const split = c.Data.split(".");
                const title = "data." + split[split.length - 1];
                //console.log("---------DATA----------------");
                //console.log('TITLE : ' + title);
                //console.log(c);
                this._functionsService.generateContent(
                    this.library.Key,
                    key,
                    title,
                    c.category,
                    this.user.Languages[index],
                );
            } else {
                const c = await this.getContentLanguage(
                    this.user.Languages[index],
                    this.library.Key,
                    key,
                );
                if (c !== undefined && c.VimeoData !== undefined) {
                    await this._functionsService.deleteVimeo(c.VimeoData.uri);
                }
                await this._storageService.deleteFolderContents(
                    this.user.Languages[index] +
                        "/Library/" +
                        this.library.Key +
                        "/Contents/" +
                        key,
                    key,
                );
                await this.removeContentLanguage(
                    this.user.Languages[index],
                    this.library.Key,
                    key,
                );
            }
        }
    }

    async uploadStoryContentToVimeo(key: string) {
        const scenario = this.scenarios.find((s) => s.key === key);
        console.log("uploadStoryContentToVimeo");
        console.log(scenario);

        forEach(this.library.Contents, async (content) => {
            if (scenario.containsMediaFor(content.key)) {
                console.log("-------uploadToVimeo for " + content.key);
                if (content.category === ContentCategory.Video) {
                    await this.uploadToVimeo(content);
                    console.log("-------uploadToVimeo for " + content.key);
                }
            }
        });
    }

    async uploadToVimeo(content: Content, lang: string = undefined) {
        //wait updload to complete
        //console.log('uploadToVimeo : ' + content.key);
        //console.log('lang : ' + lang);
        //console.log(this._storageService.DownloadInProgress());
        if (this._storageService.DownloadInProgress()) {
            setTimeout(() => this.uploadToVimeo(content, lang), 500);
            return Promise.resolve(undefined);
        }

        const ref = this.getVimeoDatabaseRef(lang);
        const filename =
            "data" + content.Data.substring(content.Data.lastIndexOf("."));
        await this._functionsService.uploadToVimeo(
            this.library.getKey(),
            content.getKey(),
            ref,
            filename,
            content.Title,
            lang,
        );
        content.VimeoData = new VimeoData();
        content.VimeoData.status = "in_progress";
        //await this.getLibrary();
        if (lang === undefined) {
            this._libraryService.getLibrary().changeContent(content);
        }
        this.setLibrary(this._libraryService.getLibrary());

        //	this.getVimeoStatus(content,lang);fait dans le setLibrary
        return Promise.resolve(undefined);
    }

    async getVimeoStatus(content: Content, lang: string) {
        const reference = this.getVimeoDatabaseRef(lang);
        const indexRef = this._libraryService
            .getLibrary()
            .Contents.findIndex((c) => c.key == content.key);
        //await this.getContentDatabaseIndex(content, lang);
        const refFinal = reference + indexRef + "/VimeoData";

        console.log("GET VIMEO STATUS");
        console.log(refFinal);

        const snapshot = await get(ref(this.database, refFinal)).catch(
            (error) => {
                console.error(error);
            },
        );
        if (!(snapshot instanceof Object)) {
            return;
        }

        //console.log('Get vimeoData from database : ');
        const data = new JsonConvert().deserializeObject(
            snapshot.toJSON(),
            VimeoData,
        );
        //console.log(data);

        if (data === undefined || data === null) {
            return;
        }

        content.VimeoData = data;
        //console.log(content);
        this._libraryService.getLibrary().changeContent(content);
        //this.setLibrary(this._libraryService.getLibrary());
        //console.log('Vimeo status : ' + content.VimeoData.status);
        if (
            content.VimeoData.status === "in_progress" ||
            content.VimeoData.status === "uploading"
        ) {
            console.log(
                "Vimeo status : " +
                    (
                        (Date.now() - parseInt(content.LastUpdate)) /
                        1000
                    ).toString() +
                    "s",
            );
            if ((Date.now() - parseInt(content.LastUpdate)) / 1000 > 60 * 20) {
                //si plus de 20 minutes on passe en erreur
                content.VimeoData.status = "error";
                this.setContentVimeoData(content);
                const notif: CustomNotif = {
                    id: this.generateKey(),
                    title: "Erreur liens de streaming",
                    icon: "error",
                    description:
                        'Echec de la création des liens de streaming pour La vidéo "' +
                        content.title +
                        '".\nVous pouvez recréer les liens.',
                    time: new Date().toString(),
                    read: false,
                };
                this._notificationsService.create(notif);
            } else
                setTimeout(() => this.getVimeoStatus(content, lang), 1000 * 60);
        } else {
            if (content.VimeoData.status === "complete") content.setStatus(1);
            //console.log(content);
            this._libraryService.getLibrary().changeContent(content);
            this.getPreviewURL(this.library.Key, content);
            this.emitLibrary();
        }
    }

    getVimeoDatabaseRef(lang = undefined) {
        return (
            this.getDatabasePrefix() +
            (lang === undefined
                ? "Library/" + this.library.getKey()
                : "Languages/" + lang + "/" + this.library.getKey()) +
            "/Contents/"
        ); //+indexRef+'/';
    }
    async getContentDatabaseIndex(content: Content, lang = undefined) {
        if (lang === undefined) {
            let contents = [];
            const lib = this.jsonConvert.deserializeObject(
                (
                    await get(
                        ref(
                            this.database,
                            this.getDatabasePrefix() +
                                "Library/" +
                                this.library.getKey(),
                        ),
                    )
                ).toJSON(),
                Library,
            );
            contents = lib?.Contents;
            return contents.findIndex((c) => c.key == content.key);
        } else {
            const c = this.jsonConvert.deserializeObject(
                (
                    await get(
                        ref(
                            this.database,
                            this.getDatabasePrefix() +
                                "Languages/" +
                                lang +
                                "/Contents/" +
                                content.key,
                        ),
                    )
                ).toJSON(),
                Content,
            );
            return c !== undefined ? c.key : content.key;
        }
    }

    checkIfPrensent(content: Content, path, callback) {
        onValue(
            ref(this.database, "Temp/" + content.key + path),
            (snapshot) => {
                const bool = snapshot.val();
                //console.log(path+" set in db : "+ bool);
                if (bool == null || !bool) {
                    //this.checkIfPrensent(content,library,path,callback);
                    return;
                }
                //console.log(path+" callback !");
                content.setLastUpdate(Date.now().toString());
                off(ref(this.database, "Temp/" + content.key + path));
                remove(
                    ref(this.database, "Temp/" + content.key + path),
                ).catch();
                callback();
            },
        );
    }
    writeDateToDataBase(
        file1: number,
        file2: number,
        date: string,
        library: Library,
        content: Content,
    ) {
        if (file1 == file2) {
            content.setStatus(1);
            library.setLastUpdate(date);
       //     this.getAllContentURL();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Interface
    // -----------------------------------------------------------------------------------------------------
    interface: Interface;
    interfaceSubject = new Subject<Interface>();

    emitInterface() {
        this._interfaceService.setInterface(this.interface);
        this.interfaceSubject.next(this.interface);
    }
    initInterface() {
        this.initDatabase();
    }
    getInterface() {
        if (
            this.user == undefined ||
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }
        //console.log("Get Interface");
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }
        //console.log("Get Interface");
        if (
            this.user != undefined &&
            this.user != null &&
            this.user.getKey() != ""
        ) {
            //console.log(this.user.Versions[this.user.Version].Interface);
            const key = this.user.Versions[this.user.Version].Interface;
            //ref(this.database,'Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/Interface/')).then( (keySnapshot) => {
            //console.log("Get Interface From Database");
            //console.log('Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/Interface/');
            //let key = keySnapshot.val();
            //console.log(key);
            if (key == undefined || key == null || key == "") {
                //console.log("User doesn't have Interface !");
                this._interfaceService.createNewInterface();
                this.interface = this._interfaceService.getInterface();
                this.user.addInterface(this.interface.getKey());
                this._userService.setUser(this.user);
                this.setUser(this.user);
            }

            get(
                ref(
                    this.database,
                    this.getDatabasePrefix() + "Interface/" + key,
                ),
            ).then((projectSnapshot) => {
                this.interface = this.jsonConvert.deserializeObject(
                    projectSnapshot.toJSON(),
                    Interface,
                );
                //console.log("project is null?");
                if (this.interface == undefined || this.interface == null) {
                    //console.log("User doesn't have project !");
                    this._interfaceService.createNewInterface();
                    this.interface = this._interfaceService.getInterface();

                    this.user.addInterface(this.interface.getKey());
                    this._userService.setUser(this.user);
                    this.setUser(this.user);
                    //console.log(this.project);
                    //this.setProject(this.project);
                    //console.log("firebase json");
                }
                //console.log('Users/' + user.uid + '/Versions/'+this.user.getVersion()+'/Interface/');
                //console.log(projectSnapshot.toJSON());
                //console.log("this.project");
                //console.log(this.interface);
                this.emitInterface();
            });
        } //);
    }

    setInterface(interf: Interface) {
        const interfaceSerialized =
            this.jsonConvert.serializeObject<Interface>(interf);

        if (
            this.user.Versions[this.user.Version].Interface != interf.getKey()
        ) {
            this.user.Versions[this.user.Version].Interface = interf.getKey();
            this.setUser(this.user);
            this._userService.setUser(this.user);
        }
        if (!this.checkKeyNotEmpty(interf.getKey())) return;
        return new Promise<void>((resolve, reject) => {
            set(
                ref(
                    this.database,
                    this.getDatabasePrefix() + "Interface/" + interf.getKey(),
                ),
                interfaceSerialized,
            )
                .then(() => {
                    resolve(undefined);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    setDefaultStory(def: string) {
        console.log(def);
        console.log(this.interface);
        this.interface.defaultStoryKey = def;
        this.interface.LastUpdate = Date.now().toString();
        this.setInterface(this.interface);
    }

    paired: UserPairing;
    pairedSubject = new Subject<DevicePairing[]>();
    onlineDeviceSubject = new Subject<DevicePairing>();

    async getDevicesPairing(chekonline: boolean) {
        this.paired = new UserPairing();
        console.log(this.user.Key);
        const snapshot = await get(
            ref(this.database, "/Pairing/Users/" + this.user.Key + "/Devices/"),
        );
        if (snapshot !== null) {
            this.paired.Devices = [];
            snapshot.forEach((child) => {
                const device = new JsonConvert().deserializeObject(
                    child.val(),
                    DevicePairing,
                );
                if (device !== null) this.paired.Devices.push(device);
            });
        }

        console.log(this.paired.Devices.length);

        if (this.paired === null || this.paired === undefined) {
            this.paired = new UserPairing();
        }
        //console.log(this.paired);
        if (chekonline) {
            this.checkDevicesPairing();
            this.emitDevicesPairing();
        }

        const refNEW = ref(
            this.database,
            "/Pairing/Users/" + this.user.Key + "/DeviceAdded/",
        );
        onValue(refNEW, async (snapshot) => {
            console.log("DEVICE ADDED");
            const val = snapshot.val();
            if (val !== null) {
                console.log(val);

                const split = val.split("|");
                console.log(split);
                const snapshotDev = await get(
                    ref(
                        this.database,
                        "/Pairing/Users/" +
                            this.user.Key +
                            "/Devices/" +
                            split[1],
                    ),
                );

                const device = new JsonConvert().deserializeObject(
                    snapshotDev.val(),
                    DevicePairing,
                );
                if (
                    this.paired.Devices !== null ||
                    this.paired.Devices !== undefined
                ) {
                    this.paired.Devices = this.paired.Devices.filter(
                        (elements) => {
                            return elements !== null;
                        },
                    );

                    const index = this.paired.Devices.findIndex(
                        (x) => x.Guid === device.Guid,
                    );
                    if (index === -1) {
                        if (device !== null) this.paired.Devices.push(device);
                        this.checkDevicesPairing();
                        this.emitDevicesPairing();
                    }
                } else {
                    if (device !== null) this.paired.Devices.push(device);
                    this.checkDevicesPairing();
                    this.emitDevicesPairing();
                }
            }
        });
    }

    unsubscribeDevice: any[] = [];
    guidlist: string[] = [];

    checkDevicesPairing() {
        this.stopDevicesPairing();
        if (this.paired === null || this.paired === undefined) {
            this.paired = new UserPairing();
        }

        forEach(this.paired.Devices, (device) => {
            console.log("checkDevicesPairing");
            if (device !== null) {
                this.guidlist.push(device.Guid);
                const refe = ref(
                    this.database,
                    "/Pairing/Users/" +
                        this.user.Key +
                        "/Devices/" +
                        device.Guid,
                );
                this.unsubscribeDevice.push(
                    onValue(refe, (snapshot) => {
                        const data = snapshot.val();
                        const device = new JsonConvert().deserializeObject(
                            data,
                            DevicePairing,
                        );
                        this.emitDeviceChange(device);
                    }),
                );
            }
        });
    }

    emitDeviceChange(obj: DevicePairing) {
        this.onlineDeviceSubject.next(obj);
    }

    stopDevicesPairing() {
        if (this.unsubscribeDevice === null) return;

        forEach(this.unsubscribeDevice, (unsub) => {
            unsub();
        });

        this.unsubscribeDevice = [];
    }

    emitDevicesPairing() {
        this.pairedSubject.next(this.paired.Devices);
    }

    //enregistrement des devices
    setDevicesPairing() {
        console.log("Set Devices Pairing");
        if (this.user.Key == "") return;
        forEach(this.paired.Devices, (device) => {
            if (device !== null) {
                device.data.lastUpdate = Date.now();
                set(
                    ref(
                        this.database,
                        "/Pairing/Users/" +
                            this.user.Key +
                            "/Devices/" +
                            device.Guid,
                    ),
                    this.jsonConvert.serializeObject<DevicePairing>(device),
                ).catch((error) => {
                    console.error(error);
                });
            }
        });
    }

    setDeviceOffline(guid: string) {
        if (this.user.Key === "" || guid === "") return;
        set(
            ref(
                this.database,
                "/Pairing/Users/" +
                    this.user.Key +
                    "/Devices/" +
                    guid +
                    "/Data/Online",
            ),
            false,
        ).catch((error) => {
            console.error(error);
        });
    }

    request: RequestPairing[];

    async getRequestsPairing() {
        this.request = [];
        const snapshot = await get(ref(this.database, "/Pairing"));
        if (snapshot !== null) {
            const tmp = this.jsonConvert.deserializeObject(
                snapshot.toJSON(),
                Pairing,
            );
            if (tmp !== null && tmp !== undefined) {
                this.request = tmp.requests;
            }
        }
    }

    setRequestPairing(index: number, request: RequestPairing) {
        set(
            ref(this.database, "/Pairing/Requests/" + index),
            this.jsonConvert.serializeObject(request),
        ).catch((error) => {
            console.error(error);
        });
    }

    async pairDevice(code: number) {
        const codeStr = "" + code;

        await this.getRequestsPairing();

        const index = this.request.findIndex(
            (x) => x.code === codeStr && x.complete === false,
        );
        if (index === -1) {
            return -1;
        }
        const request = this.jsonConvert.deserializeObject(
            this.jsonConvert.serializeObject(this.request[index]),
            RequestPairing,
        );

        request.User = this.user.Key;
        request.complete = true;
        request.device.User = this.user.Key;

        console.log(request);
        if (this.paired.Devices === null) this.paired.Devices = [];
        if (request.device !== null) {
            this.paired.Devices.push(request.device);
            this.setDevicesPairing();
            this.setRequestPairing(index, request);
            this.emitDevicesPairing();
        }

        return 1;
    }

    unpairDevice(device: DevicePairing) {
        if (this.user.Key === "" || device.guid === "") return;
        const index = this.paired.Devices.findIndex(
            (x) => x.Guid === device.Guid,
        );
        if (index === -1) {
            return;
        }

        console.log(this.paired.Devices);
        this.paired.Devices.splice(index, 1);

        console.log(this.paired.Devices);
        remove(
            ref(
                this.database,
                "/Pairing/Users/" + this.user.Key + "/Devices/" + device.Guid,
            ),
        ).then(() => this.emitDevicesPairing());
    }

    updateDevice(device: DevicePairing) {
        if (this.user.Key === "" || device.guid === "") return;
        device.data.lastUpdate = Date.now();
        set(
            ref(
                this.database,
                "/Pairing/Users/" + this.user.Key + "/Devices/" + device.guid,
            ),
            device,
        ).catch((error) => {
            console.error(error);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Devices
    // -----------------------------------------------------------------------------------------------------
    devices: Device[];
    devicesSubject = new Subject<Device[]>();

    emitDevices() {
        this._devicesService.setDevices(this.devices);
        this.devicesSubject.next(this.devices);
    }
    initDevices() {
        this.initDatabase();
    }
    getDevices() {
        if (
            this.appFirebase === undefined ||
            this.auth === undefined ||
            this.database === undefined
        ) {
            return;
        }

        this.devices = [];
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }

        get(
            ref(
                this.database,
                "Users/" +
                    user.uid +
                    "/Versions/" +
                    this.user.getVersion() +
                    "/",
            ),
        ).then((userProjectSnapshot) => {
            const listKey = this.jsonConvert.deserializeObject(
                userProjectSnapshot.toJSON(),
                UserProject,
            );
            listKey?.Devices.forEach((deviceKey) => {
                if (deviceKey == "") return;

                get(
                    ref(
                        this.database,
                        this.getDatabasePrefix() + "Devices/" + deviceKey,
                    ),
                ).then((projectSnapshot) => {
                    //console.log("Get Device From Database");

                    const device = this.jsonConvert.deserializeObject(
                        projectSnapshot.toJSON(),
                        Device,
                    );
                    //console.log(device);
                    this.setDevice(device);
                });
            });
        });
    }
    setDevice(device: Device) {
        if (device == null) return;
        for (let i = 0; i < this.devices.length; i++) {
            if (
                this.devices[i] != null &&
                this.devices[i].getKey() == device.getKey()
            ) {
                this.devices[i] = device;
                this.emitDevices();
                return;
            }
        }

        this.devices.push(device);
        this.emitDevices();
    }
    setDevices(devices: Device[]) {
        devices.forEach((d) => {
            const projectSerialized =
                this.jsonConvert.serializeObject<Device>(d);

            if (!this.checkKeyNotEmpty(d.getKey())) return;
            set(
                ref(
                    this.database,
                    this.getDatabasePrefix() + "Devices/" + d.getKey(),
                ),
                projectSerialized,
            );
        });
    }
    addDevice(device: Device) {
        if (device == null) return;

        this.user.addDevice(device.getKey());
        this._devicesService.addDevice(device);

        const projectSerialized =
            this.jsonConvert.serializeObject<Device>(device);

        if (!this.checkKeyNotEmpty(device.getKey())) return;
        set(
            ref(
                this.database,
                this.getDatabasePrefix() + "Devices/" + device.getKey(),
            ),
            projectSerialized,
        );

        this._userService.setUser(this.user);
        this.setUser(this.user);
        this.emitUser();
        this.emitDevices();
    }
    async deleteDevice(deviceKey: string, version: string = undefined) {
        if (version == undefined) {
            version = this.getDatabasePrefix();
        }
        await get(ref(this.database, version + "/Devices/" + deviceKey)).then(
            async (deviceSnapshot) => {
                //console.log("Get Device From Database");

                const device = this.jsonConvert.deserializeObject(
                    deviceSnapshot.toJSON(),
                    Device,
                );
                //console.log(device.User);
                await this._functionsService
                    .deleteUser(device.User)

                    .catch((error) => {
                        this._notificationsService.create({
                            id: this.generateKey(),
                            title: "Erreur lors de la suppression !",
                            icon: "error",
                            description:
                                "Une erreur est survenue durant la supression de la box !",
                            time: new Date().toString(),
                            read: false,
                        });
                    });
            },
        );
        if (!this.checkKeyNotEmpty(deviceKey)) return;
        await remove(ref(this.database, version + "/Devices/" + deviceKey));
        this._devicesService.deleteDevice(deviceKey);
        this.devices = this._devicesService.getDevices();
        this.user.deleteDevice(deviceKey);
        this.setUser(this.user);
        this.emitUser();
        this.emitDevices();
    }
    deleteProjectFromDevice(projectKey: string) {
        if (this.devices == undefined) return;
        this.devices.forEach((d) => {
            if (d.Projects.includes(projectKey)) {
                d.Projects.splice(d.Projects.indexOf(projectKey), 1);

                this.setDevice(d);
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Device User
    // -----------------------------------------------------------------------------------------------------
    deviceUserList: DeviceUserTable[];
    deviceUserListSubject = new Subject<DeviceUserTable[]>();
    deviceUserListObservable: Observable<DeviceUserTable[]>;
    emitDeviceUserList() {
        this.deviceUserListSubject.next(this.deviceUserList);
    }

    getDeviceUserList() {
        if (this.devices == undefined || this.devices == null) return;
        const user = this.auth.currentUser;
        if (user === undefined || user === null) {
            return;
        }
        this.deviceUserList = [];

        for (let i = 0; i < this.devices.length; i++) {
            if (this.devices[i] != null) {
                if (this.devices[i].getUser() == "") {
                    onValue(
                        ref(
                            this.database,
                            this.getDatabasePrefix() +
                                "Devices/" +
                                this.devices[i].getKey(),
                        ),
                        (snapshot) => {
                            this.devices[i] =
                                this.jsonConvert.deserializeObject(
                                    snapshot.toJSON(),
                                    Device,
                                );
                            this.refreshUser(i);
                        },
                    );
                } else {
                    this.refreshUser(i);
                }
            }
        }
    }
    refreshUser(i: number) {
        if (this.devices[i] == undefined || this.devices[i] == null) {
            this.devices.splice(i, 1);
            return;
        }

        const userKey: string = this.devices[i].getUser();

        onValue(
            ref(this.database, "Users/" + userKey + "/"),
            (snapshot) => {
                const u: User = this.jsonConvert.deserializeObject(
                    snapshot.toJSON(),
                    User,
                );
                if (u != null) {
                    u.setKey(userKey);
                    const device: DeviceUserTable = new DeviceUserTable();
                    device.setUser(u);
                    device.setDevice(this.devices[i]);
                    this.addOrUpdate(device);
                    this.emitDeviceUserList();
                }
            },
            function (error) {
                //if (error) {
                console.error(error);
                //}
            },
        );
    }
    addOrUpdate(device: DeviceUserTable) {
        if (device.Device == null) return;
        if (this.deviceUserList == undefined) this.deviceUserList = [];
        for (let i = 0; i < this.deviceUserList.length; i++) {
            if (
                this.deviceUserList[i] != undefined &&
                this.deviceUserList[i] != null &&
                this.deviceUserList[i].Device != undefined &&
                this.deviceUserList[i].Device != null &&
                this.deviceUserList[i].Device.Key == device.Device.Key
            ) {
                if (
                    this.deviceUserList[i].User == null ||
                    this.deviceUserList[i].getUser().getType() !=
                        UserType.Device ||
                    (this.deviceUserList[i].getUser().getType() ==
                        UserType.Device &&
                        device.getUser().getType() == UserType.Device)
                ) {
                    this.deviceUserList[i] = device;
                }
                return;
            }
        }
        this.deviceUserList.push(device);
    }
    updateDeviceUser(key: string, user: User) {
        if (user.Type != UserType.Device) return;

        for (let i = 0; i < user.Versions.length; i++) {
            if (user.Versions[i] == undefined) {
                user.Versions[i] = new UserProject();
                user.Versions[i].Projects[0] = "";
                user.Versions[i].Library = "";
                user.Versions[i].ProjectsV2 = [];
                user.Versions[i].Devices = [];
            }
        }
        //console.log(user);
        const userSerialized = this.jsonConvert.serializeObject<User>(user);
        if (!this.checkKeyNotEmpty(key)) return;
        set(ref(this.database, "Users/" + key), userSerialized);
    }

    deleteDeviceUser(key: string) {
        if (this.deviceUserList == undefined || this.deviceUserList == null)
            return;
        // var adminKey = this.auth.currentUser.uid;
        for (let index = 0; index < this.deviceUserList.length; index++) {
            if (this.deviceUserList[index].getUser().getKey() == key) {
                if (
                    this.deviceUserList[index].getUser().getType() ==
                    UserType.Device
                ) {
                    if (!this.checkKeyNotEmpty(key)) return;
                    set(ref(this.database, "Deleting/" + key), key);
                    this.deleteDevice(
                        this.deviceUserList[index].getDevice().getKey(),
                    );
                    //this.deviceUserList.splice(index,1);
                }
                return;
            }
        }
    }
    findDeviceUserList(): Observable<DeviceUserTable[]> {
        //this.checkUserList();
        this.deviceUserListObservable = of(this.deviceUserList);
        return this.deviceUserListObservable;
    }
    checkUserList(): boolean {
        if (this.deviceUserList == undefined) {
            return true;
        }
        for (let i = 0; i < this.deviceUserList.length; i++) {
            if (
                this.deviceUserList[i].getUser() == null ||
                this.deviceUserList[i].getUser().getKey() == ""
            ) {
                return true;
            }
        }
        return false;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ User List data
    // -----------------------------------------------------------------------------------------------------
    userList: DeviceUserTable[];
    userListSubject = new Subject<DeviceUserTable[]>();
    userListObservable: Observable<DeviceUserTable[]>;
    emitUserList() {
        this.userListSubject.next(this.userList);
    }

    getUserList() {
        if (this.userList == undefined) this.userList = [];
        return this.userList;
    }

    setUserList(userList: DeviceUserTable[]) {
        this.userList = userList;
        this.emitUserList();
    }

    addOrUpdateUser(user: User, device: Device) {
        if (user.getType() != UserType.Device) return;
        for (let i = 0; i < this.userList.length; i++) {
            if (
                this.userList[i] != null &&
                this.userList[i].getUser().Key == user.Key
            ) {
                this.userList[i].setUser(user);
                this.userList[i].setDevice(device);
                return;
            }
        }
        const d: DeviceUserTable = new DeviceUserTable();
        d.setUser(user);
        d.setDevice(device);
        this.userList.push(d);
    }

    findUserList(): Observable<DeviceUserTable[]> {
        //console.log(this.deviceUserList)
        this.userListObservable = of(this.userList);
        return this.userListObservable;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ REGULAR EXPRESSIONS
    // -----------------------------------------------------------------------------------------------------
    regularExpressionsEncode: any[] = [/%/g, /\./g, /#/g, /\$/g, /\[/g, /\]/g];
    regularExpressionsDecode: any[] = [
        /%25/g,
        /%2E/g,
        /%23/g,
        /%24/g,
        /%5B/g,
        /%5D/g,
    ];
    substitutionEncode: string[] = ["%25", "%2E", "%23", "%24", "%5B", "%5D"];
    substitutionDecode: string[] = ["%", ".", "#", "$", "[", "]"];

    encodeAsFirebaseKey(str: string): string {
        for (let i = 0; i < this.regularExpressionsEncode.length; i++) {
            str = str.replace(
                this.regularExpressionsEncode[i],
                this.substitutionEncode[i],
            );
        }

        return str;
    }

    decodeAsFirebaseKey(str: string): string {
        for (let i = 0; i < this.regularExpressionsDecode.length; i++) {
            str = str.replace(
                this.regularExpressionsDecode[i],
                this.substitutionDecode[i],
            );
        }

        return str;
    }
    //-------------------------------------------------------------------------------
    //  Scenario
    //-------------------------------------------------------------------------------

    // emitScenarios() {
    // 	this._projectV2Service.setProjects(this.projects);
    // 	this.projectV2Subject.next(this.projects);
    // }
    initScenarios() {
        this.initDatabase();
    }

    setScenario(scenario: Scenario) {
        //console.log("setScenario");
        if (scenario == null) return;
        if (this.scenarios == undefined) {
            this.scenarios = [];
            this.scenarios.push(scenario);
            return;
        }
        for (let i = 0; i < this.scenarios.length; i++) {
            if (this.scenarios[i].key == scenario.key) {
                this.scenarios[i] = scenario;

                set(
                    ref(
                        this.database,
                        this.getDatabasePrefix() + "Scenarios/" + scenario.key,
                    ),
                    new JsonConvert().serializeObject<Scenario>(scenario),
                );
                return;
            }
        }

        this.scenarios.push(scenario);
    }
    setScenarios(scenarios: Array<Scenario>) {
        scenarios.forEach((sc) => {
            const projectSerialized =
                this.jsonConvert.serializeObject<Scenario>(sc);

            if (!this.checkKeyNotEmpty(sc.key)) return;
            set(
                ref(
                    this.database,
                    this.getDatabasePrefix() + "Scenarios/" + sc.key,
                ),
                projectSerialized,
            );
        });
    }

    async deleteScenario(scenario: Scenario, version: string = undefined) {
        if (version == undefined) version = this.getDatabasePrefix();

        if (scenario.diffusionKey.length != 0) {
            if (!this.checkKeyNotEmpty(scenario.diffusionKey)) return;

            //console.log(scenario);
            const diffusion = this.diffusions.find(
                (d) => d.key == scenario.diffusionKey,
            );
            if (diffusion !== undefined) {
                await this.deleteDiffusionLangs(diffusion);
            }
            this.removeDiffusionFromExperiences(scenario.diffusionKey);
            await remove(
                ref(
                    this.database,
                    version + "/Diffusions/" + scenario.diffusionKey,
                ),
            ).catch();
        }

        if (this._userService.getUser() != undefined) {
            this._userService.getUser().removeDiffusion(scenario.diffusionKey);
            this.setUser(this._userService.getUser());
        }

        /* Remove scenario */
        if (!this.checkKeyNotEmpty(scenario.key)) return;
        await remove(
            ref(this.database, version + "/Scenarios/" + scenario.key),
        );

        this.user.removeScenario(scenario.key);

        this.deleteProjectFromDevice(scenario.key);
        this.setUser(this.user);

        this.getUser();
        this.getScenarios();
    }

    async deleteScenarioKey(scenarioKey: string, version: string) {
        await get(
            ref(this.database, version + "/Scenarios/" + scenarioKey),
        ).then(async (scenarioSnapshot) => {
            const scenario = this.jsonConvert.deserializeObject(
                scenarioSnapshot.toJSON(),
                Scenario,
            );
            for (const code of scenario.languages) {
                await this.deleteScenarioLang(scenario, code);
            }
            await this.deleteScenario(scenario, version);
        });
    }

    async deleteSubscriptionKey(
        subcriptionsKey: string,
        version: string,
        userKey: string,
    ) {
        if (!this.checkKeyNotEmpty(userKey)) return;
        if (!this.checkKeyNotEmpty(subcriptionsKey)) return;
        await remove(
            ref(
                this.database,
                version + "/Subscriptions/" + userKey + "/" + subcriptionsKey,
            ),
        ).catch();
    }

    //-------------------------------------------------------------------------------
    //  Viewer
    //-------------------------------------------------------------------------------

    viewerScenario: Scenario;
    viewerContent: Content;
    viewerLibrary: Library;
    viewerVersion: string;
    viewerSubject = new Subject<Scenario>();
    nextScene: string;
    nextSceneSubject = new Subject<string>();
    viewerAuth: boolean;
    viewerAuthSubject = new Subject<boolean>();
    diffusion: Diffusion;
    nextAudioSubject = new Subject<string>();

    LoadViewer(scenarioKey, libraryKey, version) {
        this.viewerVersion = version;
        //Scenario
        if (libraryKey != undefined) {
            this.AuthTest(scenarioKey, version);

            //EDITOR
            this.getViewerLibrary(libraryKey);
            get(
                ref(
                    this.database,
                    this.viewerVersion + "/Scenarios/" + scenarioKey,
                ),
            ).then((scenarioSnapshot) => {
                this.viewerScenario = this.jsonConvert.deserializeObject(
                    scenarioSnapshot.toJSON(),
                    Scenario,
                );

                this.getContentURL(this.viewerScenario.audioKey);
                this.viewerSubject.next(this.viewerScenario);
            });
        } else {
            //Diffusion
            get(
                ref(
                    this.database,
                    this.viewerVersion + "/Diffusions/" + scenarioKey,
                ),
            ).then((diffusionSnapshot) => {
                this.diffusion = this.jsonConvert.deserializeObject(
                    diffusionSnapshot.toJSON(),
                    Diffusion,
                );
                if (this.diffusion == null) {
                    this.viewerSubject.next(null);
                    return;
                }
                //console.log(this.diffusion);
                libraryKey = this.diffusion.libraryKey;
                //console.log(this.diffusion.libraryKey);
                get(
                    ref(
                        this.database,
                        this.viewerVersion +
                            "/Diffusions/" +
                            scenarioKey +
                            "/Scenario",
                    ),
                ).then((scenarioSnapshot) => {
                    this.diffusion.scenario =
                        this.jsonConvert.deserializeObject(
                            scenarioSnapshot.toJSON(),
                            Scenario,
                        );
                    this.viewerScenario = this.diffusion.scenario;

                    this.viewerSubject.next(this.viewerScenario);
                });
                this.getViewerLibrary(libraryKey);
                if (!this.diffusion.private) {
                    this.viewerAuth = true;
                    this.viewerAuthSubject.next(this.viewerAuth);
                }
            });
        }
    }
    getViewerLibrary(key: string) {
        get(ref(this.database, this.viewerVersion + "/Library/" + key)).then(
            (librarySnapshot) => {
                this.viewerLibrary = this.jsonConvert.deserializeObject(
                    librarySnapshot.toJSON(),
                    Library,
                );
                const url = "";
                this.getAllContentURL(this.viewerLibrary);

                this.viewerSubject.next(this.viewerScenario);
            },
        );
    }
    goToNextScene(destKey: string) {
        this.nextScene = destKey;
        this.nextSceneSubject.next(this.nextScene);
    }
    getUrlMedia(mediaKey) {
        if (this.viewerLibrary == undefined) return;
        for (
            let index = 0;
            index < this.viewerLibrary.Contents.length;
            index++
        ) {
            const content = this.viewerLibrary.Contents[index];
            if (content.getKey() == mediaKey) {
                return content.getDataUrl();
            }
        }
    }

    checkPassword(password: string) {
        if (this.diffusion == undefined || this.diffusion.password != password)
            return false;

        return true;
    }

    AuthTest(scenarioKey, version) {
        //Auth Test
        this.viewerAuth = false;
        const currentUser = this.auth.currentUser;
        //console.log('Auth Test');
        if (currentUser != undefined && currentUser != null) {
            if (this.user === undefined) {
                get(ref(this.database, "Users/" + currentUser.uid)).then(
                    (userSnapshot) => {
                        const user = new JsonConvert().deserializeObject(
                            userSnapshot.toJSON(),
                            User,
                        );
                        for (
                            let index = 0;
                            index < user.Versions[version].Scenarios.length;
                            index++
                        ) {
                            const key = user.Versions[version].Scenarios[index];
                            if (key == scenarioKey) {
                                //console.log("AUTH OK !");
                                this.viewerAuth = true;
                                this.viewerAuthSubject.next(this.viewerAuth);
                                break;
                            }
                        }
                    },
                );
            } else {
                for (
                    let index = 0;
                    index < this.user.Versions[version].Scenarios.length;
                    index++
                ) {
                    const key = this.user.Versions[version].Scenarios[index];
                    if (key == scenarioKey) {
                        //console.log("AUTH OK !");
                        this.viewerAuth = true;
                        this.viewerAuthSubject.next(this.viewerAuth);
                        break;
                    }
                }
            }
        } else {
            setTimeout(() => {
                this.AuthTest(scenarioKey, version);
            }, 10);
        }
    }

    async getContentURL(audioKey) {
        //let libraryKey = this.viewerLibrary.Key;
        let filename = "";
        //Medias
        if (this.library == undefined) {
            return;
        }

        const cont = this.library.Contents.find((x) => x.Key == audioKey);
        if (cont === undefined) return;
        if (environment.useCDN) {
            if (cont.DataUrl == "" && cont.Data != "" && cont.Status > 0) {
                filename = cont.Data;
                filename =
                    "data." + filename.substring(filename.lastIndexOf(".") + 1);
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    this.library.Key +
                    "/Contents/" +
                    cont.Key +
                    "/Data/" +
                    filename;

                //this.checkCdnUrl(url, (res)=>{
                cont.DataUrl = url;
                this.emitLibrary();
                // });
            }
        } else {
            if (cont.DataUrl == "" && cont.Data != "" && cont.Status > 0) {
                filename = cont.Data;
                filename =
                    "data." + filename.substring(filename.lastIndexOf(".") + 1);
                await getDownloadURL(
                    Sref(
                        this.storage,
                        "Library/" +
                            this.library.Key +
                            "/Contents/" +
                            cont.Key +
                            "/Data/" +
                            filename,
                    ),
                ).then((url) => {
                    //console.log("AUDIO URL");
                    //console.log(url);
                    cont.DataUrl = url;
                    this.nextAudioSubject.next(url);
                });
            }
        }
    }

    async getDefaultContentURL(contentKey: string) {
        //let libraryKey = this.viewerLibrary.Key;
        let filename = "";
        //Medias
        if (this.template == undefined) {
            return;
        }

        const content = this.template.Contents.find((x) => x.Key == contentKey);
        if (content === undefined) return;

        if (environment.useCDN) {
            if (
                content.DataUrl == "" &&
                content.Data != "" &&
                content.Status > 0
            ) {
                filename = content.Data;
                filename =
                    "data." + filename.substring(filename.lastIndexOf(".") + 1);
                const url =
                    environment.firebase.cdnURL +
                    "/Library/" +
                    this.template.Key +
                    "/Contents/" +
                    content.Key +
                    "/Data/" +
                    filename;

                //this.checkCdnUrl(url, (res)=>{
                content.DataUrl = url;
                return url;
                // });
            }
        } else {
            if (
                content.DataUrl == "" &&
                content.Data != "" &&
                content.Status > 0
            ) {
                filename = content.Data;
                filename =
                    "data." + filename.substring(filename.lastIndexOf(".") + 1);
                return await getDownloadURL(
                    Sref(
                        this.storage,
                        "Library/" +
                            this.template.Key +
                            "/Contents/" +
                            content.Key +
                            "/Data/" +
                            filename,
                    ),
                ).then((url) => {
                    //console.log("AUDIO URL");
                    //console.log(url);
                    content.DataUrl = url;
                    return url;
                });
            }
        }
    }

    checkCdnUrl(url, callback) {
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.send();

        //console.log("Check CDN URL ! " + url);

        request.onprogress = () => {
            if (request.status == 200) {
                //if(statusText == OK)
                //console.log("OnProgress ! " + url);
                callback(url);
                request.abort();
            } else if (request.status == 206) {
                //if(statusText == OK)
                //console.log("206 ! " + url);
                setTimeout(() => {
                    this.checkCdnUrl(url, callback);
                }, 500);
                request.abort();
            }
        };
        request.onload = () => {
            if (request.status == 200) {
                //if(statusText == OK)
                //console.log("OnLoad ! " + url);
                callback(url);
                request.abort();
            } else if (request.status == 206) {
                //if(statusText == OK)
                //console.log("206 ! " + url);
                setTimeout(() => {
                    this.checkCdnUrl(url, callback);
                }, 500);
                request.abort();
            } else {
                callback("");
                request.abort();
                //console.log("Cancel ! " + url);
            }
        };
    }

    async getContentLanguage(
        code: string,
        libkey: string,
        key: string,
    ): Promise<Content> {
        const value = await get(
            ref(
                this.database,
                this.user.Version +
                    "/Languages/" +
                    code +
                    "/" +
                    libkey +
                    "/Contents/" +
                    key,
            ),
        );
        //console.log('code : ' + code);
        //console.log(value);
        //console.log(value.toJSON());
        //console.log(JSON.stringify(value));
        //console.log(JSON.stringify(value.toJSON()));
        if (JSON.stringify(value) == "null") {
            return undefined;
        }
        return this.jsonConvert.deserializeObject(value.toJSON(), Content);
    }

    async setContentLanguage(code: string, libkey: string, content: Content) {
        await set(
            ref(
                this.database,
                this.user.Version +
                    "/Languages/" +
                    code +
                    "/" +
                    libkey +
                    "/Contents/" +
                    content.key,
            ),
            this.jsonConvert.serializeObject(content),
        );
    }

    async removeContentLanguage(code: string, libkey: string, key: string) {
        await remove(
            ref(
                this.database,
                this.user.Version +
                    "/Languages/" +
                    code +
                    "/" +
                    libkey +
                    "/Contents/" +
                    key,
            ),
        ).catch();
    }

    updateAllScene(scenariokey: string, index: number, val: any) {
        //console.log("--------------updateScene-----------");
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    index +
                    "/",
            ),
            val,
        )
            .then(() => console.log("scenario Set"))
            .catch((err) => console.error(err));
    }

    updateScene(scenariokey: string, index: number, option: string, val: any) {
        //console.log("--------------updateScene-----------");
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    index +
                    "/" +
                    option,
            ),
            val,
        )
            .then(() => console.log("scenario Set"))
            .catch((err) => console.error(err));
    }

    updateSceneOptions(
        scenariokey: string,
        index: number,
        option: string,
        val: any,
        serializeobj: boolean,
    ) {
        //console.log("--------------updateSceneOptions-----------");
        this._scenarioService.updateScenarioVersionDate(this);
        if (serializeobj)
            set(
                ref(
                    this.database,
                    this.user.Version +
                        "/Scenarios/" +
                        scenariokey +
                        "/Scenes/" +
                        index +
                        "/SceneOptions/" +
                        option,
                ),
                this.jsonConvert.serializeObject(val),
            );
        else
            set(
                ref(
                    this.database,
                    this.user.Version +
                        "/Scenarios/" +
                        scenariokey +
                        "/Scenes/" +
                        index +
                        "/SceneOptions/" +
                        option,
                ),
                val,
            );
    }

    updateSceneEvent(
        scenariokey: string,
        indexscene: number,
        indexevent: number,
        val: any,
    ) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    indexscene +
                    "/Events/" +
                    indexevent,
            ),
            this.jsonConvert.serializeObject(val),
        );
    }

    addSceneEvent(
        scenariokey: string,
        indexscene: number,
        indexevent: number,
        val: any,
    ) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    indexscene +
                    "/Events/" +
                    indexevent,
            ),
            this.jsonConvert.serializeObject(val),
        );
    }

    updateSceneEvents(scenariokey: string, indexscene: number, val: any) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    indexscene +
                    "/Events/",
            ),
            this.jsonConvert.serializeArray(val, String),
        );
    }
    updateSceneEventsOpenBy(
        scenariokey: string,
        indexscene: number,
        indexEvent: number,
        val: any,
    ) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version +
                    "/Scenarios/" +
                    scenariokey +
                    "/Scenes/" +
                    indexscene +
                    "/Events/" +
                    indexEvent +
                    "/OpenBy/",
            ),
            this.jsonConvert.serializeArray(val, String),
        );
    }
    updateVersion(scenariokey: string, version: number) {
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/Version",
            ),
            version,
        );
    }
    updateDate(scenariokey: string, lastUpdate: string) {
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/LastUpdate",
            ),
            lastUpdate,
        );
    }

    updatestoryOption(
        scenariokey: string,
        option: string,
        val: any,
        serializeobj: boolean,
    ) {
        this._scenarioService.updateScenarioVersionDate(this);
        if (serializeobj)
            set(
                ref(
                    this.database,
                    this.user.Version +
                        "/Scenarios/" +
                        scenariokey +
                        "/" +
                        option,
                ),
                this.jsonConvert.serializeObject(val),
            );
        else
            set(
                ref(
                    this.database,
                    this.user.Version +
                        "/Scenarios/" +
                        scenariokey +
                        "/" +
                        option,
                ),
                val,
            );
    }

    updatequestionSets(scenariokey: string, option: string, val: any) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/" + option,
            ),
            this.jsonConvert.serializeArray(val),
        );
    }

    updateVariables(scenariokey: string, option: string, val: any) {
        console.log("updateVariables");
        console.log(val);
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/" + option,
            ),
            this.jsonConvert.serializeArray(val),
        );
    }

    updatestoryLanguages(scenariokey: string, option: string, val: any) {
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/" + option,
            ),
            val,
        );
    }

    updateSceneList(scenariokey: string, val: any) {
        this._scenarioService.updateScenarioVersionDate();
        this._scenarioService.updateScenarioVersionDate(this);
        set(
            ref(
                this.database,
                this.user.Version + "/Scenarios/" + scenariokey + "/Scenes/",
            ),
            val,
        )
            .then(() => console.log("scenario Set"))
            .catch((err) => console.error(err));
    }

    async getSubscriptions() {
        const value = await get(ref(this.database, "Subscriptions"));
        //console.log(value);
        //console.log(value.toJSON());
        this.subscriptions = this.jsonConvert.deserializeObject(
            value.toJSON(),
            Subscriptions,
        );

        let i = 0;
        for (let subscription of this.subscriptions.subscriptions) {
            this.subscriptions.subscriptions[i].index = i;
            i++;
        }
    }

    emitSubscriptions() {
        this.subscriptionsSubject.next(this.subscriptions?.subscriptions);
    }

    //Unix timestamp to date
    timestampToDate(timestamp: number) {
        return new Date(timestamp * 1000);
    }

    async shareFile(
        scenario: Scenario,
        content: Content,
        email: string,
        directUpload = false,
    ) {
        //Add content to library of shareuser
        //console.log("shareFile");
        //console.log(environment.share.version);
        //console.log(environment.share.libraryKey);
        await get(
            ref(
                this.database,
                environment.share.version +
                    "/Library/" +
                    environment.share.libraryKey,
            ),
        ).then(
            async (json: DataSnapshot) => {
                //console.log(json);
                //console.log(json.toJSON());
                const lib = this.jsonConvert.deserializeObject(
                    json.toJSON(),
                    Library,
                );
                //console.log(lib);
                const index =
                    lib !== undefined && lib !== null ? lib.Contents.length : 0;
                await set(
                    ref(
                        this.database,
                        environment.share.version +
                            "/Library/" +
                            environment.share.libraryKey +
                            "/Contents/" +
                            index,
                    ),
                    this.jsonConvert.serializeObject(content),
                ).then(
                    () => console.log("content Set"),
                    (err) => console.error(err),
                );
            },
            (err) => console.error(err),
        );
        //Add scenario
        /*await set(ref(this.database,environment.share.version + "/Scenarios/" + scenario.key+"/"),this.jsonConvert.serializeObject(scenario))
        .then(() => //console.log("scenario Set"), (err) => console.error(err));*/

        //Create Diffusion
        const diffusion = new Diffusion();
        diffusion.scenario = scenario;
        diffusion.key = this.generateKey();
        diffusion.description = "Diffusion de " + email;
        diffusion.mode = ModeType.Public;
        diffusion.contentKeys = [content.key];
        diffusion.libraryKey = environment.share.libraryKey;
        diffusion.isDefaultWeb = true;
        diffusion.web = true;
        diffusion.email = email;
        diffusion.endDate = new Date();
        diffusion.endDate.setDate(
            diffusion.endDate.getDate() + environment.share.duration,
        );
        diffusion.lang = localStorage.getItem("user.language");

        const urlForIframe =
            environment.firebase.viewerUrl +
            "/viewer/" +
            diffusion.key +
            "/" +
            environment.share.version;
        const iframe =
            "<iframe width='560px' height='315px' src='" +
            urlForIframe +
            "' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; microphone' allowfullscreen></iframe>";
        diffusion.url = urlForIframe;

        //console.log(diffusion.key);
        //console.log(urlForIframe);
        //console.log(iframe);

        const jsonSc = this.jsonConvert.serializeObject(scenario);
        const jsonDiff = this.jsonConvert.serializeObject(diffusion);

        return this._functionsService
            .shareFile({
                scenario: jsonSc,
                diffusion: jsonDiff,
                scenarioKey: scenario.key,
                diffusionKey: diffusion.key,
                version: environment.share.version,
                user: environment.share.userKey,
            })
            .then((data) => {
                //console.log(data);
                const params = {
                    action: "publish",
                    toEmail: email,
                    isPrivate: false,
                    storymode: false,
                    username: email,
                    scenarioTitle:
                        diffusion.scenario.title !== undefined
                            ? diffusion.scenario.title
                            : "",
                    diffusionKey:
                        diffusion.key !== undefined ? diffusion.key : "",
                    diffusionPassword:
                        diffusion.password !== undefined
                            ? diffusion.password
                            : "",
                    url: diffusion.url !== undefined ? diffusion.url : "",
                    databaseVersion: environment.share.version,
                    sendEmail: true,
                    iframe: iframe !== undefined ? iframe : "",
                    description:
                        diffusion.description !== undefined
                            ? diffusion.description
                            : "",
                    image: "",
                    lang: localStorage.getItem("user.language"),
                };
                if (directUpload) {
                    return params;
                }
                this._functionsService
                    .createShortLink(params)
                    .then(async (data) => {
                        //console.log(data);
                        diffusion.url = data.data.res;
                        /*if(diffusion.url !== undefined)
					{
						set(ref(this.database,environment.share.version + "/Diffusions/" + diffusion.key + "/Url/" ),this.jsonConvert.serializeObject(diffusion.url));
					}*/
                    });
            });
    }

    createCustomerSubject = new Subject<string>();

    createCustomer(user: User) {
        console.log("createCustomer " + user.Stripe?.CustomerId);
        if (
            user.Key === undefined ||
            user.Key === "" ||
            user.Email === undefined ||
            user.Email === null ||
            user.Email === "" ||
            this.stripeInProgress
        ) {
            return;
        }
        this.stripeInProgress = true;
        console.log("Call to api-stripe-easystudio");
        let id = "";
        this._dataService
            .createCustomer({ email: user.Email })
            .subscribe(async (res: any) => {
                console.log(res);
                if (res.customer.id !== undefined && res.customer.id !== "") {
                    user.Stripe.CustomerId = res.customer.id;
                    id = res.customer.id;
                    await set(
                        ref(
                            this.database,
                            "Users/" + user.Key + "/Stripe/CustomerId/",
                        ),
                        user.Stripe.CustomerId,
                    ).then(
                        () => console.log("CustomerId Set"),
                        (err) => console.error(err),
                    );
                    this.stripeInProgress = false;
                    this.createCustomerSubject.next(id);
                }
            });
    }

    getVimeoFiles(content: Content): Observable<Content> {
        return new Observable<Content>((observer) => {
            if (
                content.VimeoData !== undefined &&
                content.VimeoData.status === "complete"
            ) {
                this._vimeoService
                    .getVideo(content.VimeoData.uri)
                    .subscribe((request) => {
                        if (
                            request["body"] !== undefined &&
                            request["body"] !== null
                        ) {
                            content.VimeoData.files = [];
                            forEach(request["body"].files, (file) => {
                                let video = new VimeoFile();
                                video.quality = file.quality;
                                video.url = file.link;
                                video.type = file.type;
                                video.name = file.public_name;
                                content.VimeoData.files.push(video);
                            });

                            if (
                                request["body"].play !== undefined &&
                                request["body"].play !== null
                            ) {
                                if (request["body"].play.dash !== undefined) {
                                    let video = new VimeoFile();
                                    video.quality = "dash";
                                    video.url = request["body"].play.dash.link;
                                    video.type = "dash";
                                    video.name = "auto";
                                    content.VimeoData.files.push(video);
                                }
                            }
                            observer.next(content);
                            observer.complete();
                        }
                    });
            }
        });
    }
}
